import { motion } from "framer-motion";
import React, { useEffect } from "react";
import styled from "styled-components";
import Grid from "@bit/mui-org.material-ui.grid";
import { Button } from "./Buttons";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import { useMainStore } from "../../../../store";

const Modal = styled(motion.div)`
  background-color: #fff;
  max-width: calc(100% - 180px);
  padding: 60px;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  opacity: 0;
  @media only screen and (max-width: 600px) {
    padding: 30px 15px;
    max-width: calc(100% - 60px);
  }
`;

const TimesUpModal = ({ timer, nextPhase, currentPhase }) => {
  useEffect(() => timer === 0, [timer]);
  const { theme } = useMainStore();

  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
          display: "none",
        }}
        animate={{
          display:
            currentPhase === "answerPhase" && timer === 0 ? "block" : "none",
          y: currentPhase === "answerPhase" && timer === 0 ? 0 : "100%",
          opacity: currentPhase === "answerPhase" && timer === 0 ? "1" : "0",
        }}
        transition={{ duration: 0.5 }}
        exit={{ opacity: 0, scale: 0.1 }}
        style={{
          backgroundColor: theme.colors.grey,
          height: "100vh",
          width: "100vw",
          position: "absolute",
          zIndex: 100,
          bottom: 0,
          left: 0,
        }}
      >
        <Grid
          style={{ height: "100vh" }}
          container
          alignItems="center"
          justify="center"
        >
          <Modal
            layout
            animate={{
              opacity: currentPhase === "answerPhase" && timer === 0 ? 1 : 0,
              scale: currentPhase === "answerPhase" && timer === 0 ? 1 : 0,
            }}
            transition={{ ...transitionSpring, delay: 0.5 }}
            exit={{ opacity: 0, scale: 0.1 }}
          >
            <motion.h1
              style={{
                color: theme.colors.purple,
                fontSize: "3em",
                fontFamily: "proxima-nova",
                marginTop: 0,
              }}
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7 }}
            >
              De tijd is op!
            </motion.h1>
            <p>Het is tijd om de antwoorden in te vullen</p>
            <Button
              primary
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover={{
                scale: 1.1,
                cursor: "pointer",
              }}
              transition={{
                scale: { delay: 0 },
                default: { delay: 2 },
              }}
              onClick={nextPhase}
            >
              Vul de antwoorden in
            </Button>
          </Modal>
        </Grid>
      </motion.div>
    </>
  );
};

export default TimesUpModal;
