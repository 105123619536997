import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";

import { useSocketStore } from "../../../../../store/socketStore";
import { NavigationNext } from "../../atoms/Buttons";
import CloudBackgroundVideo from "../../atoms/CloudBackgroundVideo";
import Topbar from "../../molecules/Topbar";
import { transitionSpring } from "./SelectLesson";
import { motion } from "framer-motion";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";

const Outro = () => {
  const { setPhase, level } = useSocketStore();
  const { small, height } = useWindowDimensions();

  return (
    <>
      <Topbar layoutId="bar" initial />
      <CloudBackgroundVideo />
      <Grid
        container
        style={{ height: height, position: "absolute", top: "0" }}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={11} sm={8}>
          <motion.div
            transition={{ ...transitionSpring, delay: 1 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              x: "-100%",
              opacity: 0,
              transition: { duration: 0.5, delay: 0 },
            }}
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: "0",
            }}
          >
            <iframe
              controls="0"
              style={{
                top: "0",
                left: "0",
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "0",
              }}
              src={`https://www.youtube.com/embed/${
                JSON.parse(level.params).outrovideourl
              }`}
              frameBorder="0"
              title="video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </motion.div>
        </Grid>

        <NavigationNext
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            scale: { delay: 0 },
            opacity: { delay: 2 },
          }}
          whileHover={{
            scale: 1.1,
          }}
          nextPhase={() => setPhase("end")}
          bottom={small ? 30 : 30}
          right={small ? 15 : 30}
          text={"Bekijk winnaar"}
        />
      </Grid>
    </>
  );
};

export default Outro;
