import React, { useState } from "react";
import { motion } from "framer-motion";
import Grid from "@bit/mui-org.material-ui.grid";
import styled from "styled-components";
import { Input } from "../../themes/2023/components/atoms/Input";
import { Button } from "../../themes/2023/components/atoms/Buttons";
import { useForm } from "react-hook-form";
import { useMainStore } from "../../store";
import { toast } from "react-toastify";
import IconClose from "../../static/media/iconClose";
import { useSocketStore } from "../../store/socketStore";

const ModalStyled = styled(motion.div)`
  padding: 60px;
  width: 500px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  opacity: 0;
  position: relative;
  @media only screen and (max-width: 600px) {
    padding: 30px 15px;
    max-width: calc(100% - 60px);
  }
`;

const AskCodeModal = ({ askCode, setAskCode }) => {
  const { register, handleSubmit, watch } = useForm();
  const socketStore = useSocketStore();
  const { theme, typeHost, setPhase } = useMainStore();
  const [error, setError] = useState(false);

  const onSubmit = () => {
    if (!watch("email")) {
      toast("Vul een e-mailadres in");
    } else {
      let email = watch("email");
      // stuur email
      fetch(`${process.env.REACT_APP_SERVER}code/${email}/${typeHost}/po`)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.msg === "invalid") {
              setError(email);
            } else {
              if (result.length === 0) {
                setError(email);
              } else {
                setError(false);
                setAskCode(false);
                toast("De code is verstuurd, check je inbox.");
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  return (
    <>
      <motion.div
        animate={{
          opacity: askCode === true ? 1 : 0,
        }}
        exit={{ opacity: 0, scale: 0.1 }}
        style={{
          backgroundColor: theme.colors.backgroundTransparent,
          height: "100vh",
          width: "100vw",
          position: "absolute",
          zIndex: 100,
          bottom: 0,
          left: 0,
        }}
      >
        <Grid
          style={{ height: "100vh" }}
          container
          alignItems="center"
          justify="center"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalStyled
              style={{ opacity: 0 }}
              layout
              initial={{ opacity: 0, scale: 0.1 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.1 }}
            >
              <div
                onClick={() => setAskCode(false)}
                style={{
                  background: theme.colors.backgroundTransparent,
                  position: "absolute",
                  top: 0,
                  right: 0,
                  padding: 18,
                  width: 20,
                  height: 20,
                  cursor: "pointer",
                }}
              >
                <IconClose
                  color={theme.colors.white}
                  height={"18px"}
                  width={"18px;"}
                />
              </div>
              <motion.h1
                style={{
                  fontFamily: "Brevia",
                  fontSize: "1.8rem",
                  fontWeight: 800,
                  color: theme.colors.purple,
                  marginTop: 0,
                }}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                Startcode opvragen
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                style={{
                  fontFamily: "Brevia",
                  color: theme.colors.purple,
                  fontSize: "1.4em",
                }}
              >
                {typeHost === "gastdocent"
                  ? "Vul hieronder het e-mailadres in waarmee je de gastles(sen) hebt geregistreerd."
                  : "Vul hieronder jouw e-mailadres in."}
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: 30 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  color: error ? "#9f0000" : theme.colors.purple,
                }}
                transition={{ delay: 0.4 }}
                style={{
                  fontFamily: "Brevia",
                  color: theme.colors.purple,
                  fontSize: "1.4em",
                }}
              >
                E-mailadres
              </motion.p>
              <Grid item xs={12}>
                <Input
                  style={{ border: `3px solid #e1ceff` }}
                  transition={{ delay: 0.5 }}
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    background: error ? "#ffe2e2" : "#fff",
                    borderColor: error
                      ? "#9f0000"
                      : theme.colors.backgroundLight,
                    color: error ? "#9f0000" : "#000",
                  }}
                  name="email"
                  placeholder={
                    typeHost === "gastdocent"
                      ? "email@bank.nl"
                      : "email@adres.nl"
                  }
                  ref={register}
                />
              </Grid>
              <Grid style={{ marginTop: "16px" }} item xs={10} md={"auto"}>
                <Button
                  style={{
                    height: 80,
                    fontFamily: "Brevia",
                    fontWeight: 800,
                    fontSize: "2em",
                  }}
                  fullWidth
                  transition={{
                    scale: { delay: 0 },
                    default: { delay: 0.5 },
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  primary
                  type="submit"
                >
                  Verstuur startcode
                </Button>
              </Grid>
              {error && (
                <Grid style={{ marginTop: "16px" }} item xs={10} md={"auto"}>
                  <p style={{ color: "#9f0000", fontFamily: "Brevia" }}>
                    <span style={{ fontWeight: 800 }}>Let op!</span> Er is geen
                    code geregistreerd voor {error}. Probeer een ander
                    e-mailadres of <br />{" "}
                    <span
                      onClick={() => {
                        setAskCode(false);
                        setPhase("noCodeForm");
                        socketStore.setPhase("noCodeForm");
                      }}
                      style={{
                        cursor: "pointer",
                        fontWeight: 800,
                        textDecoration: "underline",
                        textUnderlineOffset: 5,
                      }}
                    >
                      speel zonder code
                    </span>
                  </p>
                </Grid>
              )}
            </ModalStyled>
          </form>
        </Grid>
      </motion.div>
    </>
  );
};

export default AskCodeModal;
