import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";

import CashQuizLogo from "../../atoms/CashQuizLogo";
import { useMainStore } from "../../../../../store";
import { SquareTile } from "../../atoms/SquareTile";
import { backgrounds } from "../../../../../data/backgrounds";
import { Background } from "../../atoms/Backgrounds";
import { motion } from "framer-motion";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";
import { TextLink } from "../../atoms/TextLink";
import { useGAEventsTracker } from "../../../../../hooks/useGAEventsTracker";

const SelectTeams = () => {
  const { setPhase, setTeams, setQuestions, level, setShowCoins, theme } =
    useMainStore();
  const [teams] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
  const GAEventsTracker = useGAEventsTracker("cashquiz");

  useEffect(() => {
    fetch(
      process.env.REACT_APP_SERVER +
        "questions/" +
        level?.id +
        "/af091a0655214d6de1188850b6ff4512"
    )
      .then((res) => res.json())
      .then(
        (result) => setQuestions(result),
        (error) => console.log("Er is iets misgegaan.")
      );
  }, [setQuestions, level?.id]);

  return (
    <>
      <Grid
        style={{ height: "100vh", textAlign: "center", padding: "0px 0" }}
        container
        alignItems="center"
        justify="center"
      >
        <Grid item xs={8} lg={6}>
          <Grid item xs={12}>
            <motion.h1
              transition={{
                opacity: { delay: 0.2 },
                ...transitionSpring,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ fontSize: "32px" }}
            >
              Hoeveel teams doen er mee?
            </motion.h1>
          </Grid>
          <Grid key="teams" item xs={12}>
            <Grid container spacing={4}>
              {teams.map((team, index) => (
                <Grid key={index} item xs={3}>
                  <SquareTile
                    index={index}
                    backgroundColor={theme.colors.goldGradient}
                    color={theme.colors.white}
                    exit={{ opacity: 0 }}
                    onClick={() => {
                      GAEventsTracker("teams geselecteerd");
                      let filledArray = new Array(team)
                        .fill(null)
                        .map(() => ({ score: 10 }));
                      setTeams(filledArray);
                      setPhase("tips");
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {team}
                    </div>
                  </SquareTile>
                </Grid>
              ))}
              <motion.h2
                transition={{
                  opacity: { delay: 0.2 },
                  ...transitionSpring,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ fontSize: "22px", color: "white" }}
              >
                Speel je met meer dan 6 teams? Er zitten dit jaar 6 bordjes in
                het gastlessenpakket. Geef de extra teams 10 vellen papier om
                hun antwoorden op te schrijven of maak de teams iets groter.
              </motion.h2>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TextLink
        onClick={() => setPhase("selectLevel")}
        navigationPrev
        bottom={"50px"}
        left={"50px"}
        style={{
          position: "absolute",
          border: "1px solid red;",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        whileHover={{
          scale: 1.1,
          cursor: "pointer",
        }}
        transition={{
          scale: { delay: 0 },
          default: 0.5,
        }}
      >
        terug
      </TextLink>
      <Background exit initialBig background={"cloudBackgroundBig"} />
    </>
  );
};

export default SelectTeams;
