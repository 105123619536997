import styled from "styled-components";
import { motion } from "framer-motion";

export const Input = styled(motion.input)`
  margin: 0;
  padding: ${(props) => (props.small ? "7px 10px" : "15px 20px")};
  color: inherit;
  border: ${(props) => (props.border ? `1px solid rgba(0,0,0,0.5)` : "none")};
  border-radius: 0.4rem;
  font-size: 1.7em;
  width: calc(100% - 40px);

  &::placeholder {
    color: #d6cedf;
    letter-spacing: 0;
  }
`;

export const TextArea = styled(motion.textarea)`
  margin: 0;
  padding: ${(props) => (props.small ? "7px 10px" : "15px 20px")};
  color: inherit;
  border: ${(props) => (props.border ? `1px solid rgba(0,0,0,0.5)` : "none")};
  border-radius: 0.4rem;
  font-size: 1.2em;
  width: calc(100% - 40px);
  min-height: 100px;

  &::placeholder {
    color: #d6cedf;
    letter-spacing: 0;
  }
`;
