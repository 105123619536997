import { motion } from "framer-motion";
import React from "react";
import { useMainStore } from "../../../../store";

export const Background = ({
  background,
  big,
  initialBig,
  exit,
  opacity,
  exitOpacity,
}) => {
  const { theme } = useMainStore();

  const backgrounds = {
    cloudBackground: [
      {
        size: "120vh",
        transform: "translate(-50%, -70%)",
        background: theme.colors.background,
      },
      {
        size: "80vh",
        transform: "translate(-130%, -65%)",
        background: theme.colors.background,
      },
      {
        size: "120vh",
        transform: "translate(-10%, -80%)",
        background: theme.colors.background,
      },
      {
        size: "60vh",
        transform: "translate(10%, -80%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "90vh",
        transform: "translate(-55%, -65%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "70vh",
        transform: "translate(-120%, -75%)",
        background: theme.colors.backgroundLight,
      },
    ],
    cloudBackgroundBig: [
      {
        size: "120vh",
        transform: "translate(-50%, -70%)",
        background: theme.colors.background,
      },
      {
        size: "80vh",
        transform: "translate(-130%, -65%)",
        background: theme.colors.background,
      },
      {
        size: "120vh",
        transform: "translate(-10%, -80%)",
        background: theme.colors.background,
      },
      {
        size: "60vh",
        transform: "translate(10%, -80%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "90vh",
        transform: "translate(-55%, -65%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "70vh",
        transform: "translate(-120%, -75%)",
        background: theme.colors.backgroundLight,
      },
    ],
    cloudBackgroundBigWhite: [
      {
        size: "200vh",
        transform: "translate(-50%, -70%)",
        background: "#e9f0f5",
      },
      {
        size: "80vh",
        transform: "translate(-130%, -65%)",
        background: "#e9f0f5",
      },
      {
        size: "200vh",
        transform: "translate(-10%, -80%)",
        background: "#e9f0f5",
      },
      {
        size: "75vh",
        transform: "translate(10%, -80%)",
        background: "#fff",
      },
      {
        size: "90vh",
        transform: "translate(-55%, -65%)",
        background: "#fff",
      },
      {
        size: "70vh",
        transform: "translate(-120%, -75%)",
        background: "#fff",
      },
    ],
  };

  const getAnimationInitial = () => {
    if (big) return { y: 0, scale: 1 };
    else if (opacity) return { opacity: 0 };
  };

  const getAnimation = () => {
    if (big) return { y: 160, scale: 1.5 };
    else if (opacity) return { opacity: 1 };
  };

  const getExitAnimation = () => {
    if (exit)
      return {
        y: -1000,
        transition: {
          delay: 0.8,
          ease: [0.58, 0.02, 0.45, 1],
          duration: 0.6,
        },
      };
    else if (exitOpacity) {
      return {
        opacity: 0,
        transition: {
          delay: 0.8,
          ease: [0.58, 0.02, 0.45, 1],
          duration: 0.6,
        },
      };
    }
  };

  return (
    <motion.div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        zIndex: -2,
        y: initialBig ? 160 : 0,
        scale: initialBig ? 1.5 : 1,
      }}
      initial={getAnimationInitial()}
      animate={getAnimation()}
      transition={{ duration: opacity ? 1 : 0.5 }}
      exit={getExitAnimation()}
    >
      {backgrounds[background].map((props, index) => (
        <motion.div
          key={index}
          style={{
            zIndex: "-1",
            left: "50%",
            top: "50%",
            borderRadius: "100%",
            textAlign: "center",
            position: "absolute",
            width: props.size,
            height: props.size,
            ...props,
            overflow: "hidden",
          }}
        />
      ))}
    </motion.div>
  );
};
