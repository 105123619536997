import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer
      position="bottom-center"
      hideProgressBar={true}
      limit={3}
      autoClose={3000}
      draggable={false}
    />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
