import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion, useAnimation } from "framer-motion";

import { PanelQuestion } from "../../molecules/PanelQuestion";
import { PanelIntro } from "../../molecules/PanelIntro";
import TimerBar from "../../atoms/TimerBar";
import TimesUpModalSocket from "../../atoms/TimesUpModalSocket";
import TimesUpModal from "../../atoms/TimesUpModal";
import { LetterBox, numberToLetter } from "../../atoms/LetterBox";
import { NavigationNext } from "../../atoms/Buttons";
import { transitionSpring } from "./SelectLesson";
import Topbar from "../../molecules/Topbar";
import { TextLink } from "../../atoms/TextLink";
import { AnswersHolder } from "../../atoms/AnswersHolder";
import useInterval from "../../../../../hooks/useInterval";
import { transitionSlowSpring } from "../../atoms/ScoreBarSocket";
import bulb from "../../../../../static/media/bulb.svg";
import { useSocketStore } from "../../../../../store/socketStore";
import ProgressTeams from "../../molecules/ProgressTeams";
import NotAllPlayersModal from "../../atoms/NotAllPlayersModal";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";

const variants3 = {
  show: (custom) => ({
    opacity: 1,
    y: 0,
    transition: { delay: custom * 0.2 + 1 },
  }),
};

const backgroundVariants = {
  peak: {
    x: -100,
    transition: { duration: 1, type: "spring" },
  },
  small: {
    transition: { duration: 1, type: "spring" },
  },
  exit: {
    x: "-100%",
    transition: { ...transitionSpring, delay: 0.5 },
  },
};

const Question = ({ homework, socket }) => {
  const {
    setPhase,
    questions,
    round,
    theme,
    roomPin,
    playersAnswered,
    teams,
    answerPhase,
    setAnswerPhase,
    resetPlayerAnswering,
  } = useSocketStore();
  const answersPanelAnimation = useAnimation();
  const questionPanelAnimation = useAnimation();
  const questionAnimation = useAnimation();
  const backgroundAnimation = useAnimation();
  const CloudBackgroundDescriptionAnimation = useAnimation();
  const [notAllPlayers, setNotAllPlayers] = useState(false);
  const { small, height, md } = useWindowDimensions();
  let [timer, startTimer] = useState(false);

  useEffect(() => {
    if (answerPhase === false) {
      socket.emit("next question", roomPin);
      resetPlayerAnswering();
    }
  }, [answerPhase]);

  const variants = {
    full: {
      justifyContent: "center",
      transition: { duration: 1, type: "spring" },
      width: questions[round].intro
        ? questions[round].question_layout === "introbig"
          ? "50%"
          : "70%"
        : "100%",
      left: questions[round].intro
        ? questions[round].question_layout === "introbig"
          ? "50%"
          : "30"
        : "0%",
      paddingLeft: "0",
      x: 0,
    },
    hide: {
      opacity: 0,
    },
  };

  const variants2 = {
    peak: {
      transition: { ...transitionSpring },
      width: "100%",
    },
    small: {
      transition: { ...transitionSpring },
      width: questions[round].question_layout === "introbig" ? "50%" : "30%",
      justifyContent: "center",
    },
    hide: {
      opacity: 0,
    },
  };

  useEffect(() => {
    // if (!questions[round].intro) {
    //   setAnswerPhase("show");
    // } else {
    setAnswerPhase(false);
    // }
  }, [setAnswerPhase, questions, round]);

  useEffect(() => {
    let timeout;
    if (!answerPhase) {
      questionPanelAnimation.start("peak");
      timeout = setTimeout(() => {
        backgroundAnimation.start("peak");
      }, 2000);
    } else if (answerPhase === "show") {
      questionAnimation.start("show");
      answersPanelAnimation.start("full");
      questionPanelAnimation.start("small");
      startTimer(questions[round].time);

      //send to devices
      socket.emit("start answering", {
        phase: "question",
        question: questions[round],
        round,
        roomPin,
      });
    } else if (answerPhase === "exit") {
      questionPanelAnimation.start("hide");
      answersPanelAnimation.start("hide");
    }
    return () => clearTimeout(timeout);
  }, [
    answerPhase,
    answersPanelAnimation,
    questionAnimation,
    questionPanelAnimation,
    CloudBackgroundDescriptionAnimation,
    backgroundAnimation,
    questions,
    roomPin,
    round,
    socket,
  ]);

  useInterval(() => {
    if (timer !== 0 && answerPhase === "show") {
      startTimer(timer - 1);
    }
  }, 1000);
  return (
    <>
      <motion.div
        style={{
          zIndex: 0,
          position: "absolute",
          width: "100vw",
          height: height,
          backgroundColor: theme.colors.purple,
        }}
        animate={CloudBackgroundDescriptionAnimation}
        variants={backgroundVariants}
        exit="exit"
      />
      <Topbar
        roomPin={roomPin}
        question={round + 1}
        noVideo={!questions[round].video}
        questionValue={questions[round].points}
        questionSoort={questions[round].soort}
      />
      <Grid container alignItems="center" justify="center">
        <Grid
          item
          xs={answerPhase ? 11 : 11}
          lg={answerPhase ? 8 : 8}
          style={{ position: answerPhase ? "relative" : "inherit" }}
        >
          {questions[round].intro !== "" && (
            <PanelIntro
              custom={answerPhase}
              animate={questionPanelAnimation}
              variants={variants2}
              layoutId="panel"
              layout="position"
              exit="hide"
              style={{
                flexDirection: answerPhase ? "column" : "row",
                position: answerPhase ? "absolute" : "relative",
                left: answerPhase ? "0" : "inherit",
              }}
            >
              <Grid
                alignItems={answerPhase ? "center" : "center"}
                container
                justify={answerPhase ? "center" : "center"}
              >
                <Grid
                  item
                  lg={answerPhase ? 12 : ""}
                  style={{
                    width: "100%",
                    border: answerPhase ? "1px solid transparent" : "",
                    padding: answerPhase ? "0 15px" : "",
                    background:
                      answerPhase && questions[round].intro !== ""
                        ? theme.colors.white
                        : "",
                    borderRadius: answerPhase ? " 10px 0 10px 10px" : "",
                    marginTop: "0",
                    boxShadow: answerPhase
                      ? "inset 0px -2px 0px -1px rgba(255,255,255,0.075)"
                      : "none",
                  }}
                >
                  <motion.div
                    layout="position"
                    initial={{ opacity: 0, x: 200 }}
                    animate={{ opacity: 1, x: 0, transition: { delay: 1 } }}
                    transition={{
                      ...transitionSlowSpring,
                    }}
                  >
                    <motion.h2
                      initial={{ fontSize: "2rem" }}
                      animate={{
                        color: theme.colors.white,
                        fontSize: answerPhase ? "1.6rem" : "2rem",
                        display: answerPhase ? "none" : "block",
                        opacity: answerPhase ? "0" : "1",
                      }}
                      transition={{ ...transitionSpring }}
                      style={{
                        textShadow: "0 1px 1px rgba(0,0,0,0.4)",
                        fontFamily: "Brevia",
                      }}
                      exit={{ opacity: 0 }}
                    >
                      {questions[round].intro && "Inleiding"}
                    </motion.h2>
                    <motion.p
                      initial={{ fontSize: "2rem" }}
                      animate={{
                        fontSize: answerPhase ? "1.4rem" : "2.2rem",
                        lineHeight: "1.6em",
                        color: answerPhase
                          ? theme.colors.purple
                          : theme.colors.white,
                      }}
                      transition={{ ...transitionSpring }}
                      dangerouslySetInnerHTML={{
                        __html:
                          questions[round].intro !== ""
                            ? questions[round].intro
                            : !answerPhase
                            ? "<h1>Klaar voor de volgende vraag?</h1>"
                            : "",
                      }}
                    />
                  </motion.div>
                </Grid>
              </Grid>
            </PanelIntro>
          )}
          {!answerPhase && (
            <>
              <NavigationNext
                bottom={30}
                right={30}
                initial={{ opacity: 0, display: "none" }}
                animate={{ opacity: 1, display: "block" }}
                transition={{
                  scale: { delay: 0 },
                  default: { delay: 2 },
                }}
                text="Ga naar vraag"
                nextPhase={() => setAnswerPhase("show")}
              />
            </>
          )}
          <PanelQuestion
            variants={variants}
            animate={answersPanelAnimation}
            style={{
              x: 250,
              left: "30%",
              height: height,
              marginTop: "-100px",
              padding:
                questions[round].question_layout === "questiononly" ? 0 : "",
              justifyContent:
                questions[round].question_layout === "questiononly"
                  ? "center"
                  : "inherit",
            }}
          >
            {answerPhase === "show" && (
              <Grid
                container
                alignItems={questions[round].intro ? "center" : "center"}
                justify="center"
                spacing={small ? 2 : 4}
                xs={11}
                lg={"auto"}
              >
                <Grid
                  item
                  style={{
                    width: "100%",
                    marginLeft: questions[round].intro ? "30px" : "0",
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    exit={{ opacity: 0, transition: { delay: 0 } }}
                  >
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: questions[round].name,
                      }}
                      style={{
                        color: theme.colors.white,
                        fontFamily: "Brevia",
                        fontSize: small
                          ? questions[round].question_layout === "questiononly"
                            ? "2.8rem"
                            : "1.2rem"
                          : questions[round].question_layout === "questiononly"
                          ? "2.8rem"
                          : "1.6rem",
                        lineHeight: small
                          ? questions[round].question_layout === "questiononly"
                            ? "2.8rem"
                            : "1.6rem"
                          : questions[round].question_layout === "questiononly"
                          ? "3.6rem"
                          : "1.8rem",
                        marginTop: questions[round].image ? "" : "0",
                        textShadow: "0 1px 1px rgba(0,0,0,0.4)",
                      }}
                    />
                    {questions[round].possible_answers && (
                      <motion.p
                        animate={{
                          fontSize: small ? "1rem" : "1.4rem",
                          lineHeight: "1em",
                          color: theme.colors.white,
                          display: "flex",
                          alignItems: "center",
                        }}
                        transition={{ ...transitionSpring }}
                      >
                        <div
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            width: 25,
                            height: 25,
                            marginRight: 20,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color: theme.colors.brown,
                              fontSize: "0.8em",
                              fontWeight: 900,
                            }}
                          >
                            !
                          </span>
                        </div>{" "}
                        {parseInt(questions[round].possible_answers) ===
                        JSON.parse(questions[round].answers).length
                          ? "Meerdere"
                          : questions[round].possible_answers}{" "}
                        antwoorden mogelijk
                      </motion.p>
                    )}
                    {questions[round].tip && (
                      <motion.p
                        animate={{
                          fontSize: "1.4rem",
                          lineHeight: "1.4em",
                          color: theme.colors.white,
                          alignItems: "center",
                          display: "flex",
                        }}
                        transition={{ ...transitionSpring }}
                      >
                        <img
                          width={40}
                          height={40}
                          style={{ marginRight: 10 }}
                          src={bulb}
                          alt="tipsicon"
                        />
                        <p
                          dangerouslySetInnerHTML={{
                            __html: questions[round].tip,
                          }}
                        />
                      </motion.p>
                    )}
                    {questions[round].type !== "schatting" &&
                      questions[round].type !== "open" &&
                      questions[round].type !== "waarnietwaar" && (
                        <AnswersHolder>
                          {JSON.parse(questions[round].answers).map(
                            (answer, index) =>
                              answer && (
                                <motion.div
                                  style={{
                                    opacity: 0,
                                    y: -30,
                                  }}
                                  animate={questionAnimation}
                                  variants={variants3}
                                  custom={index}
                                  key={answer}
                                >
                                  <Grid container alignItems="center">
                                    <Grid item xs={2} sm={1}>
                                      {questions[round].type !==
                                      "waarnietwaar" ? (
                                        <LetterBox
                                          style={{
                                            opacity: 1,
                                            y: 0,
                                            backgroundColor: theme.colors.green,
                                          }}
                                          boxShadowColor={
                                            theme.colors.greenDarker
                                          }
                                        >
                                          {numberToLetter(index)}
                                        </LetterBox>
                                      ) : (
                                        <LetterBox
                                          style={{
                                            opacity: 1,
                                            y: 0,
                                            backgroundColor: theme.colors.green,
                                          }}
                                          boxShadowColor={
                                            theme.colors.greenDarker
                                          }
                                        >
                                          {numberToLetter(answer)}
                                        </LetterBox>
                                      )}
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                      <motion.h3
                                        style={{
                                          fontFamily: "proxima-nova",
                                          fontWeight: "normal",
                                          opacity: 1,
                                          y: 0,
                                          fontSize: "1.4em",
                                        }}
                                      >
                                        {answer}
                                      </motion.h3>
                                    </Grid>
                                  </Grid>
                                </motion.div>
                              )
                          )}
                        </AnswersHolder>
                      )}
                  </motion.div>
                </Grid>
              </Grid>
            )}
          </PanelQuestion>
        </Grid>
      </Grid>
      {answerPhase && (
        <>
          <motion.div
            initial={{ x: 75, opacity: 1 }}
            animate={{ x: 0 }}
            transition={{ delay: 2 }}
            exit={{ x: 75, transition: { delay: 0 } }}
            style={{
              height: "calc(100vh - 60px)",
              alignItems: "center",
              display: "flex",
            }}
          >
            <ProgressTeams
              teams={teams}
              playersAnswered={playersAnswered}
              md={md}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 1 } }}
            exit={{ opacity: 0, transition: { delay: 0 } }}
            style={{
              width: small ? "calc(100% - 30px)" : "initial",
              position: "absolute",
              bottom: "30px",
              right: small ? "0" : "30px",
              display: "flex",
              alignItems: "center",
              padding: small ? "0 15px" : "0",
              justifyContent: "space-between",
            }}
          >
            <TimerBar
              timer={timer}
              totalTime={questions[round].time}
              nextPhase={() => setPhase("submitAnswers")}
            />
            <NavigationNext
              initial={{ opacity: 0, display: "none" }}
              animate={{ opacity: 1, display: "block" }}
              whileHover={{
                scale: 1.1,
                cursor: "pointer",
              }}
              transition={{
                scale: { delay: 0 },
                opacity: { delay: 2 },
              }}
              text="Bekijk het antwoord"
              nextPhase={() => {
                if (teams.length !== playersAnswered.length) {
                  setNotAllPlayers(true);
                } else {
                  setPhase("revealAnswer");
                }
              }}
              bottom={60}
              flexPosition
              small={small}
            />
          </motion.div>
        </>
      )}
      <TimesUpModalSocket
        socket={socket}
        roomPin={roomPin}
        timer={timer}
        currentPhase={answerPhase ? "answerPhase" : ""}
        nextPhase={() => setPhase("revealAnswer")}
      />
      <NotAllPlayersModal
        notAllPlayers={notAllPlayers}
        setNotAllPlayers={setNotAllPlayers}
        socket={socket}
        roomPin={roomPin}
        timer={timer}
        currentPhase={answerPhase ? "answerPhase" : ""}
      />
    </>
  );
};

export default Question;
