import React, { useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import deleteImage from "../../../../../static/media/delete.png";
import { useMainStore } from "../../../../../store";

const Box = styled(motion.div)`
  background-color: ${(props) => props.theme.colors.grey};
  padding: 1em;
  border-radius: 10px;
  margin-bottom: 1.5em;
  min-height: 37px;
  ${(props) =>
    props.small &&
    css`
      min-height: 0px;
      padding: 0.5em;
      margin-bottom: 0em;
      cursor: pointer;
    `}

  ${(props) =>
    props.disable &&
    css`
      pointer-events: none;
    `}
`;

const EstimationInput = ({
  setChosenAnswer,
  answer,
  input,
  setInput,
  unit,
  prepend,
  confetti,
  disable,
  small,
}) => {
  useEffect(() => {
    setChosenAnswer(input);
  }, [input, setChosenAnswer]);

  const { theme } = useMainStore();

  const getColor = () => {
    if (confetti === true) return "#43ba69";
    else if (confetti === false) return "#ba4343";
    else return theme.colors.grey;
  };

  return (
    <div>
      <Box animate={{ backgroundColor: getColor() }}>
        <motion.h2
          animate={{
            color: confetti == undefined ? theme.colors.purple : "#fff",
          }}
          style={{
            padding: small ? 0 : 0,
            fontSize: small ? "2em" : "3em",
            fontWeight: 900,
            textAlign: " right",
            margin: 0,
          }}
        >
          {disable
            ? answer && prepend
              ? `${unit}${answer}`
              : answer && `${answer}${unit}`
            : input && prepend
            ? `${unit}${input}`
            : input && `${input}${unit}`}
        </motion.h2>
      </Box>
      <Grid spacing={2} container>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 12].map((item) => (
          <Grid item xs={4}>
            <Box
              disable={disable}
              onClick={() => {
                if (item === 12) {
                  setInput((input) => input.slice(0, -1));
                } else if (item !== 10 && input?.length !== 3) {
                  setInput((input) =>
                    input !== undefined ? `${input}${item}` : `${item}`
                  );
                }
              }}
              small
              style={{
                pointerEvents: disable ? "none" : "inherit",
                backgroundColor:
                  item >= 10 ? theme.colors.purple : theme.colors.grey,
              }}
            >
              <h2
                style={{
                  fontSize: small ? "1em" : "1.5em",
                  fontWeight: 900,
                  margin: 0,
                  color: theme.colors.purple,
                  userSelect: "none",
                  textAlign: "center",
                }}
              >
                {item !== 12 ? (
                  item
                ) : (
                  <img
                    alt="delete"
                    width={small ? 30 : 50}
                    height={"auto"}
                    src={deleteImage}
                  />
                )}
              </h2>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default EstimationInput;
