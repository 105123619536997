import React, { useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import slide1 from "../../../../../static/sliced/1.jpg";
import slide2 from "../../../../../static/sliced/2.jpg";
import slide3 from "../../../../../static/sliced/3.jpg";

import bulb from "../../../../../static/emojis/bulb.png";
import dashing from "../../../../../static/emojis/dashing.png";
import trophy from "../../../../../static/emojis/trophy.png";
import target from "../../../../../static/emojis/target.png";
import calculate from "../../../../../static/emojis/calculate.png";
import open from "../../../../../static/emojis/open.png";

import { transitionSpring } from "./SelectLesson";
import { useSocketStore } from "../../../../../store/socketStore.js";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import { useGAEventsTracker } from "../../../../../hooks/useGAEventsTracker.js";
import styled from "styled-components";

const QuestionIntro = ({ socket }) => {
  const {
    setPhase,
    questions,
    round,
    decision,
    roomPin,
    resetPlayerAnswering,
    setAnswerPhase,
    theme,
  } = useSocketStore();
  const { small, height } = useWindowDimensions();
  const GAEventsTracker = useGAEventsTracker("cashquiz-plus");

  useEffect(() => {
    GAEventsTracker(`ronde ${round + 1} begonnen`);
    setAnswerPhase(false);
    socket.emit("next question", roomPin);
    resetPlayerAnswering();
    setTimeout(() => {
      if (!decision) {
        if (!questions[round].video) setPhase("question");
        else setPhase("questionVideo");
      } else {
        setPhase("questionDecision");
      }
    }, 3000);
  }, [
    setPhase,
    questions,
    round,
    decision,
    resetPlayerAnswering,
    roomPin,
    socket,
    setAnswerPhase,
  ]);

  const renderIcon = () => {
    if (decision) {
      return trophy;
    }
    switch (questions[round].soort) {
      case "Verdiepingsvraag":
        return bulb;
      case "Schattingsvraag":
        return target;
      case "Rekenvraag":
        return calculate;
      case "Snelle vraag":
        return dashing;
      case "Beslissingsvraag":
        return trophy;
      case "Score":
        return trophy;
      case "Open vraag":
        return open;
      default:
        break;
    }
  };

  return (
    <div>
      <Grid
        style={{
          height: height,
          width: "100vw",
          position: "absolute",
          background: theme.colors.purple,
          backgroundImage: `url(${
            //every 3 rounds, change the background
            round % 3 === 0 ? slide1 : round % 3 === 1 ? slide2 : slide3
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          gap: 32,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{
          opacity: 0,
          transition: {
            ...transitionSpring,
            delay: 0.2,
          },
        }}
        transition={{ ...transitionSpring, delay: 0.1 }}
        component={motion.div}
        justify="center"
        alignItems="center"
        container
      >
        <IconWrapper
          transition={{ ...transitionSpring, delay: 0.3 }}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{
            opacity: 0,
            scale: 0,
            transition: {
              ...transitionSpring,
              delay: 0,
            },
          }}
          style={{
            zIndex: 10,
            width: small ? "95px" : "65px",
            height: small ? "95px" : "65px",
          }}
        >
          <motion.img
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 2 }}
            transition={{ ...transitionSpring, delay: 0.4 }}
            style={{
              rotate: -5,
              y: renderIcon() === target ? -22 : -30,
              x: renderIcon() === target ? 18 : -3,
            }}
            src={renderIcon()}
          />
          {!decision && (
            <NumberTag
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{
                opacity: 0,
                transition: {
                  ...transitionSpring,
                  delay: 0,
                },
              }}
              transition={{ ...transitionSpring, delay: 0.4 }}
              style={{ rotate: -5 }}
            >
              {round + 1}/<span>10</span>
            </NumberTag>
          )}
        </IconWrapper>
        <Title
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{
            opacity: 0,
            y: 30,
            transition: {
              ...transitionSpring,
              delay: 0,
            },
          }}
          transition={{ ...transitionSpring, delay: 0.4 }}
        >
          {!decision ? questions[round].soort : "Beslissingsvraag"}
        </Title>
      </Grid>
    </div>
  );
};

const IconWrapper = styled(motion.div)`
  width: 95px;
  height: 95px;
  padding: 24px;
  border: 13px solid #4a6dfe;
  border-radius: 50%;
  background: #173fff;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
`;

const NumberTag = styled(motion.div)`
  position: absolute;
  width: fit-content;
  padding: 14px;
  border-radius: 12px 12px 12px 0px;
  background: red;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  font-family: "Brevia";
  top: -50px;
  right: -80px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  span {
    opacity: 0.85;
  }
`;

const Title = styled(motion.h1)`
  font-size: 4.5rem;
  font-weight: 700;
  color: #fff;
  font-family: "Brevia";
`;

export default QuestionIntro;
