import { motion } from "framer-motion";
import React from "react";

const IconVink = ({ color }) => {
  return (
    <motion.svg
      initial={{opacity: 0}}
      animate={{opacity: 1 }}
      // style={{ fill: color }}
      id="bold"
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 515.556 515.556"
      height="100%"
      viewBox="0 0 515.556 515.556"
      width="100%"
    >
       <path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/>
    </motion.svg>
  );
}

export default IconVink;
