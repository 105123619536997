import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { useMainStore } from "../../../../store";

const TeamsHolder = styled(motion.div)`
  background: #fff;
  box-shadow: 0 3px 0px #baabcf;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justifycontent: space-between;
  border-radius: 10px 0 0 10px;
  padding: 15px 15px;
  box-shadow: inset 0px -2px 0px -1px rgba(255, 255, 255, 0.075);
  @media only screen and (max-width: 1024px) {
    right: 0;
    padding: 5px;
  }
`;
const Circle = styled(motion.div)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 1.2em;
  @media only screen and (max-width: 1024px) {
    width: 20px;
    height: 20px;
    font-size: 0.7em;
  }
`;

const ProgressTeams = ({ playersAnswered, teams, md }) => {
  const { theme } = useMainStore();

  return (
    <TeamsHolder>
      <Grid
        md={md}
        spacing={md ? 1 : 2}
        container
        alignItems="center"
        justidy="center"
        direction="column"
      >
        {teams.map((team) => {
          const answered = playersAnswered.some((playerA) => {
            return parseInt(playerA.user) === parseInt(team.group);
          });
          return (
            <Grid key={team.group} item>
              <Circle
                animate={{
                  backgroundColor: !answered
                    ? "rgba(0,0,0,0.08)"
                    : theme.colors.green,
                  color: !answered ? theme.colors.purple : "#fff",
                }}
              >
                {team.group}
              </Circle>
            </Grid>
          );
        })}
      </Grid>
    </TeamsHolder>
  );
};

export default ProgressTeams;
