import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import React from "react";

export const Button = styled(motion.button)`
  border: none;
  border-radius: 0.8rem;
  color: ${(props) => props.theme.colors.white};
  box-shadow: 0 5px 0 ${(props) => props.theme.colors.purpleDarker};
  margin-bottom: 5px !important;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.6em;
  font-weight: 700;
  z-index: 1;

  @media only screen and (max-width: 600px) {
    padding: 15px 15px;
    font-size: 1.2em;
  }
  min-width: 100px;
  font-family: "proxima-nova";
  width: ${(props) => props.width || ""};
  margin: ${(props) => props.margin || "0 0 0 0"};
  cursor: pointer;
  transition: background 0.3s ease;
  transition: transform 0.3s ease;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0.8rem;

    background: rgb(250, 243, 161);
    background: ${(props) => props.theme.colors.goldGradient};
    z-index: -1;
    transition: opacity 0.2s linear;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active {
    transform: translateY(5px);
  }

  &:focus {
    outline: 0;
  }
  ${(props) =>
    props.positionFlex &&
    css`
      border: 5px solid red !important;
      color: red;
    `}

  ${(props) =>
    props.primary &&
    css`
      background: ${(props) => props.theme.colors.goldGradient};
    `}

  ${(props) =>
    props.secondary &&
    css`
      background-color: ${props.theme.colors.secondary};
    `}

    ${(props) =>
    props.noHover &&
    css`
      &:hover::before {
        opacity: 0;
      }
    `}
    
  ${(props) =>
    props.basic &&
    css`
      background: none;
      background-color: #947cbb;
      box-shadow: none;
      color: #fff;

      &:hover::before {
        opacity: 0;
      }
    `}
    

    ${(props) =>
    props.light &&
    css`
      background: none;
      background-color: ${props.theme.colors.default};
      box-shadow: 0 5px 0 ${(props) => props.theme.colors.greyDarker};
      color: #000;

      &:hover {
        background: ${(props) => props.theme.colors.grey};
      }

      &:hover::before {
        opacity: 0;
      }

      &:active {
        box-shadow: none;
        transform: translateY(5px);
      }
    `}

    ${(props) =>
    props.start &&
    css`
      text-align: start;
    `}

    ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

    ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

    ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
    
    ${(props) =>
    props.disable &&
    css`
      pointer-events: none;
      background: none;
      background-color: ${(props) => props.theme.colors.grey};
      color: ${(props) => props.theme.colors.white};
      box-shadow: 0 5px 0 ${(props) => props.theme.colors.grey};
      &:hover::before {
        opacity: 0;
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.purple};
        color: ${(props) => props.theme.colors.white};
      }
    `}
    
    ${(props) =>
    props.disableGreen &&
    css`
      pointer-events: none;
      background: none;
      background-color: ${(props) => props.theme.colors.green} !important;
      color: ${(props) => props.theme.colors.black} !important;
      box-shadow: 0 5px 0 ${(props) => props.theme.colors.greenDarker};

      &:hover::before {
        opacity: 0;
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.purple};
        color: ${(props) => props.theme.colors.white};
      }
    `}

    ${(props) =>
    props.wrong &&
    css`
      box-shadow: none;
      transform: translateY(5px);
      background-color: #ba4343;
    `}

      ${(props) =>
    props.right &&
    css`
      box-shadow: none;
      transform: translateY(5px);
      background-color: #43ba69;
    `}
`;

export const TinyButton = styled(motion.button)`
  height: 40px;
  border: none;
  border-radius: 0.4rem;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.blue};
  transition: background 0.2s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;

  &:hover {
    background: ${(props) => props.theme.colors.blue};
  }

  &:active {
    box-shadow: none;
    transform: translateY(5px);
  }

  &:focus {
    outline: 0;
  }

  p {
    font-family: "proxima-nova";
    margin: 0 5px 0 5px;
  }

  ${(props) =>
    props.outlined &&
    css`
      background: none;
      background-color: rgba(0, 0, 0, 0);

      border: 1px solid #a54f18;
      box-shadow: none;

      &:hover {
        background-color: ${(props) => props.theme.colors.purple};
        color: ${(props) => props.theme.colors.white};
      }
    `}

  ${(props) =>
    props.primary &&
    css`
      position: relative;
      padding: 10px 20px;
      font-weight: 700;
      color: #a54f18;
      z-index: 1;
      background: rgb(255, 218, 121);
      background: ${(props) => props.theme.colors.goldGradient};

      &:before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0.4rem;

        background: rgb(255, 231, 169);
        background: ${(props) => props.theme.colors.goldGradient};
        z-index: -1;
        transition: opacity 0.2s linear;
        opacity: 0;
      }

      &:hover::before {
        opacity: 1;
      }

      &:hover {
        background: ${(props) => props.theme.colors.goldGradient};
      }
    `}
`;

export const ExplanationButton = styled(motion.button)`
  border: ${(props) => `rgba(255,255,255,0.4) 2px solid`};
  border-radius: 0.4rem;
  color: rgba(255, 255, 255, 0.4);
  margin-bottom: 5px !important;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.6em;
  background: rgba(0, 0, 0, 0);
  @media only screen and (max-width: 600px) {
    padding: 15px 15px;
    font-size: 1.2em;
  }
  position: absolute;
  min-width: 100px;
  font-family: "proxima-nova";
  width: ${(props) => props.width || ""};
  margin: ${(props) => props.margin || "0 0 0 0"};
  cursor: pointer;
  transition: background 0.2s ease;
  transition: transform 0.2s ease;
  transform: translate(10px, 0px);

  &:hover {
    transform: translate(10px, -5px);
  }

  &:active {
    transform: translate(10px, 5px);
  }

  &:focus {
    outline: 0;
  }

  p {
    font-family: "proxima-nova";
    margin: 0 5px 0 5px;
  }
`;

export const NavigationNext = ({
  text,
  nextPhase,
  transition,
  bottom,
  flexPosition,
  right,
}) => {
  if (flexPosition) {
    return (
      <Button primary onClick={nextPhase}>
        {text}
      </Button>
    );
  } else {
    return (
      <motion.div
        style={{
          zIndex: 2,
          cursor: "pointer",
          position: "absolute",
          bottom: bottom,
          right: right,
        }}
        initial={{ opacity: 0, display: "none" }}
        animate={{ opacity: 1, display: "block" }}
        // whileHover={{
        //   scale: 1.1,
        //   cursor: "pointer",
        // }}
        transition={transition}
        exit={{ opacity: 0, transition: { delay: 0 } }}
      >
        <Button primary onClick={nextPhase}>
          {text}
        </Button>
      </motion.div>
    );
  }
};
