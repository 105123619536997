import React, { useEffect, useRef, useState } from "react";

import BackgroundMobile from "../../atoms/BackgroundMobile";
import { Button } from "../../atoms/Buttons";
import CashQuizLogo from "../../atoms/CashQuizLogo";
import Grid from "@bit/mui-org.material-ui.grid";
import { HeaderTwo } from "../../atoms/Typography";
import { Input } from "../../atoms/Input";
import NoSleep from "nosleep.js";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { transitionSpring } from "../hostpages/SelectLesson";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";

const JoinRoom = ({ socket }) => {
  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        callback.apply(null, args);
      }, wait);
    };
  };
  const { register, watch, handleSubmit } = useForm();
  const { search } = useLocation();
  const [roomCode, setRoomCode] = useState("");
  const [clicked, setClicked] = useState(false);
  const isMounted = useRef(false);
  const [joinCooldown, setJoinCooldown] = useState(false);
  useEffect(() => {
    if (search.indexOf("?roompin=") >= 0) {
      const roomCode = search.replace("?roompin=", "");
      localStorage.setItem("roomPin", roomCode);
      setRoomCode(roomCode);
    }
  }, []);
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    if (joinCooldown) {
      setTimeout(() => {
        setJoinCooldown(false);
      }, 2000);
    }
  }, [joinCooldown]);
  const roomPin = watch("roomPin") && watch("roomPin").toUpperCase();
  const onSubmit = debounce(() => {
    if (joinCooldown) return;
    if (!roomPin) toast("Room pin niet ingevuld.");
    else if (!clicked) {
      let user;
      if (localStorage.getItem("roomPin") === roomPin) {
        user = parseInt(localStorage.getItem("user"));
      }
      localStorage.setItem("roomPin", roomPin);
      socket.emit("check room", { roomPin: roomPin, user });
    }
  }, 250);
  const { height } = useWindowDimensions();
  let noSleep = new NoSleep();
  useEffect(() => {
    if ((search && socket) || (roomCode && socket)) {
      setClicked(true);
      let pin = search.split("=")[1];
      let user;
      if (localStorage.getItem("roomPin") === pin) {
        user = parseInt(localStorage.getItem("user"));
      }
      localStorage.setItem("roomPin", pin);
      socket.emit("check room", { roomPin: pin, user });
    }
  }, [search, socket]);
  useEffect(() => {
    if (!socket) return <></>;
    if (roomCode) {
      setTimeout(() => {
        handleSubmit(onSubmit);
      }, 250);
    }
  }, [socket]);
  return (
    <>
      <CashQuizLogo />
      <motion.div exit={{ opacity: 0 }}>
        <Grid
          style={{
            height: height,
            textAlign: "center",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid component={motion.div} layout item xs={10} md={6} lg={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container justify="center" spacing={4}>
                <Grid item xs={12}>
                  <HeaderTwo
                    layout
                    transition={{ ...transitionSpring }}
                    style={{
                      fontSize: "2.5em",
                      lineHeight: "1.1em",
                      fontFamily: "proxima-nova",
                    }}
                  >
                    Vul code in
                  </HeaderTwo>
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "1.6em",
                      lineHeight: "1.1em",
                      margin: "0",
                    }}
                  >
                    Deze zie je op het digibord
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    value={watch("roomPin")?.toUpperCase()}
                    placeholder="Spel code"
                    name="roomPin"
                    ref={register}
                    defaultValue={
                      localStorage.getItem("roomPin")
                        ? localStorage.getItem("roomPin")
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      noSleep.enable();
                      setTimeout(() => {
                        setJoinCooldown(true);
                      }, 25);
                    }}
                    type="submit"
                    primary
                    fullWidth
                  >
                    Doe mee!
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </motion.div>
      <BackgroundMobile height={height} />
    </>
  );
};

export default JoinRoom;
