import React from "react";
import { motion } from "framer-motion";
import Grid from "@bit/mui-org.material-ui.grid";
import styled from "styled-components";
import { useMainStore } from "../../../../store";
import { Button } from "./Buttons";
import { useHistory } from "react-router-dom";

const ModalStyled = styled(motion.div)`
  background-color: #fff;
  max-width: calc(100% - 180px);
  padding: 60px;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  opacity: 0;
  @media only screen and (max-width: 600px) {
    padding: 30px 15px;
    max-width: calc(100% - 60px);
  }
`;

const Modal = ({ round, activegame, setActiveGame, homework }) => {
  const { setPhase, setRound, setTeams, setShowCoins, theme, typeQuiz } = useMainStore();
  const history = useHistory();

  return (
    <>
      <motion.div
        animate={{
          opacity: activegame === 1 ? "100" : "0",
        }}
        exit={{ opacity: 0, scale: 0.1 }}
        style={{
          backgroundColor: theme.colors.grey,
          height: "100vh",
          width: "100vw",
          position: "absolute",
          zIndex: 100,
          bottom: 0,
          left: 0,
        }}
      >
        <Grid
          style={{ height: "100vh" }}
          container
          alignItems="center"
          justify="center"
        >
          <ModalStyled
            style={{ opacity: 0 }}
            layout
            initial={{ opacity: 0, scale: 0.1 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.1 }}
          >
            <motion.h1
              style={{
                color: theme.colors.purple,
                fontFamily: "proxima-nova",
                marginTop: 0,
              }}
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              {homework ? "Was je nog bezig?" : "Actief spel gevonden"}
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              style={{
                fontSize: "1.5em",
              }}
            >
              {homework ? "Wil je verder gaan?" : "Wil je verder spelen?"}
            </motion.p>
            <Button
              primary
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover={{
                scale: 1.05,
                cursor: "pointer",
              }}
              transition={{
                scale: { delay: 0 },
                default: 0.5,
              }}
              onClick={() => {
                if (round < 10) {
                  setPhase("quizStart");
                  history.push(
                      typeQuiz === "cashquiz" ? "/cashquiz" : "/cashquiz-plus"
                  );
                } else {
                  setRound(9);
                  setPhase("end");
                }
                setShowCoins(false);
              }}
            >
              {homework ? "Ja, ga verder" : "Ja, speel verder"}
            </Button>
            <Button
              light
              style={{ marginLeft: "10px" }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover={{
                scale: 1.05,
                cursor: "pointer",
              }}
              transition={{
                scale: { delay: 0 },
                default: 0.5,
              }}
              onClick={() => {
                localStorage.removeItem("gameid");
                setRound(0);
                setTeams([""]);
                setActiveGame(0);
              }}
            >
              Nee
            </Button>
          </ModalStyled>
        </Grid>
      </motion.div>
    </>
  );
};
export default Modal;
