import React, { useEffect, useState } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import styled from "styled-components";
import { QRCodeSVG } from "qrcode.react";

import { useSocketStore } from "../../../../../store/socketStore";
import { Background } from "../../atoms/Backgrounds";
import { Button } from "../../atoms/Buttons";
import PlayerBox from "../../molecules/PlayerBox";

const Box = styled(motion.div)`
  background ${(props) => props.theme.colors.white};
  border-radius: 5px;
  box-shadow: 0px 4px 0px #b2a1c7;
  border: 1px solid #b2a1c7;
  padding: 32px;
  text-align: start;

  h1,p {
    margin: 0;
    line-height: 1.2;
  
  }

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 16px;
  }
 p {
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    color: #12191E;
    margin-bottom: 16px;
  }

  .link {
  font-weight: 600;
  border-radius: 5px;
  box-shadow: 0px 3px 0px #b2a1c7;
  padding: 4px 12px;
  color: ${(props) => props.theme.colors.purple};
  text-align: center;
  border: 0.5px solid #b2a1c7;
  width:fit-content;
  margin-bottom: 0;

  }
`;

const QRCodeWrapper = styled(motion.div)`
  gap: 24px;
  flex-direction: column;
  cursor: pointer;
  max-width: 100%;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 3px 0px #b2a1c7;
  border: 0.5px solid #b2a1c7;
  p {
    margin-bottom: 0;
    font-size: 1rem;
  }
`;

const Pin = styled(motion.div)`
  width: ${(props) => (props.largeQR ? "100%" : "60%")};
  margin-top: ${(props) => (props.largeQR ? "24px" : "0px")};
  padding-block: 16px;
  background: #f8f8f8;
  border: 1px solid rgba(42, 57, 148, 0.5);
  box-shadow: 0px 4px 0px #b2a1c7;
  border-radius: 8px;
  font-size: 2.5rem;
  font-weight: 900;
  color: ${(props) => props.theme.colors.purple};
  text-align: center;
`;

const Lobby = ({ socket }) => {
  const {
    setShowCoins,
    roomPin,
    teams,
    setPhase,
    setQuestions,
    level,
    gameid,
    personalCode,
    lesson,
  } = useSocketStore();

  useEffect(() => {
    setShowCoins(false);
    fetch(
      process.env.REACT_APP_SERVER +
        "questions/" +
        level?.id +
        "/af091a0655214d6de1188850b6ff4512"
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setQuestions(result);
        },
        (error) => console.log("Er is iets misgegaan.")
      );
  }, [setQuestions, level?.id, setShowCoins]);

  const startGame = () => {
    let params = level.params;
    const parsed = JSON.parse(params);
    socket.emit("start game", {
      roomPin,
      gameid,
      groups: teams,
      points: parsed.points,
    });
    setPhase("tips");
    localStorage.setItem("isHost", true);
  };

  const createSocketGame = (level) => {
    // setLevel(level);
    if (personalCode) {
      //game is not set yet
      socket.emit("create room", {
        level,
        code: personalCode,
        lesson,
        host: "gastdocent",
        quiztype: "cashquiz-plus",
      });
    } else {
      socket.emit("update room", { level, gameid });
      setPhase("lobby");
    }
  };

  useEffect(() => {
    createSocketGame(level);
  }, []);
  const [largeQR, setLargeQR] = useState(false);
  return (
    <>
      <motion.div exit={{ opacity: 0 }}>
        <Grid
          style={{
            height: "100vh",
            textAlign: "center",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid
            component={motion.div}
            layout
            item
            xs={10}
            md={10}
            lg={8}
            xl={8}
          >
            <Grid
              container
              component={motion.div}
              layout
              alignItems="center"
              justify="center"
              spacing={8}
            >
              <Grid component={motion.div} layout xs={7} item>
                <Box>
                  {!largeQR ? (
                    <QRSmall
                      roomPin={roomPin}
                      largeQR={largeQR}
                      setLargeQR={setLargeQR}
                    />
                  ) : (
                    <QRLarge
                      roomPin={roomPin}
                      largeQR={largeQR}
                      setLargeQR={setLargeQR}
                    />
                  )}
                </Box>
              </Grid>
              {teams.length > 0 && (
                <Grid xs={5} item>
                  <PlayerBox teams={teams} />
                  <Grid
                    component={motion.div}
                    layout
                    container
                    spacing={4}
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Button
                        disableGreen={!teams.length}
                        fullWidth
                        primary
                        onClick={() => startGame()}
                        exit={{ opacity: 0 }}
                        whileTap={{ scale: 0.93 }}
                        whileHover={{
                          scale: 1.03,
                          delay: 0,
                        }}
                      >
                        {!teams.length
                          ? "Nog geen spelers"
                          : "Start met " +
                            teams.length +
                            " team" +
                            (teams.length > 1 ? "s" : "")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </motion.div>
      <Background initialBig background={"cloudBackgroundBig"} />
    </>
  );
};

export default Lobby;
const QRSmall = (props) => {
  const { largeQR, setLargeQR, roomPin } = props;
  return (
    <>
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={6}>
          <QRCodeWrapper onClick={() => setLargeQR(!largeQR)}>
            <QRCodeSVG
              width={"100%"}
              height={"100%"}
              value={`https://cashquiz-geldheld.bankvoordeklas.nl/cashquiz-speler?roompin=${roomPin}`}
            />
            <p>Klik om te vergroten</p>
          </QRCodeWrapper>
        </Grid>
        <Grid item xs={6}>
          <h1>Hoe speel ik mee?</h1>
          <p style={{ marginBottom: 8 }}>
            Eén iemand uit het team <br /> scant de QR-code
          </p>
          <p> Of gaat naar</p>
          <p className="link">cashquiz-geldheld.nu</p>
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: "48px" }}
        container
        alignItems="center"
        direction="column"
        justify="center"
      >
        <p>Voer de code in en login</p>
        <Pin>{roomPin}</Pin>
      </Grid>
    </>
  );
};

const QRLarge = (props) => {
  const { largeQR, setLargeQR, roomPin } = props;
  return (
    <>
      <Grid
        container
        spacing={3}
        alignItems="center"
        onClick={() => setLargeQR(!largeQR)}
      >
        <Grid item xs={12}>
          <QRCodeWrapper>
            <QRCodeSVG
              height={"100%"}
              width={"100%"}
              value={`https://cashquiz.bankvoordeklas.nl/cashquiz-speler?roompin=${roomPin}`}
            />
            <p>Klik om te verkleinen</p>
          </QRCodeWrapper>
          <Pin largeQR={largeQR}>{roomPin}</Pin>
        </Grid>
      </Grid>
    </>
  );
};
