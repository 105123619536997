import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";

import { useMainStore } from "../../../store";
import { motion } from "framer-motion";
import { LessonDropdown } from "../../../themes/2023/components/molecules/LessonDropdown";
import { TextLink } from "../../../themes/2023/components/atoms/TextLink";
import CashQuizLogo from "../../../themes/2023/components/atoms/CashQuizLogo";
import { Background } from "../../../themes/2023/components/atoms/Backgrounds";

export const transitionSpring = {
  type: "spring",
  stiffness: 800,
  damping: 100,
  mass: 5.1,
};

const SelectLesson = () => {
  const { lessons, setPhase } = useMainStore();

  return (
    <>
      <CashQuizLogo />
      <motion.div exit={{ opacity: 0 }}>
        <Grid
          style={{
            height: "100vh",
            textAlign: "center",
            padding: "100px 0",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid component={motion.div} layout="position" item xs={10} lg={8}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
              spacing={4}
            >
              <Grid item xs={10}>
                <motion.h1
                  transition={{
                    opacity: { delay: 0.2 },
                    ...transitionSpring,
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  layout
                  style={{ lineHeight: "0" }}
                >
                  Kies je gastles
                </motion.h1>
              </Grid>
              <Grid item xs={8}>
                <LessonDropdown lessons={lessons} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </motion.div>
      <TextLink
        navigationPrev
        onClick={() => setPhase("personalCode")}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        whileHover={{
          scale: 1.1,
          cursor: "pointer",
        }}
        transition={{
          scale: { delay: 0 },
          default: 0.5,
        }}
        bottom={"50px"}
        left={"50px"}
        style={{
          position: "absolute",
        }}
      >
        terug
      </TextLink>
      <Background big background={"cloudBackgroundBig"} />
    </>
  );
};

export default SelectLesson;
