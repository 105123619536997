import Grid from "@bit/mui-org.material-ui.grid";
import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useSocketStore } from "../../../../store/socketStore";
import AnswerTabs from "../../../2023/components/molecules/AnswerTabs";
import { Button } from "../atoms/Buttons";
import OpenAnswer from "../atoms/OpenAnswer";
import { transitionSpring } from "../pages/hostpages/SelectLesson";

const RevealAnswerOpen = ({ playersAnswered, setResultsSend, resultsSend }) => {
  const { questions, round, gameid, theme, teams } = useSocketStore();
  const [TeamsResults, setTeamsResults] = useState(
    teams.map((player, index) => {
      return {
        user: player.group,
        answer:
          playersAnswered.find((p) => p.user === player.group)?.answer ||
          "Geen antwoord",
        correct: undefined,
      };
    })
  );

  const [selectedPlayer, setSelectedPlayer] = useState(0);
  const [confirm, setConfirm] = useState(false);

  const handleSendGoodAnswers = async () => {
    TeamsResults.map((team) => {
      let info = {
        answer: team.answer,
        group: team.user,
        question: questions[round].ordering,
        gameid: gameid,
      };

      if (team.correct) {
        info.correct = true;
        info.points = questions[round].points;
        fetch(process.env.REACT_APP_SERVER + "score/set-player-answer", {
          method: "POST",
          body: JSON.stringify(info),
          headers: { "Content-Type": "application/json" },
        }).catch((error) => {
          console.error("Error:", error);
        });
      } else {
        info.correct = false;
        info.points = 0;
        fetch(process.env.REACT_APP_SERVER + "score/set-player-answer", {
          method: "POST",
          body: JSON.stringify(info),
          headers: { "Content-Type": "application/json" },
        }).catch((error) => {
          console.error("Error:", error);
        });
      }
    });
    setResultsSend(true);
  };
  return (
    <Grid container justify="center" alignItems="center" component={Container}>
      <Grid item xs={10}>
        <h2>{questions[round].name}</h2>
        <Wrapper>
          {confirm ? (
            <>
              <motion.h2
                style={{
                  color: theme.colors.purple,
                  fontSize: "1.5rem",
                  fontFamily: "Brevia",
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ...transitionSpring, delay: 0.4 }}
              >
                Alle antwoorden zijn ingevuld
              </motion.h2>
              <Button
                disableGreen={resultsSend}
                primary
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                whileHover={{
                  scale: 1.05,
                  cursor: "pointer",
                }}
                transition={{
                  opacity: { delay: 0.6 },
                  default: 2.5,
                }}
                onClick={() => !resultsSend && handleSendGoodAnswers()}
              >
                {resultsSend ? "Verzonden" : "Verstuur antwoorden"}
              </Button>
            </>
          ) : (
            <OpenAnswer
              TeamsResults={TeamsResults}
              setTeamsResults={setTeamsResults}
              setConfirm={setConfirm}
              players={TeamsResults}
              setSelectedPlayer={setSelectedPlayer}
              selectedPlayer={selectedPlayer}
            />
          )}
        </Wrapper>
        <AnswerTabs
          type="openvraag"
          setConfirm={setConfirm}
          players={TeamsResults}
          selectedPlayer={selectedPlayer}
          setSelectedPlayer={setSelectedPlayer}
        />
      </Grid>
    </Grid>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 60px);
  width: 100%;
  padding: 0 0 0 0;
  background: ${(props) => props.theme.colors.white};

  h2,
  h3 {
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.purple};
  }

  h2 {
    margin-bottom: 24px;
  }
`;

const Wrapper = styled.div`
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;

  .answer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding-inline: 20px;

    h3 {
      /* flex: 1; */
      margin: 0;
      min-width: fit-content;
      color: ${(props) => props.theme.colors.purple};
    }

    p {
      margin: 0;
      flex: 2;
    }

    h3,
    p {
      margin-block: 16px;
    }

    //not last child
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    max-width: 100%;
    // style the checkbox
    input[type="checkbox"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: inline-block;
      position: relative;
      width: 20px;
      height: 20px;
      background: #eee;
      border-radius: 3px;
      cursor: pointer;

      &:checked {
        background: ${(props) => props.theme.colors.purple};
      }
    }
  }
`;

export default RevealAnswerOpen;
