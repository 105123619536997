import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import React from "react";
import styled, { css } from "styled-components";
import { QuestionIcon } from "../atoms/QuestionIcon";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import coin from "../../../../static/media/coin1.svg";
import cross from "../../../../static/media/icon-close.svg";
import { useMainStore } from "../../../../store";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const Modal = styled(motion.div)`
  display: flex;
  z-index: 10;
  width: 100%;
`;

const Text = styled(motion.p)`
  color: ${(props) => props.theme.colors.white};
  font-size: 1.5em;
  margin: 0;
  @media only screen and (max-width: 600px) {
    font-size: 1em;
    whitespace: nowrap;
  }
`;

export const Coin = styled(motion.div)`
  width: 30px;
  height: 30px;
  background-size: contain;
  margin: 0 12px;
  text-align: center;
  background-repeat: no-repeat;
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;
export const Cross = styled(motion.div)`
  width: 12px;
  height: 12px;
  background-size: contain;
  margin: 0 12px 0 0;
  text-align: center;
  background-repeat: no-repeat;
  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

const Topbar = ({
  roomPin,
  begin,
  initial,
  question,
  questionValue,
  exit,
  show,
  layoutId,
  noVideo,
  questionSoort,
  showTotalScore,
}) => {
  const { decision, theme } = useMainStore();
  const { small } = useWindowDimensions();

  return (
    <>
      <Grid container justify="center">
        <Modal>
          <Grid
            layoutId={layoutId}
            component={motion.div}
            container
            direction="row"
            justify="center"
            alignItems="center"
            initial={noVideo && { y: -200 }}
            animate={noVideo && { y: 0 }}
            style={{ background: theme.colors.purpleShadow }}
            transition={{ duration: 0.5 }}
            exit={exit && { y: -200 }}
          >
            <Grid item xs={11} lg={8}>
              <Grid
                container
                spacing={0}
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid
                    style={{ height: "100%" }}
                    justify="center"
                    container
                    direction="row"
                    spacing={0}
                    alignItems="center"
                  >
                    {question && (
                      <Grid item xs={12}>
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          direction="row"
                          spacing={4}
                          style={{ flexWrap: "nowrap" }}
                        >
                          <Grid
                            component={motion.div}
                            initial={initial && { y: -100 }}
                            animate={{ y: 0 }}
                            transition={{ delay: 0.5, ...transitionSpring }}
                            item
                            style={{ width: "70px", position: "relative" }}
                          >
                            <span
                              style={{
                                width: "70px",
                                top: 0,
                                left: 0,
                                position: "relative",
                              }}
                            >
                              <QuestionIcon
                                initial={initial}
                                questionSoort={questionSoort}
                                small={small}
                              />
                            </span>
                          </Grid>
                          <Grid item>
                            <Text
                              component={motion.div}
                              initial={initial && { opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ delay: 0.5, ...transitionSpring }}
                            >
                              <motion.span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "15px",
                                  color: "#fff",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {decision && !show ? (
                                  <strong>{question} </strong>
                                ) : (
                                  <>
                                    <strong>
                                      {!begin ? (
                                        <>
                                          {questionSoort !== "Beslissingsvraag"
                                            ? "Vraag"
                                            : ""}{" "}
                                          {question}{" "}
                                        </>
                                      ) : (
                                        <>{question}</>
                                      )}
                                    </strong>
                                    {questionSoort !== "Beslissingsvraag" ? (
                                      <>{!begin ? "van 10" : ""}</>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </motion.span>
                            </Text>
                          </Grid>
                          {roomPin && (
                            <Grid item>
                              <Text
                                component={motion.div}
                                initial={initial && { opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.5, ...transitionSpring }}
                              >
                                <motion.span
                                  style={{
                                    display: "inline-block",
                                    margin: "0",
                                    padding: "0",
                                    color: "#fff",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <span>
                                    Room: <strong>{roomPin}</strong>
                                  </span>
                                </motion.span>
                              </Text>
                            </Grid>
                          )}
                          {showTotalScore && (
                            <Grid item>
                              <Text
                                component={motion.div}
                                initial={initial && { opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.5, ...transitionSpring }}
                              >
                                <motion.h1
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{
                                    opacity: 0,
                                    transition: { delay: 0 },
                                  }}
                                  transition={{ delay: 6 }}
                                  style={{
                                    position: "absolute",
                                    left: "50%",
                                    top: "4px",
                                    transform: "translateX(-50%)",
                                    fontSize: "1em",
                                    fontFamily: "Brevia",
                                    color: theme.colors.white,
                                  }}
                                >
                                  <span>Tussenstand</span>
                                </motion.h1>
                              </Text>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {questionValue && (
                  <Grid
                    component={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.5 }}
                    item
                  >
                    <Grid
                      justify="center"
                      style={{ textAlign: "right", height: "100%" }}
                      container
                      direction="column"
                      spacing={0}
                    >
                      <Grid item>
                        <Grid
                          justify="flex-start"
                          alignItems="center"
                          style={{ textAlign: "right", height: "100%" }}
                          container
                          direction="row"
                          spacing={0}
                        >
                          {!small && <Text>te verdienen</Text>}
                          <Coin image={coin}>€</Coin>
                          <Cross image={cross}></Cross>
                          <Text
                            style={{
                              textShadow: "0 1px 1px rgba(0, 0, 0, 0.5)",
                            }}
                          >
                            <strong>{questionValue}</strong>
                          </Text>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </>
  );
};

export default Topbar;
