import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import React from "react";
import styled, { css } from "styled-components";
import { useMainStore } from "../../../../store";
import { useSocketStore } from "../../../../store/socketStore";
import { Button } from "./Buttons";
import { transitionSlowSpring } from "./ScoreBar";
import { TextLink } from "./TextLink";

const Score = styled(motion.div)`
  width: 100%;
  height: auto;
  padding: 1em 3em;
  background: ${(props) =>
    !props.background
      ? props.theme.colors.purple
      : props.theme.colors.purpleShadow};
  border-radius: 7px;

  ${(props) =>
    props.first &&
    css`
      padding: 2em 3em;
      background-color: ${props.theme.colors.white};
      margin-bottom: 10px;
    `}
`;

const Header = styled(motion.h1)`
  font-size: 1.7em;
  margin: 0;

  ${(props) =>
    props.first &&
    css`
      font-size: 1.7em;
      color: ${(props) => props.theme.colors.background}; ;
    `}
`;

const Text = styled(motion.p)`
  font-size: 1.5em;
  color: #fff;
  margin: 0;
  font-weight: 400;

  ${(props) =>
    props.first &&
    css`
      color: ${(props) => props.theme.colors.background}; ;
    `}
`;

const Ranking = ({ scores, setRanking }) => {
  const { setPhase } = useMainStore();
  const socketStore = useSocketStore();

  return (
    <Grid container alignItems="center" direction="column">
      {scores.map((score, index) => (
        <Score
          key={score.group}
          initial={{ y: 60, opacity: 0 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ...transitionSlowSpring, delay: index * 0.1 + 0.5 }}
          exit={{ y: -60, opacity: 0, transition: { delay: index * 0.1 } }}
          background={index % 2 === 0}
          first={index === 0}
        >
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Header first={index === 0}>
                <span
                  style={{
                    marginRight: 100,
                  }}
                >
                  {" "}
                  {index + 1}
                </span>
                Team {score.group}
              </Header>
            </Grid>
            <Grid item>
              <Text first={index === 0}>€{score.total}</Text>
            </Grid>
          </Grid>
        </Score>
      ))}
      <Button
        animate={{ opacity: 1, y: 0 }}
        initial={{ y: 0, opacity: 0 }}
        transition={{ ...transitionSlowSpring, delay: 0.05 }}
        primary
        onClick={() => {
          setRanking(false);
        }}
        exit={{ opacity: 0 }}
        whileHover={{
          scale: 1.1,
          delay: 0,
        }}
      >
        Terug
      </Button>
      <Grid
        style={{
          position: "absolute",
          bottom: "30px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
        container
        direction="row"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            primary
            onClick={() => {
              setPhase("final");
              socketStore.setPhase("final");
            }}
            exit={{ opacity: 0 }}
            whileHover={{
              scale: 1.1,
              delay: 0,
            }}
          >
            Verder
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Ranking;
