import React, { useEffect, useState } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import { NavigationNext } from "../../atoms/Buttons";
import { LetterBox, numberToLetter } from "../../atoms/LetterBox";
import Topbar from "../../molecules/Topbar";
import { PanelQuestion } from "../../molecules/PanelQuestion";
import { AnswersHolder } from "../../atoms/AnswersHolder";
import { transitionSpring } from "./SelectLesson";
import { checkCorrect } from "../../../../../hooks/helperfunctions";
import Counter from "../../atoms/Counter";
import { useSocketStore } from "../../../../../store/socketStore";
import RevealAnswerOpen from "../../molecules/RevealAnswerOpen";

const questionVariants = {
  full: {
    width: "100vw",
    x: "100%",
  },
  slideleft: {
    transition: { duration: 0.8 },
    x: "0%",
  },
  hide: {
    opacity: 0,
  },
  exit: {
    x: "-100%",
    transition: { delay: 1.5, duration: 0.8 },
  },
};

const RevealAnswer = ({ socket }) => {
  const { setPhase, questions, round, roomPin, playersAnswered, theme } =
    useSocketStore();
  const [explanationPhase, setExplanationPhase] = useState(false);
  const [resultsSend, setResultsSend] = useState(
    questions[round].type === "open" ? false : true
  );
  let allGood;
  if (questions[round].type === "meerkeuze") {
    allGood =
      JSON.parse(questions[round].answer).length ===
      JSON.parse(questions[round].answers).length;
  } else {
    allGood = false;
  }
  useEffect(() => {
    socket.emit("start checking server", { roomPin, playersAnswered });
  }, [roomPin, playersAnswered, socket]);

  useEffect(() => {
    if (questions[round].explanation) {
      setTimeout(() => {
        setExplanationPhase(true);
      }, 5000);
    }
  }, [questions, round, setExplanationPhase]);

  return (
    <>
      <Topbar
        roomPin={roomPin}
        pageTitle={questions[round].soort}
        question={round + 1}
        questionSoort={questions[round].soort}
      />
      {questions[round].type === "open" ? (
        <RevealAnswerOpen
          resultsSend={resultsSend}
          socket={socket}
          roomPin={roomPin}
          setResultsSend={setResultsSend}
          playersAnswered={playersAnswered}
        />
      ) : (
        <PanelQuestion
          transition={{ delay: 5 }}
          variants={questionVariants}
          initial="full"
          animate="slideleft"
          exit="exit"
          alignItems="center"
          style={{ backgroundColor: theme.colors.purple, marginTop: "60px" }}
        >
          <Grid
            container
            style={{
              height: "100%",
              paddingBottom: "110px",
              alignItems: "center",
            }}
            spacing={4}
            alignItems={questions[round].explanation ? "flex-start" : "center"}
            justify="center"
          >
            <Grid
              component={motion.div}
              layout
              item
              xs={questions[round].question_layout === "explanationbig" ? 6 : 7}
              lg={questions[round].question_layout === "explanationbig" ? 6 : 5}
            >
              <motion.h1
                dangerouslySetInnerHTML={{ __html: questions[round].name }}
                style={{
                  fontSize: "2rem",
                  lineHeight: "1em",
                  marginBottom: "30px",
                  textShadow: "0 1px 1px rgba(0,0,0,0.4)",
                  color: theme.colors.white,
                }}
                transition={{ ...transitionSpring, delay: 1.2 }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, transition: { delay: 0 } }}
              />
              {allGood && (
                <motion.h3
                  transition={{ ...transitionSpring, delay: 1.2 }}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, transition: { delay: 0 } }}
                  style={{
                    fontSize: "1.4em",
                    fontWeight: "normal",
                    opacity: 1,
                    y: -2,
                    color: "#fff",
                  }}
                >
                  Alle antwoorden zijn goed!
                </motion.h3>
              )}
              {questions[round].type === "schatting" && (
                <>
                  <Counter
                    unit={questions[round].unit}
                    unitPrepend={questions[round].unit_prepend}
                    from={0}
                    to={parseInt(questions[round].answer)}
                  />
                </>
              )}
              <AnswersHolder
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { delay: 0 } }}
                transition={{ ...transitionSpring, delay: 1 }}
              >
                {questions[round].answers !== "" &&
                  JSON.parse(questions[round].answers).map((answer, index) => {
                    const correct = checkCorrect(
                      answer,
                      index,
                      questions[round].answer,
                      questions[round].type
                    );
                    return (
                      answer && (
                        <Grid
                          component={motion.div}
                          animate={{
                            backgroundColor: correct ? "#DCF7C5" : "#fff",
                          }}
                          transition={{ delay: 2.5, duration: 0.2 }}
                          container
                          alignItems="center"
                        >
                          <Grid item xs={1}>
                            {questions[round].type !== "waarnietwaar" ? (
                              <LetterBox
                                color={theme.colors.green}
                                boxShadowColor={theme.colors.greenShadow}
                                animate={{
                                  color: correct
                                    ? theme.colors.white
                                    : "#c0c7ce",
                                  backgroundColor: correct
                                    ? theme.colors.purple
                                    : "#e9f0f6",
                                  boxShadow: correct
                                    ? `0 3px 0 ${theme.colors.yellowShadow} `
                                    : "0 3px 0 #c0c7ce",
                                }}
                                transition={{ delay: 2.5, duration: 0.2 }}
                                custom={index}
                                key={index}
                              >
                                {numberToLetter(index)}
                              </LetterBox>
                            ) : (
                              <LetterBox
                                color={theme.colors.green}
                                boxShadowColor={theme.colors.greenShadow}
                                initial={{ opacity: 0 }}
                                animate={{
                                  opacity: 1,
                                  color: correct
                                    ? theme.colors.white
                                    : "#c0c7ce",
                                  backgroundColor: correct
                                    ? theme.colors.purple
                                    : "#e9f0f6",
                                  boxShadow: correct
                                    ? `0 3px 0 ${theme.colors.yellowShadow} `
                                    : "0 3px 0 #c0c7ce",
                                }}
                                correct={correct ? true : false}
                                revealAnswer
                                transition={{ delay: 2.5, duration: 0.2 }}
                                custom={index}
                                key={index}
                              >
                                {numberToLetter(answer)}
                              </LetterBox>
                            )}
                          </Grid>
                          <Grid item xs={11}>
                            <motion.h3
                              style={{
                                fontSize: "1.4em",
                                fontWeight: "normal",
                                opacity: 1,
                                y: -2,
                              }}
                              animate={{
                                color: correct ? theme.colors.green : "#c0c7ce",
                              }}
                              transition={{ delay: 2.5, duration: 0.2 }}
                              custom={index}
                              key={index}
                            >
                              {answer}
                            </motion.h3>
                          </Grid>
                        </Grid>
                      )
                    );
                  })}
              </AnswersHolder>
            </Grid>

            {explanationPhase && (
              <Grid
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
                exit={{ opacity: 0, transition: { delay: 0 } }}
                component={motion.div}
                style={{
                  width: "100%",
                  border: "1px solid transparent",
                  padding: "0 15px",
                  background: theme.colors.white,
                  borderRadius: "0 10px 10px 10px",
                  marginTop: "35px",
                  boxShadow: "inset 0px -2px 0px -1px rgba(255,255,255,0.075)",
                }}
                item
                xs={
                  questions[round].question_layout === "explanationbig" ? 5 : 4
                }
                lg={
                  questions[round].question_layout === "explanationbig" ? 5 : 3
                }
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: questions[round].explanation,
                  }}
                  style={{
                    fontSize: "1.4rem",
                    lineHeight: "1.6em",
                    marginBottom: "30px",
                    color: theme.colors.purple,
                  }}
                />
              </Grid>
            )}
          </Grid>
        </PanelQuestion>
      )}
      {resultsSend && (
        <NavigationNext
          // transition={{ ...transitionSpring }}
          initial={{ opacity: 0, display: "none" }}
          animate={{ opacity: 1, display: "block" }}
          whileHover={{
            scale: 1.1,
            cursor: "pointer",
          }}
          transition={{
            scale: { delay: 0 },
            opacity: {
              delay:
                questions[round].type === "open" ? 0 : explanationPhase ? 7 : 5,
            },
            default: 2.5,
          }}
          text="Bekijk scores"
          bottom={30}
          right={50}
          nextPhase={() => {
            setPhase("scores");
          }}
        />
      )}
    </>
  );
};

export default RevealAnswer;
