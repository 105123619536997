export const testQuestions = [
  {
    catid: 9,
    id: 1,
    name:
      "Hanane koopt een nieuw schrift voor <span class='no-wrap'>€ 1,17</span>. Naar welk bedrag wordt <span class='no-wrap'>€ 1,17</span> afgerond?",
    alias:
      "hanane-koopt-een-nieuw-schrift-voor-1-17-naar-welk-bedrag-wordt-1-17-afgerond",
    noname: 1,
    intro:
      "<p>In Nederland betalen we met de euro. Er zijn <span class='no-wrap'>7 biljetten</span> en <span class='no-wrap'>8 munten</span>. Munten van 1 en 2 cent worden in Nederland bijna niet meer gebruikt. Een bedrag wordt afgerond naar 0 of <span class='no-wrap'>5 cent</span>.</p>",
    subtitle: "nvt",
    video: 1,
    videourl: "O2uSQHMcyi4",
    soort: "Verdiepingsvraag",
    type: "schatting",
    possible_answers: "32.13",
    answers:
      '["\u20ac 1,10","\u20ac 1,15","\u20ac 1,20","\u20ac 1,10","\u20ac 1,10"]',
    answer: "2",
    explanation:
      "Eindigt een bedrag op 1, 2, 8 of 9? Dan wordt het bedrag afgerond naar 0. Dit omdat 1, 2, 9 en 8 het dichtst bij (1)0 liggen. Eindigt een bedrag op 3, 4, 6 of 7? Dan wordt het bedrag afgerond naar 5. Dit omdat 3, 4, 6 en 7 het dichtst bij 5 liggen. Afronden gebeurt alleen als je contant betaalt. Als je betaalt met een pinpas, dan wordt het exacte bedrag van je rekening afgeschreven. De munten van 1 en 2 eurocent bestaan nog wel: ze zijn een wettig betaalmiddel. In veel andere Europese landen worden deze munten wel gebruikt.",
    time: 60,
    points: "10",
    points2: 0,
    published: 1,
    checked_out: 0,
    checked_out_time: "0000-00-00 00:00:00",
    ordering: 1,
    access: 1,
    language: "*",
    params: "",
    created: "2019-10-28T13:56:13.000Z",
    created_by: 686,
    created_by_alias: "",
    modified: "2020-02-14T08:48:50.000Z",
    modified_by: 689,
    metakey: "",
    metadesc: "",
    xreference: "",
    publish_up: "0000-00-00 00:00:00",
    publish_down: "0000-00-00 00:00:00",
    version: 16,
  },
  {
    catid: 9,
    id: 2,
    name:
      "Hanane koopt een nieuw schrift voor <span class='no-wrap'>€ 1,17</span>. Naar welk bedrag wordt <span class='no-wrap'>€ 1,17</span> afgerond?",
    alias:
      "hanane-koopt-een-nieuw-schrift-voor-1-17-naar-welk-bedrag-wordt-1-17-afgerond",
    noname: 1,
    intro:
      "<p>In Nederland betalen we met de euro. Er zijn <span class='no-wrap'>7 biljetten</span> en <span class='no-wrap'>8 munten</span>. Munten van 1 en 2 cent worden in Nederland bijna niet meer gebruikt. Een bedrag wordt afgerond naar 0 of <span class='no-wrap'>5 cent</span>.</p>",
    subtitle: "nvt",
    video: 1,
    videourl: "O2uSQHMcyi4",
    soort: "Verdiepingsvraag",
    type: "meerkeuzevraag",
    possible_answers: "",
    answers: '["\u20ac 1,10","\u20ac 1,15","\u20ac 1,20","",""]',
    answer: "2",
    explanation:
      "Eindigt een bedrag op 1, 2, 8 of 9? Dan wordt het bedrag afgerond naar 0. Dit omdat 1, 2, 9 en 8 het dichtst bij (1)0 liggen. Eindigt een bedrag op 3, 4, 6 of 7? Dan wordt het bedrag afgerond naar 5. Dit omdat 3, 4, 6 en 7 het dichtst bij 5 liggen. Afronden gebeurt alleen als je contant betaalt. Als je betaalt met een pinpas, dan wordt het exacte bedrag van je rekening afgeschreven. De munten van 1 en 2 eurocent bestaan nog wel: ze zijn een wettig betaalmiddel. In veel andere Europese landen worden deze munten wel gebruikt.",
    time: 60,
    points: "10",
    points2: 0,
    published: 1,
    checked_out: 0,
    checked_out_time: "0000-00-00 00:00:00",
    ordering: 2,
    access: 1,
    language: "*",
    params: "",
    created: "2019-10-28T13:56:13.000Z",
    created_by: 686,
    created_by_alias: "",
    modified: "2020-02-14T08:48:50.000Z",
    modified_by: 689,
    metakey: "",
    metadesc: "",
    xreference: "",
    publish_up: "0000-00-00 00:00:00",
    publish_down: "0000-00-00 00:00:00",
    version: 16,
  },
  {
    catid: 9,
    id: 3,
    name:
      "Hanane koopt een nieuw schrift voor <span class='no-wrap'>€ 1,17</span>. Naar welk bedrag wordt <span class='no-wrap'>€ 1,17</span> afgerond?",
    alias:
      "hanane-koopt-een-nieuw-schrift-voor-1-17-naar-welk-bedrag-wordt-1-17-afgerond",
    noname: 1,
    intro:
      "<p>In Nederland betalen we met de euro. Er zijn <span class='no-wrap'>7 biljetten</span> en <span class='no-wrap'>8 munten</span>. Munten van 1 en 2 cent worden in Nederland bijna niet meer gebruikt. Een bedrag wordt afgerond naar 0 of <span class='no-wrap'>5 cent</span>.</p>",
    subtitle: "nvt",
    video: 1,
    videourl: "O2uSQHMcyi4",
    soort: "Verdiepingsvraag",
    type: "meerkeuzevraag",
    possible_answers: "",
    answers: '["\u20ac 1,10","\u20ac 1,15","\u20ac 1,20","",""]',
    answer: "2",
    explanation:
      "Eindigt een bedrag op 1, 2, 8 of 9? Dan wordt het bedrag afgerond naar 0. Dit omdat 1, 2, 9 en 8 het dichtst bij (1)0 liggen. Eindigt een bedrag op 3, 4, 6 of 7? Dan wordt het bedrag afgerond naar 5. Dit omdat 3, 4, 6 en 7 het dichtst bij 5 liggen. Afronden gebeurt alleen als je contant betaalt. Als je betaalt met een pinpas, dan wordt het exacte bedrag van je rekening afgeschreven. De munten van 1 en 2 eurocent bestaan nog wel: ze zijn een wettig betaalmiddel. In veel andere Europese landen worden deze munten wel gebruikt.",
    time: 60,
    points: "10",
    points2: 0,
    published: 1,
    checked_out: 0,
    checked_out_time: "0000-00-00 00:00:00",
    ordering: 3,
    access: 1,
    language: "*",
    params: "",
    created: "2019-10-28T13:56:13.000Z",
    created_by: 686,
    created_by_alias: "",
    modified: "2020-02-14T08:48:50.000Z",
    modified_by: 689,
    metakey: "",
    metadesc: "",
    xreference: "",
    publish_up: "0000-00-00 00:00:00",
    publish_down: "0000-00-00 00:00:00",
    version: 16,
  },
  {
    catid: 9,
    id: 4,
    name:
      "Hanane koopt een nieuw schrift voor <span class='no-wrap'>€ 1,17</span>. Naar welk bedrag wordt <span class='no-wrap'>€ 1,17</span> afgerond?",
    alias:
      "hanane-koopt-een-nieuw-schrift-voor-1-17-naar-welk-bedrag-wordt-1-17-afgerond",
    noname: 1,
    intro:
      "<p>In Nederland betalen we met de euro. Er zijn <span class='no-wrap'>7 biljetten</span> en <span class='no-wrap'>8 munten</span>. Munten van 1 en 2 cent worden in Nederland bijna niet meer gebruikt. Een bedrag wordt afgerond naar 0 of <span class='no-wrap'>5 cent</span>.</p>",
    subtitle: "nvt",
    video: 1,
    videourl: "O2uSQHMcyi4",
    soort: "Verdiepingsvraag",
    type: "meerkeuzevraag",
    possible_answers: "",
    answers: '["\u20ac 1,10","\u20ac 1,15","\u20ac 1,20","",""]',
    answer: "2",
    explanation:
      "Eindigt een bedrag op 1, 2, 8 of 9? Dan wordt het bedrag afgerond naar 0. Dit omdat 1, 2, 9 en 8 het dichtst bij (1)0 liggen. Eindigt een bedrag op 3, 4, 6 of 7? Dan wordt het bedrag afgerond naar 5. Dit omdat 3, 4, 6 en 7 het dichtst bij 5 liggen. Afronden gebeurt alleen als je contant betaalt. Als je betaalt met een pinpas, dan wordt het exacte bedrag van je rekening afgeschreven. De munten van 1 en 2 eurocent bestaan nog wel: ze zijn een wettig betaalmiddel. In veel andere Europese landen worden deze munten wel gebruikt.",
    time: 60,
    points: "10",
    points2: 0,
    published: 1,
    checked_out: 0,
    checked_out_time: "0000-00-00 00:00:00",
    ordering: 4,
    access: 1,
    language: "*",
    params: "",
    created: "2019-10-28T13:56:13.000Z",
    created_by: 686,
    created_by_alias: "",
    modified: "2020-02-14T08:48:50.000Z",
    modified_by: 689,
    metakey: "",
    metadesc: "",
    xreference: "",
    publish_up: "0000-00-00 00:00:00",
    publish_down: "0000-00-00 00:00:00",
    version: 16,
  },
  {
    catid: 9,
    id: 5,
    name:
      "Hanane koopt een nieuw schrift voor <span class='no-wrap'>€ 1,17</span>. Naar welk bedrag wordt <span class='no-wrap'>€ 1,17</span> afgerond?",
    alias:
      "hanane-koopt-een-nieuw-schrift-voor-1-17-naar-welk-bedrag-wordt-1-17-afgerond",
    noname: 1,
    intro:
      "<p>In Nederland betalen we met de euro. Er zijn <span class='no-wrap'>7 biljetten</span> en <span class='no-wrap'>8 munten</span>. Munten van 1 en 2 cent worden in Nederland bijna niet meer gebruikt. Een bedrag wordt afgerond naar 0 of <span class='no-wrap'>5 cent</span>.</p>",
    subtitle: "nvt",
    video: 1,
    videourl: "O2uSQHMcyi4",
    soort: "Verdiepingsvraag",
    type: "meerkeuzevraag",
    possible_answers: "",
    answers: '["\u20ac 1,10","\u20ac 1,15","\u20ac 1,20","",""]',
    answer: "2",
    explanation:
      "Eindigt een bedrag op 1, 2, 8 of 9? Dan wordt het bedrag afgerond naar 0. Dit omdat 1, 2, 9 en 8 het dichtst bij (1)0 liggen. Eindigt een bedrag op 3, 4, 6 of 7? Dan wordt het bedrag afgerond naar 5. Dit omdat 3, 4, 6 en 7 het dichtst bij 5 liggen. Afronden gebeurt alleen als je contant betaalt. Als je betaalt met een pinpas, dan wordt het exacte bedrag van je rekening afgeschreven. De munten van 1 en 2 eurocent bestaan nog wel: ze zijn een wettig betaalmiddel. In veel andere Europese landen worden deze munten wel gebruikt.",
    time: 60,
    points: "10",
    points2: 0,
    published: 1,
    checked_out: 0,
    checked_out_time: "0000-00-00 00:00:00",
    ordering: 5,
    access: 1,
    language: "*",
    params: "",
    created: "2019-10-28T13:56:13.000Z",
    created_by: 686,
    created_by_alias: "",
    modified: "2020-02-14T08:48:50.000Z",
    modified_by: 689,
    metakey: "",
    metadesc: "",
    xreference: "",
    publish_up: "0000-00-00 00:00:00",
    publish_down: "0000-00-00 00:00:00",
    version: 16,
  },
  {
    catid: 9,
    id: 6,
    name:
      "Hanane koopt een nieuw schrift voor <span class='no-wrap'>€ 1,17</span>. Naar welk bedrag wordt <span class='no-wrap'>€ 1,17</span> afgerond?",
    alias:
      "hanane-koopt-een-nieuw-schrift-voor-1-17-naar-welk-bedrag-wordt-1-17-afgerond",
    noname: 1,
    intro:
      "<p>In Nederland betalen we met de euro. Er zijn <span class='no-wrap'>7 biljetten</span> en <span class='no-wrap'>8 munten</span>. Munten van 1 en 2 cent worden in Nederland bijna niet meer gebruikt. Een bedrag wordt afgerond naar 0 of <span class='no-wrap'>5 cent</span>.</p>",
    subtitle: "nvt",
    video: 1,
    videourl: "O2uSQHMcyi4",
    soort: "Verdiepingsvraag",
    type: "meerkeuzevraag",
    possible_answers: "",
    answers: '["\u20ac 1,10","\u20ac 1,15","\u20ac 1,20","",""]',
    answer: "2",
    explanation:
      "Eindigt een bedrag op 1, 2, 8 of 9? Dan wordt het bedrag afgerond naar 0. Dit omdat 1, 2, 9 en 8 het dichtst bij (1)0 liggen. Eindigt een bedrag op 3, 4, 6 of 7? Dan wordt het bedrag afgerond naar 5. Dit omdat 3, 4, 6 en 7 het dichtst bij 5 liggen. Afronden gebeurt alleen als je contant betaalt. Als je betaalt met een pinpas, dan wordt het exacte bedrag van je rekening afgeschreven. De munten van 1 en 2 eurocent bestaan nog wel: ze zijn een wettig betaalmiddel. In veel andere Europese landen worden deze munten wel gebruikt.",
    time: 60,
    points: "10",
    points2: 0,
    published: 1,
    checked_out: 0,
    checked_out_time: "0000-00-00 00:00:00",
    ordering: 6,
    access: 1,
    language: "*",
    params: "",
    created: "2019-10-28T13:56:13.000Z",
    created_by: 686,
    created_by_alias: "",
    modified: "2020-02-14T08:48:50.000Z",
    modified_by: 689,
    metakey: "",
    metadesc: "",
    xreference: "",
    publish_up: "0000-00-00 00:00:00",
    publish_down: "0000-00-00 00:00:00",
    version: 16,
  },
  {
    catid: 9,
    id: 6,
    name:
      "Hanane koopt een nieuw schrift voor <span class='no-wrap'>€ 1,17</span>. Naar welk bedrag wordt <span class='no-wrap'>€ 1,17</span> afgerond?",
    alias:
      "hanane-koopt-een-nieuw-schrift-voor-1-17-naar-welk-bedrag-wordt-1-17-afgerond",
    noname: 1,
    intro:
      "<p>In Nederland betalen we met de euro. Er zijn <span class='no-wrap'>7 biljetten</span> en <span class='no-wrap'>8 munten</span>. Munten van 1 en 2 cent worden in Nederland bijna niet meer gebruikt. Een bedrag wordt afgerond naar 0 of <span class='no-wrap'>5 cent</span>.</p>",
    subtitle: "nvt",
    video: 1,
    videourl: "O2uSQHMcyi4",
    soort: "Verdiepingsvraag",
    type: "meerkeuzevraag",
    possible_answers: "",
    answers: '["\u20ac 1,10","\u20ac 1,15","\u20ac 1,20","",""]',
    answer: "2",
    explanation:
      "Eindigt een bedrag op 1, 2, 8 of 9? Dan wordt het bedrag afgerond naar 0. Dit omdat 1, 2, 9 en 8 het dichtst bij (1)0 liggen. Eindigt een bedrag op 3, 4, 6 of 7? Dan wordt het bedrag afgerond naar 5. Dit omdat 3, 4, 6 en 7 het dichtst bij 5 liggen. Afronden gebeurt alleen als je contant betaalt. Als je betaalt met een pinpas, dan wordt het exacte bedrag van je rekening afgeschreven. De munten van 1 en 2 eurocent bestaan nog wel: ze zijn een wettig betaalmiddel. In veel andere Europese landen worden deze munten wel gebruikt.",
    time: 60,
    points: "10",
    points2: 0,
    published: 1,
    checked_out: 0,
    checked_out_time: "0000-00-00 00:00:00",
    ordering: 7,
    access: 1,
    language: "*",
    params: "",
    created: "2019-10-28T13:56:13.000Z",
    created_by: 686,
    created_by_alias: "",
    modified: "2020-02-14T08:48:50.000Z",
    modified_by: 689,
    metakey: "",
    metadesc: "",
    xreference: "",
    publish_up: "0000-00-00 00:00:00",
    publish_down: "0000-00-00 00:00:00",
    version: 16,
  },
  {
    catid: 9,
    id: 6,
    name:
      "Hanane koopt een nieuw schrift voor <span class='no-wrap'>€ 1,17</span>. Naar welk bedrag wordt <span class='no-wrap'>€ 1,17</span> afgerond?",
    alias:
      "hanane-koopt-een-nieuw-schrift-voor-1-17-naar-welk-bedrag-wordt-1-17-afgerond",
    noname: 1,
    intro:
      "<p>In Nederland betalen we met de euro. Er zijn <span class='no-wrap'>7 biljetten</span> en <span class='no-wrap'>8 munten</span>. Munten van 1 en 2 cent worden in Nederland bijna niet meer gebruikt. Een bedrag wordt afgerond naar 0 of <span class='no-wrap'>5 cent</span>.</p>",
    subtitle: "nvt",
    video: 1,
    videourl: "O2uSQHMcyi4",
    soort: "Verdiepingsvraag",
    type: "meerkeuzevraag",
    possible_answers: "",
    answers: '["\u20ac 1,10","\u20ac 1,15","\u20ac 1,20","",""]',
    answer: "2",
    explanation:
      "Eindigt een bedrag op 1, 2, 8 of 9? Dan wordt het bedrag afgerond naar 0. Dit omdat 1, 2, 9 en 8 het dichtst bij (1)0 liggen. Eindigt een bedrag op 3, 4, 6 of 7? Dan wordt het bedrag afgerond naar 5. Dit omdat 3, 4, 6 en 7 het dichtst bij 5 liggen. Afronden gebeurt alleen als je contant betaalt. Als je betaalt met een pinpas, dan wordt het exacte bedrag van je rekening afgeschreven. De munten van 1 en 2 eurocent bestaan nog wel: ze zijn een wettig betaalmiddel. In veel andere Europese landen worden deze munten wel gebruikt.",
    time: 60,
    points: "10",
    points2: 0,
    published: 1,
    checked_out: 0,
    checked_out_time: "0000-00-00 00:00:00",
    ordering: 8,
    access: 1,
    language: "*",
    params: "",
    created: "2019-10-28T13:56:13.000Z",
    created_by: 686,
    created_by_alias: "",
    modified: "2020-02-14T08:48:50.000Z",
    modified_by: 689,
    metakey: "",
    metadesc: "",
    xreference: "",
    publish_up: "0000-00-00 00:00:00",
    publish_down: "0000-00-00 00:00:00",
    version: 16,
  },
  {
    catid: 9,
    id: 6,
    name:
      "Hanane koopt een nieuw schrift voor <span class='no-wrap'>€ 1,17</span>. Naar welk bedrag wordt <span class='no-wrap'>€ 1,17</span> afgerond?",
    alias:
      "hanane-koopt-een-nieuw-schrift-voor-1-17-naar-welk-bedrag-wordt-1-17-afgerond",
    noname: 1,
    intro:
      "<p>In Nederland betalen we met de euro. Er zijn <span class='no-wrap'>7 biljetten</span> en <span class='no-wrap'>8 munten</span>. Munten van 1 en 2 cent worden in Nederland bijna niet meer gebruikt. Een bedrag wordt afgerond naar 0 of <span class='no-wrap'>5 cent</span>.</p>",
    subtitle: "nvt",
    video: 1,
    videourl: "O2uSQHMcyi4",
    soort: "Verdiepingsvraag",
    type: "meerkeuzevraag",
    possible_answers: "",
    answers: '["\u20ac 1,10","\u20ac 1,15","\u20ac 1,20","",""]',
    answer: "2",
    explanation:
      "Eindigt een bedrag op 1, 2, 8 of 9? Dan wordt het bedrag afgerond naar 0. Dit omdat 1, 2, 9 en 8 het dichtst bij (1)0 liggen. Eindigt een bedrag op 3, 4, 6 of 7? Dan wordt het bedrag afgerond naar 5. Dit omdat 3, 4, 6 en 7 het dichtst bij 5 liggen. Afronden gebeurt alleen als je contant betaalt. Als je betaalt met een pinpas, dan wordt het exacte bedrag van je rekening afgeschreven. De munten van 1 en 2 eurocent bestaan nog wel: ze zijn een wettig betaalmiddel. In veel andere Europese landen worden deze munten wel gebruikt.",
    time: 60,
    points: "10",
    points2: 0,
    published: 1,
    checked_out: 0,
    checked_out_time: "0000-00-00 00:00:00",
    ordering: 9,
    access: 1,
    language: "*",
    params: "",
    created: "2019-10-28T13:56:13.000Z",
    created_by: 686,
    created_by_alias: "",
    modified: "2020-02-14T08:48:50.000Z",
    modified_by: 689,
    metakey: "",
    metadesc: "",
    xreference: "",
    publish_up: "0000-00-00 00:00:00",
    publish_down: "0000-00-00 00:00:00",
    version: 16,
  },
  {
    catid: 9,
    id: 6,
    name:
      "Hanane koopt een nieuw schrift voor <span class='no-wrap'>€ 1,17</span>. Naar welk bedrag wordt <span class='no-wrap'>€ 1,17</span> afgerond?",
    alias:
      "hanane-koopt-een-nieuw-schrift-voor-1-17-naar-welk-bedrag-wordt-1-17-afgerond",
    noname: 1,
    intro:
      "<p>In Nederland betalen we met de euro. Er zijn <span class='no-wrap'>7 biljetten</span> en <span class='no-wrap'>8 munten</span>. Munten van 1 en 2 cent worden in Nederland bijna niet meer gebruikt. Een bedrag wordt afgerond naar 0 of <span class='no-wrap'>5 cent</span>.</p>",
    subtitle: "nvt",
    video: 1,
    videourl: "O2uSQHMcyi4",
    soort: "Verdiepingsvraag",
    type: "meerkeuzevraag",
    possible_answers: "",
    answers: '["\u20ac 1,10","\u20ac 1,15","\u20ac 1,20","",""]',
    answer: "2",
    explanation:
      "Eindigt een bedrag op 1, 2, 8 of 9? Dan wordt het bedrag afgerond naar 0. Dit omdat 1, 2, 9 en 8 het dichtst bij (1)0 liggen. Eindigt een bedrag op 3, 4, 6 of 7? Dan wordt het bedrag afgerond naar 5. Dit omdat 3, 4, 6 en 7 het dichtst bij 5 liggen. Afronden gebeurt alleen als je contant betaalt. Als je betaalt met een pinpas, dan wordt het exacte bedrag van je rekening afgeschreven. De munten van 1 en 2 eurocent bestaan nog wel: ze zijn een wettig betaalmiddel. In veel andere Europese landen worden deze munten wel gebruikt.",
    time: 60,
    points: "10",
    points2: 0,
    published: 1,
    checked_out: 0,
    checked_out_time: "0000-00-00 00:00:00",
    ordering: 10,
    access: 1,
    language: "*",
    params: "",
    created: "2019-10-28T13:56:13.000Z",
    created_by: 686,
    created_by_alias: "",
    modified: "2020-02-14T08:48:50.000Z",
    modified_by: 689,
    metakey: "",
    metadesc: "",
    xreference: "",
    publish_up: "0000-00-00 00:00:00",
    publish_down: "0000-00-00 00:00:00",
    version: 16,
  },
];
