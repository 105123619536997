import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import CashQuizLogo from "../../atoms/CashQuizLogo";
import BackgroundMobile from "../../atoms/BackgroundMobile";
import { usePlayerStore } from "../../../../../store/playerStore";
import { Button } from "../../atoms/Buttons";
import { checkAnswerSocket } from "../../../../../hooks/helperfunctions";
import AnswerEstimationDecision from "../../molecules/answerssocket/AnswerEstimationDecision";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";

const GameDecision = ({ socket }) => {
  const {
    level,
    decision,
    round,
    user,
    roomPin,
    startChecking,
    setStartChecking,
    playersAnswered,
    stopAnswering,
    theme,
  } = usePlayerStore();
  let [chosenAnswer, setChosenAnswer] = useState([]);
  const [answered, setAnswered] = useState(false);
  const [confetti, setConfetti] = useState(undefined);
  const { height } = useWindowDimensions();

  useEffect(() => {
    setChosenAnswer();
    setAnswered(false);
    setConfetti(undefined);
  }, [setChosenAnswer, setAnswered, setConfetti]);

  const finalQuestion = JSON.parse(level.params);

  useEffect(() => {
    if (startChecking) {
      if (
        checkAnswerSocket(
          "schatting",
          chosenAnswer,
          { answer: finalQuestion.final_answer },
          playersAnswered,
          user
        )
      ) {
        setConfetti(true);
        socket.emit("correct answer", {
          decision,
          roomPin,
          user,
          answer: chosenAnswer,
          correctMultiple: false,
        });
      } else if (
        !checkAnswerSocket(
          "schatting",
          chosenAnswer,
          { answer: finalQuestion.final_answer },
          playersAnswered,
          user
        )
      ) {
        setConfetti(false);
        socket.emit("wrong answer", {
          decision,
          roomPin,
          user,
          answer: chosenAnswer,
        });
      } else {
        setConfetti(false);
        socket.emit("wrong answer", {
          decision,
          roomPin,
          user,
          answer: chosenAnswer,
        });
        setAnswered(true);
      }
      setStartChecking(false);
    }
  }, [
    startChecking,
    playersAnswered,
    roomPin,
    setStartChecking,
    chosenAnswer,
    decision,
    finalQuestion.final_answer,
    socket,
    user,
  ]);

  return (
    <>
      <motion.div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          style={{
            backgroundColor: theme.colors.purpleShadow,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Grid container justify="center">
            <Grid item xs={11} md={6} lg={4}>
              <h3
                style={{
                  fontSize: "1.6rem",
                  color: theme.colors.white,
                  textAlign: "center",
                }}
              >
                Team {user}
              </h3>
            </Grid>
          </Grid>
        </Grid>
      </motion.div>
      <motion.div exit={{ opacity: 0 }}>
        <Grid
          xs={12}
          style={{
            margin: "0 auto",
            height: height,
            textAlign: "start",
            backgroundColor: theme.colors.purple,
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid container justify="center" item xs={11} md={5} lg={4}>
            <Grid>
              <p
                style={{
                  margin: 0,
                  marginBottom: 20,
                  fontSize: "2em",
                  fontWeight: 400,
                  lineHeight: 1.3,
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Antwoord op <strong>beslissingsvraag</strong>
              </p>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid
                  style={{ marginTop: "1em" }}
                  container
                  spacing={2}
                  justify="center"
                >
                  <Grid item xs={11} style={{ maxWidth: "380px" }}>
                    <AnswerEstimationDecision
                      confetti={confetti}
                      setChosenAnswer={setChosenAnswer}
                      chosenAnswer={chosenAnswer}
                      round={round}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: "1em" }} xs={12} md={"auto"}>
              {!startChecking && !stopAnswering && (
                <Button
                  disableGreen={answered}
                  onClick={() => {
                    if (!answered && chosenAnswer !== undefined) {
                      setAnswered(true);
                      socket.emit("send answer", {
                        user,
                        roomPin,
                        chosenAnswer,
                      });
                    }
                  }}
                  primary
                  fullWidth
                >
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 900,
                      color:
                        answered || chosenAnswer === undefined
                          ? "rgba(42, 57, 148, 0.5)"
                          : theme.colors.white,
                    }}
                  >
                    {answered ? "Antwoord verstuurd" : "Bevestig antwoord"}
                  </p>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </motion.div>
      <CashQuizLogo />
      <BackgroundMobile white />
    </>
  );
};

export default GameDecision;
