import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

import { transitionSpring } from "../pages/hostpages/SelectLesson";
import { Button } from "./Buttons";

const TeamReplyStyled = styled(motion.div)`
  width: 100%;
  padding: 24px 16px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 0px #f4f4f4;
  border-radius: 8px;
  left: 0px;
  position: relative;
  align-self: flex-end;
  font-weight: 600;
  margin-bottom: 12px;
  text-align: end;
  line-height: 1.2;
  font-size: 1.2rem;

  text-align: start;
  align-self: flex-start;
  background: #dcf7c5;
  color: #2d4b94;

  svg {
    transform: rotateY(-180deg);

    position: absolute;
    bottom: 0px;
    right: -20px;
    width: 40px;
    height: 40px;
    z-index: 1;
    //flip y odd

    transform: rotateY(0deg);
    right: auto;
    left: -20px;
  }

  .time {
    position: absolute;
    bottom: 0px;
    right: 12px;
    font-weight: 400;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.25);
  }
`;
const Box = styled(motion.div)`
  width: 100%;
  min-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;

  .wrap {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    width: 100%;

    p {
      margin: 0px;
      margin-top: 24px;
      font-weight: 600;
      font-size: 1.3rem;
    }
  }

  .column {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const OpenAnswer = ({
  selectedPlayer,
  setSelectedPlayer,
  players,
  setConfirm,
  setTeamsResults,
  TeamsResults,
}) => {
  const player = players[selectedPlayer];

  const handleSetSelectedPlayer = (correct) => {
    if (correct) {
      setTeamsResults(
        TeamsResults.map((team) => {
          if (team.user === player.user) {
            return {
              user: player.user,
              answer: player.answer,
              correct: true,
            };
          } else {
            return team;
          }
        })
      );
    } else {
      setTeamsResults(
        TeamsResults.map((team) => {
          if (team.user === player.user) {
            return {
              user: player.user,
              answer: player.answer,
              correct: false,
            };
          } else {
            return team;
          }
        })
      );
    }

    if (
      TeamsResults.filter((i) => i.user !== player.user).every(
        (player) => player.correct !== undefined
      )
    ) {
      setConfirm(true);
    } else {
      setSelectedPlayer(selectedPlayer + 1);
    }
  };

  return (
    <Box layout>
      <AnimatePresence>
        <div className="wrap">
          <p>Team {selectedPlayer + 1}:</p>
          <div className="column">
            <TeamReply team={player} />
            <Grid container spacing={2}>
              <Grid item>
                <Button primary onClick={() => handleSetSelectedPlayer(true)}>
                  Goed
                </Button>
              </Grid>
              <Grid item>
                <Button primary onClick={() => handleSetSelectedPlayer(false)}>
                  Fout
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </AnimatePresence>
    </Box>
  );
};

const TeamReply = ({ team }) => {
  return (
    <TeamReplyStyled
      layout
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      transition={{ ...transitionSpring, delay: 0.1 }}
    >
      {team?.answer ? team?.answer : "Geen antwoord"}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="37"
        fill="none"
      >
        <g filter="url(#a)">
          <path
            fill="#DCF7C5"
            fill-rule="evenodd"
            d="M20.6 0c0 11.33-5.77 19.24-17.31 23.76l-.78.3A2.323 2.323 0 0 0 2 28.15c4.08 2.79 9.51 4.41 18.59 4.85V0h.01Z"
            clip-rule="evenodd"
          />
        </g>
        <defs>
          <filter
            id="a"
            width="19.604"
            height="35"
            x=".996"
            y="0"
            color-interpolation-filters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0.956863 0 0 0 0 0.956863 0 0 0 0 0.956863 0 0 0 1 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_67_221"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_67_221"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </TeamReplyStyled>
  );
};

export default OpenAnswer;
