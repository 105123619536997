import React, { useState } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence, motion } from "framer-motion";

import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import EstimationInput from "../atoms/EstimationInput";
import { useMainStore } from "../../../../store";

const AnswerEstimation = ({
  selectedPlayer,
  players,
  questions,
  round,
  setPlayerAnswer,
  setConfirm,
  setSelectedPlayer,
}) => {
  const [input, setInput] = useState();
  const [sameAnswer, setSameAnswer] = useState(false);
  const { decision, level, theme } = useMainStore();
  const setAnswer = (answer) => {
    // if answer is same as any of the answers in the player array, set answer to that answer
    // removed in order to fix duplicated answer possibilities 01-03-2023 - Gijs
    if (players.some((player) => player.answer === answer)) {
      // setSameAnswer(true);
      // return;
    } else {
      setSameAnswer(false);
    }

    setPlayerAnswer(answer);
    setInput();
    setTimeout(() => {
      if (players.every((player) => player.answer !== undefined)) {
        setConfirm(true);
      } else {
        players.length !== selectedPlayer + 1
          ? setSelectedPlayer((selectedPlayer += 1))
          : players.findIndex((player) => player.answer === undefined) ===
            players.length - 1
          ? setConfirm(true)
          : setSelectedPlayer(
              players.findIndex((player) => player.answer === undefined)
            );
      }
    }, 500);
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={6} style={{ padding: "1rem 3rem 3rem 3rem" }}>
          <motion.h1
            transition={{ ...transitionSpring, delay: 0.5 }}
            style={{ color: theme.colors.purple }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <span style={{ fontFamily: "proxima-nova", fontWeight: 400 }}>
              Het antwoord van
            </span>{" "}
            <AnimatePresence exitBeforeEnter>
              <motion.span
                transition={{ ...transitionSpring }}
                style={{ display: "inline-block" }}
                key={selectedPlayer}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
              >
                team{" "}
                {players[selectedPlayer].group
                  ? players[selectedPlayer].group
                  : selectedPlayer + 1}
              </motion.span>
            </AnimatePresence>
          </motion.h1>
          {questions[round].tip && (
            <motion.h2 style={{ color: theme.colors.purple, fontSize: "16px" }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: questions[round].tip,
                }}
              />
            </motion.h2>
          )}
          {sameAnswer && (
            <motion.h2 style={{ color: theme.colors.red, fontSize: "16px" }}>
              <p>Dit antwoord is al gegeven. Kies een ander antwoord.</p>
            </motion.h2>
          )}
        </Grid>
        <Grid
          style={{
            backgroundColor: theme.colors.purple,
            padding: "1.5rem 1.5rem 1.5rem 1.5rem",
          }}
          item
          xs={6}
        >
          <EstimationInput
            prepend={questions && questions[round].unit_prepend}
            unit={
              !decision
                ? questions[round].unit
                : JSON.parse(level.params).final_answer_unit
            }
            input={input}
            setInput={setInput}
            setAnswer={setAnswer}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AnswerEstimation;
