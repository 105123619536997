import React, { useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";

import { useMainStore } from "../../../../../store";
import { NavigationNext } from "../../atoms/Buttons";
import CloudBackgroundVideo from "../../atoms/CloudBackgroundVideo";
import Topbar from "../../molecules/Topbar";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";
import { motion } from "framer-motion";
import { TextLink } from "../../atoms/TextLink";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";

const QuestionVideo = ({ homework }) => {
  const { questions, round, setPhase, setRound } = useMainStore();
  const { small, height } = useWindowDimensions();
  return (
    <>
      <Topbar
        layoutId="bar"
        initial
        question={round + 1}
        questionSoort={questions[round].soort}
      />
      <CloudBackgroundVideo />
      <Grid
        container
        style={{ height: height, position: "absolute", top: "0" }}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={11} sm={8}>
          <motion.div
            transition={{ ...transitionSpring, delay: 1 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              x: "-100%",
              opacity: 0,
              transition: { duration: 0.5, delay: 0 },
            }}
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: "0",
            }}
          >
            <iframe
              style={{
                top: "0",
                left: "0",
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "0",
              }}
              src={`https://www.youtube.com/embed/${questions[round].videourl}?rel=0&enablejsapi=1`}
              frameBorder="0"
              title="video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </motion.div>
        </Grid>

        {!homework && (
          <TextLink
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            whileHover={{
              scale: 1.1,
              cursor: "pointer",
            }}
            transition={{
              scale: { delay: 0 },
              opacity: { delay: 2.5 },
            }}
            exit={{ opacity: 0, transition: { delay: 0 } }}
            onClick={() => {
              if (round === 0) setPhase("explanation");
              else {
                setRound(round - 1);
                setPhase("quizStart");
              }
            }}
            navigationPrevPurple
            bottom={"50px"}
            left={"50px"}
            style={{
              position: "absolute",
            }}
          >
            terug
          </TextLink>
        )}

        <NavigationNext
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            scale: { delay: 0 },
            opacity: { delay: 2 },
          }}
          whileHover={{
            scale: 1.1,
          }}
          nextPhase={() => setPhase("question")}
          bottom={small ? 30 : 30}
          right={small ? 15 : 30}
          text={"Volgende"}
        />
      </Grid>
    </>
  );
};

export default QuestionVideo;
