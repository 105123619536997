import React from "react";
import { useMainStore } from "../../../../store";

const BackgroundMobile = ({ white, height }) => {
  const { theme } = useMainStore();

  return (
    <div
      style={{
        width: "100vw",
        backgroundColor: white ? "#fff" : theme.colors.background,
        height: height,
        position: "absolute",
        top: 0,
        zIndex: -2,
        overflowY: "none",
      }}
    ></div>
  );
};

export default BackgroundMobile;
