import React, { useEffect, useState } from "react";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import styled from "styled-components";
import Grid from "@bit/mui-org.material-ui.grid";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import Confetti from "react-dom-confetti";
import { numberToLetter } from "./LetterBox";
import { useMainStore } from "../../../../store";

export const transitionSlowSpring = {
  stiffness: 600,
  damping: 100,
  mass: 8.5,
  type: "spring",
};

export const Bar = styled(motion.div)`
  z-index: 1;
  background-color: ${(props) => props.theme.colors.purple};
  max-width: 120px;
  height: 600px;
  padding: 32px 16px;
  margin: 0 auto;
`;

const AnswerPlace = styled(motion.span)`
  overflow: hidden;
  display: inline-block;
  background-color: ${(props) => props.theme.colors.purpleShadow};
  margin-bottom: 20px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: ${(props) => `0 -4px 0 ${props.theme.colors.purpleShadow}`};
`;

const AnswerCircle = styled(motion.div)`
  background: ${(props) => props.theme.colors.yellow};
  position: absolute;
  //   margin-bottom: 20px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: ${(props) => `${props.theme.colors.yellowShadow} 0 4px 0 `};
  font-family: proxima-nova;
  font-size: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled(motion.div)`
  font-family: ${(props) => (props.bold ? "proxima-nova" : "proxima-nova")};
  font-size: 1.4em;
  margin-bottom: ${(props) => props.margin && "10px"};
  color: ${(props) => props.theme.colors.buttonText};
`;

const ScoreBar = ({
  index,
  score,
  begin,
  teamsAnswers,
  questions,
  round,
  allScores,
}) => {
  const [confetti, setConfetti] = useState(false);
  const [totalAnimation, setTotalAnimation] = useState(false);
  const [scoreWin, setScoreWin] = useState(false);
  const { theme } = useMainStore();
  const [scoreHeights, setScoreHeights] = useState(null);

  //scoreheights calc

  useEffect(() => {
    const highestScore = Math.max.apply(
      Math,
      allScores.map(function (o) {
        return o.total;
      })
    );

    const differences = allScores.map((score) => {
      return {
        ...score,
        percDifference: ((highestScore - score.total) / score.total) * 100,
      };
    });
    setScoreHeights(differences);
  }, [score]);

  const [showTotalScore, setShowTotalScore] = useState(false);

  const circleVariants = {
    initial: {
      opacity: 0,
      y: -200,
    },
    half: (custom) => ({
      opacity: 1,
      y: 200,
      transition: { delay: custom * 0.1 + 1, ...transitionSlowSpring },
    }),
    down: (custom) => ({
      y: 550,
      transition: { delay: custom * 0.1, ...transitionSlowSpring },
    }),
    exit: { opacity: 0, transition: { duration: 0.01 } },
  };

  const insertVariants = {
    initial: {
      y: -80,
    },
    insert: (custom) => ({
      y: 0,
      background: scoreWin ? theme.colors.yellow : theme.colors.yellow,
      transition: {
        delay: custom * 0.1,
        ...transitionSlowSpring,
        background: { delay: 1.5 },
      },
    }),
  };

  const barVariants = {
    initial: {
      y: "100%",
    },
    animate: (custom) => ({
      y: 350,
    }),
    win: (custom) => ({
      backgroundColor: theme.colors.yellow,
      y: 330,
      transition: { delay: 1, ...transitionSlowSpring },
    }),
    ranking: (custom) => ({
      backgroundColor: theme.colors.purple,
      y: custom.percDifference > 200 ? 230 + 130 : 230 + custom.percDifference,
      transition: { delay: 1, ...transitionSlowSpring },
    }),
    exit: (custom) => ({
      y: "140%",
    }),
  };

  const textVariants = {
    initial: { color: "#fff" },
    win: {
      color: theme.colors.purple,
    },
    exit: {
      color: theme.colors.buttonText,
      opacity: 0,
    },
  };

  const titleVariants = {
    initial: { color: "#fff" },
    win: {
      color: theme.colors.purple,
    },
    ranking: {
      color: "#fff",
    },
    exit: {
      color: theme.colors.buttonText,
      opacity: 0,
    },
  };

  const placeVariants = {
    initial: {
      background: scoreWin ? theme.colors.yellow : theme.colors.yellow,
      boxShadow: "none",
    },
    insert: {
      background: scoreWin ? theme.colors.yellow : theme.colors.yellow,
      boxShadow: "none",
    },
  };

  const configConfetti = {
    angle: 90,
    spread: "21",
    startVelocity: "23",
    elementCount: "37",
    dragFriction: "0.07",
    duration: "1830",
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: [theme.colors.purple, theme.colors.yellow],
  };

  const controlsCircle = useAnimation();
  const controlsBar = useAnimation();
  const controlsText = useAnimation();
  const controlsTitle = useAnimation();
  const controlsInsert = useAnimation();
  const controlsPlace = useAnimation();

  useEffect(() => {
    if (begin) {
      setScoreWin(true);
    } else {
      if (score.last_score > 0) {
        setScoreWin(true);
      } else {
        setScoreWin(false);
      }
    }
  }, [score.last_score, begin, setScoreWin]);

  useEffect(() => {
    controlsCircle.start("half");
    controlsBar.start("animate");
    setTimeout(() => {
      controlsCircle.start("down");
    }, 3000);
    setTimeout(() => {
      scoreWin && controlsBar.start("win");
      scoreWin && controlsText.start("win");
      scoreWin && controlsTitle.start("win");
    }, 3500);

    setTimeout(() => {
      controlsInsert.start("insert");
    }, 3150);

    setTimeout(() => {
      controlsPlace.start("insert");
    }, 3500);

    setTimeout(() => {
      scoreWin && setConfetti(true);
    }, 4000);

    if (showTotalScore) {
      const rankingTimeOut = setTimeout(() => {
        setTotalAnimation(true);
        controlsBar.start("ranking");
        controlsTitle.start("ranking");
      }, 6000);
      return () => {
        clearTimeout(rankingTimeOut);
      };
    }
  }, [
    controlsText,
    controlsTitle,
    controlsCircle,
    controlsBar,
    scoreWin,
    controlsInsert,
    controlsPlace,
    showTotalScore,
  ]);

  useEffect(() => {
    if (!begin) {
      if (round === 0 || round === 3 || round === 6) {
        setShowTotalScore(true);
      }
    }
  }, []);

  const getText = () => {
    if (begin) {
      return `€${score.last_score ? score.last_score : score.total}`;
    } else {
      if (!teamsAnswers[index] || teamsAnswers[index].answer.length <= 0) {
        return "x";
      } else if (questions[round].type === "waarnietwaar" || questions[round].type === "open") {
        return teamsAnswers[index].answer;
      } else if (questions[round].type === "schatting") {
        return questions[round].unit_prepend
          ? `${questions[round].unit.trim()}${teamsAnswers[index].answer}`
          : `${teamsAnswers[index].answer}${questions[round].unit}`;
      } else if (
        questions[round].type === "meerkeuze" &&
        teamsAnswers[index].answer
      ) {
        if (teamsAnswers[index].answer.length === 1) {
          return `${numberToLetter(teamsAnswers[index].answer[0])}`;
        }
        if (teamsAnswers[index].answer.length === 2) {
          return `${numberToLetter(
            teamsAnswers[index].answer[0]
          )}${numberToLetter(teamsAnswers[index].answer[1])}`;
        }
        if (teamsAnswers[index].answer.length === 3) {
          return `${numberToLetter(
            teamsAnswers[index].answer[0]
          )}${numberToLetter(teamsAnswers[index].answer[1])}${numberToLetter(
            teamsAnswers[index].answer[2]
          )}`;
        }
        if (teamsAnswers[index].answer.length === 4) {
          return `${numberToLetter(
            teamsAnswers[index].answer[0]
          )}${numberToLetter(teamsAnswers[index].answer[1])}${numberToLetter(
            teamsAnswers[index].answer[2]
          )}${numberToLetter(teamsAnswers[index].answer[3])}`;
        }
        if (teamsAnswers[index].answer.length === 5) {
          return `${numberToLetter(
            teamsAnswers[index].answer[0]
          )}${numberToLetter(teamsAnswers[index].answer[1])}${numberToLetter(
            teamsAnswers[index].answer[2]
          )}${numberToLetter(teamsAnswers[index].answer[3])}${numberToLetter(
            teamsAnswers[index].answer[4]
          )}`;
        }
      } else if (teamsAnswers[index]) {
        return `${numberToLetter(teamsAnswers[index].answer)}`;
      }
    }
  };

  return (
    <>
      <Grid container direction="column" alignItems="center" justify="center">
        <Grid item>
          <div style={{ width: 75, height: 75 }}>
            <AnswerCircle
              style={{ zIndex: -1 }}
              initial="initial"
              variants={circleVariants}
              animate={controlsCircle}
              custom={index}
              exit="exit"
            >
              <Text
                style={{
                  fontWeight: 700,
                  fontFamily: "Brevia",
                  fontSize:
                    questions[round].type === "waarnietwaar" ||
                    (!!getText() && getText().length > 3)
                      ? "0.9em"
                      : "1.2em",
                  lineHeight:
                    questions[round].type === "waarnietwaar" ||
                    (!!getText() && getText().length > 3)
                      ? "0.9em"
                      : "1.2em",
                }}
                variants={textVariants}
                animate={controlsText}
                bold
              >
                {getText()}
              </Text>
            </AnswerCircle>
          </div>
        </Grid>
      </Grid>
      <Bar
        variants={barVariants}
        animate={controlsBar}
        initial="initial"
        exit="exit"
        custom={
          scoreHeights &&
          scoreHeights.find((item) => item.group === score.group)
        }
        transition={{ ...transitionSpring, delay: index * 0.1 }}
      >
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item>
            {!begin && (
              <Confetti
                style={{ zIndex: 10 }}
                active={confetti}
                config={configConfetti}
              />
            )}
            <AnswerPlace animate={controlsPlace} variants={placeVariants}>
              <AnswerCircle
                animate={controlsInsert}
                variants={insertVariants}
                initial="initial"
                style={{ position: "relative", boxShadow: "0" }}
                custom={index}
              >
                <Text
                  variants={textVariants}
                  animate={controlsText}
                  bold
                  style={{
                    fontSize: "1em",
                    fontFamily: "Brevia",
                    fontWeight: 700,
                  }}
                >
                  + €{begin ? score.total : score.last_score}
                </Text>
              </AnswerCircle>
            </AnswerPlace>
          </Grid>
          <Grid item>
            <Text
              margin
              variants={titleVariants}
              initial="initial"
              animate={controlsTitle}
              bold
              style={{ fontFamily: "Brevia", fontWeight: 700 }}
            >
              Team {index + 1}
            </Text>
          </Grid>
          <Grid item>
            <AnimatePresence exitBeforeEnter>
              {totalAnimation ? (
                <Text
                  style={{
                    position: "absolute",
                    top: -80,
                    fontFamily: "Brevia",
                    fontWeight: 600,
                    left: 0,
                    right: 0,
                    margin: "0 auto",
                  }}
                  transition={transitionSpring}
                  key={score.total}
                  exit={{ y: 20, opacity: 0 }}
                  initial={{ y: -20, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    color: theme.colors.purple,
                  }}
                >
                  {!begin ? (
                    <>
                      <span style={{ fontSize: "0.7em" }}>
                        {showTotalScore ? "totaalscore" : ""}
                        <br />
                      </span>{" "}
                      {showTotalScore ? "€" + score.total : ""}
                    </>
                  ) : (
                    ""
                  )}
                </Text>
              ) : (
                <Text
                  style={{ color: "#fff" }}
                  transition={transitionSpring}
                  exit={{ y: 20, opacity: 0 }}
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  key={score.total - score.last_score}
                >
                  {!begin ? (
                    <>
                      <span style={{ fontSize: "0.7em" }}>
                        {showTotalScore ? "totaalscore" : ""}
                        <br />
                      </span>{" "}
                      {showTotalScore
                        ? "€" + (score.total - score.last_score)
                        : ""}
                    </>
                  ) : (
                    ""
                  )}
                </Text>
              )}
            </AnimatePresence>
          </Grid>
        </Grid>
      </Bar>
    </>
  );
};

export default ScoreBar;
