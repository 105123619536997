import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Grid from "@bit/mui-org.material-ui.grid";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { Button, TinyButton } from "../atoms/Buttons";
import arrow from "../../../../static/media/arrow.svg";
import { useMainStore } from "../../../../store";
import { useSocketStore } from "../../../../store/socketStore";
import { useGAEventsTracker } from "../../../../hooks/useGAEventsTracker";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import { InsertEmoticonSharp } from "@material-ui/icons";

const Lesson = ({
  index,
  lecture,
  homework,
  setLessonSocket,
  setPhaseSocket,
}) => {
  const { setPhase, setLesson, setTeams, theme } = useMainStore();
  const GAEventsTracker = useGAEventsTracker("cashquiz");

  const selectLesson = (gameid) => {
    GAEventsTracker("les selecteren");
    setLesson(lecture);
    setLessonSocket(lecture);
    if (homework) {
      let filledArray = new Array(1).fill(null).map(() => ({ score: 10 }));
      setTeams(filledArray);
    }

    setPhase(homework ? "explanation" : "selectLevel");
    setPhaseSocket("selectLevel");

    // setLesson(lecture);
    // setPhase("selectLevel");
  };

  var char_count = lecture.groepnaam.length + lecture.teacher.length;
  var group_length = 50 - lecture.teacher.length;

  return (
    <motion.div
      style={{
        backgroundColor: "#fff",
        padding: "0.5em",
        borderRadius: "5px",
        marginTop: "1em",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, y: 0, transition: { delay: index * 0.07 + 0.2 } }}
      exit={{ opacity: 0 }}
      transition={{ delay: index * 0.07 }}
    >
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <motion.span
            style={{
              fontSize: "1.2em",
              marginRight: "2em",
              color: theme.colors.black,
            }}
          >
            <>
              <strong>
                <span style={{ opacity: 1 }}>{`${lecture.groepnumber}`}</span>
              </strong>
            </>
            {lecture.groepnaam && (
              <>
                <span
                  style={{
                    fontSize: "0.8em",
                    color: theme.colors.orange,
                  }}
                >
                  {"  "}
                  {"  "}
                </span>
                <span style={{ opacity: 1 }}>
                  {char_count > 55
                    ? lecture.groepnaam.substr(0, group_length) + "..."
                    : `${lecture.groepnaam}`}
                </span>
              </>
            )}
            {
              <>
                <span
                  style={{
                    fontSize: "0.8em",
                    color: theme.colors.orange,
                  }}
                >
                  {"  "}
                  {"  "}
                </span>
                <span style={{ opacity: 1 }}>{`${lecture.teacher}`}</span>
              </>
            }
          </motion.span>
        </Grid>
        <Grid item>
          <Button
            onClick={() => selectLesson(lecture.id)}
            fontSize="1em"
            padding="10px 15px"
            primary
          >
            Selecteer
          </Button>
        </Grid>
      </Grid>
    </motion.div>
  );
};

const Accordion = ({
  i,
  expanded,
  setExpanded,
  school,
  homework,
  setPhaseSocket,
  setLessonSocket,
  hideTestLecture,
}) => {
  const { setPhase, setLesson, setTeams, theme } = useMainStore();
  const isOpen = i === expanded;
  const GAEventsTracker = useGAEventsTracker("cashquiz");
  let history = useHistory();
  if (hideTestLecture & (school.school_name === "Testschool")) return null;
  const selectLesson = (lectid) => {
    GAEventsTracker("les selecteren");
    setLesson(school.lecture[0]);
    setLessonSocket(school.lecture[0]);
    if (homework) {
      let filledArray = new Array(1).fill(null).map(() => ({ score: 10 }));
      setTeams(filledArray);
    }

    setPhase(homework ? "explanation" : "selectLevel");
    setPhaseSocket("selectLevel");
  };

  var char_count =
    school.lecture[0].groepnaam.length + school.lecture[0].teacher.length;
  var group_length = 55 - school.lecture[0].teacher.length;

  return (
    <>
      <motion.div
        style={{
          padding: "1em",
          borderRadius: "5px",
          height: "100%",
          marginBottom: 10,
          backgroundColor:
            school.lecture[0].school_name === "Testschool"
              ? "rgba(255,255,255,0.1)"
              : theme.colors.grey,
          border:
            school.lecture[0].school_name === "Testschool"
              ? "1px solid rgba(255,255,255,0.8)"
              : "0 none",
        }}
        layout
        transition={{
          opacity: { delay: i * 0.1 + 0.5 },
          y: { delay: i * 0.1 + 0.5 },
          ...transitionSpring,
        }}
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <motion.h2
              transition={{
                opacity: { delay: 0.2 },
                ...transitionSpring,
              }}
              style={{
                textAlign: "left",
                margin:
                  school.lecture[0].school_name === "Testschool"
                    ? "10px 0px 10px 0px"
                    : "0 0px 10px 0px",
                color:
                  school.lecture[0].school_name === "Testschool"
                    ? theme.colors.white
                    : "#000",
                fontSize: "1.5em",
              }}
              layout
            >
              {school.lecture[0].school_name === "Testschool"
                ? "Oefen de Cash Quiz"
                : school.school_name}
            </motion.h2>
            {school.lecture.length > 1 ? (
              ""
            ) : (
              <motion.span
                style={{
                  float: "left",
                  fontSize: "1.2em",
                  marginRight: "2em",
                  color: theme.colors.black,
                }}
              >
                {school.lecture[0].school_name !== "Testschool" && (
                  <>
                    <>
                      <span style={{ opacity: 0.4 }}>
                        {`${school.lecture[0].groepnumber}`}
                      </span>
                    </>
                    {school.lecture[0].groepnaam && (
                      <>
                        <span
                          style={{
                            fontSize: "0.8em",
                            color: theme.colors.orange,
                          }}
                        ></span>
                        <span style={{ opacity: 0.4 }}>
                          {char_count > 55
                            ? school.lecture[0].groepnaam.substr(
                                0,
                                group_length
                              ) + "..."
                            : `${school.lecture[0].groepnaam}`}
                        </span>
                      </>
                    )}
                    {
                      <>
                        <span
                          style={{
                            fontSize: "0.8em",
                            color: theme.colors.orange,
                          }}
                        >
                          {"  "}
                          {"  "}
                        </span>
                        <span style={{ opacity: 0.4 }}>
                          {`${school.lecture[0].teacher}`}
                        </span>
                      </>
                    }
                  </>
                )}
              </motion.span>
            )}
          </Grid>
          {school.lecture.length > 1 ? (
            <Grid item style={{ display: "flex" }}>
              <Button
                transition={{
                  opacity: { delay: 0.2 },
                  ...transitionSpring,
                }}
                onClick={() => setExpanded(isOpen ? false : i)}
                layout
                fontSize="1em"
                padding="10px 15px"
                primary
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Bekijk groepen
                <motion.img
                  animate={{ rotateZ: isOpen ? 180 : 0 }}
                  width={15}
                  height={15}
                  style={{
                    marginLeft: "15px",
                  }}
                  src={arrow}
                />
              </Button>
            </Grid>
          ) : (
            <Grid item>
              <Button
                onClick={() =>
                  school.lecture[0].school_name !== "Testschool"
                    ? selectLesson(school.lecture[0].id)
                    : history.push("./?oefensessie")
                }
                fontSize="1em"
                padding="10px 15px"
                primary
              >
                {school.lecture[0].school_name === "Testschool"
                  ? "Start oefensessie"
                  : "Selecteer"}
              </Button>
            </Grid>
          )}
        </Grid>
        {school.lecture.length >= 1 && (
          <AnimatePresence>
            {isOpen &&
              school.lecture.map((lecture, index) => (
                <Lesson
                  key={index}
                  isOpen={isOpen}
                  lecture={lecture}
                  index={index}
                  homework={homework}
                  setLessonSocket={setLessonSocket}
                  setPhaseSocket={setPhaseSocket}
                />
              ))}
          </AnimatePresence>
        )}
      </motion.div>
    </>
  );
};

const DropdownStyled = styled(motion.div)`
  max-height: 60vh;
  overflow-y: ${(props) => (props.isOpen ? "auto" : "hidden")};

  ::-webkit-scrollbar {
    width: 15px;
    background-color: rgba(255, 255, 255, 1);
    transform: translateX(-10px);
    border-bottom-right-radius: 10020px;
  }

  ::-webkit-scrollbar-track {
    width: 15px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    width: 15px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${(props) => props.theme.colors.yellow};
  }
`;

export const LessonDropdown = ({ lessons, homework, hideTestLecture }) => {
  const [expanded, setExpanded] = useState(-1);
  const { setPhase, setLesson } = useSocketStore();
  return (
    <>
      <DropdownStyled>
        {lessons.map((school, i) => (
          <Accordion
            hideTestLecture={hideTestLecture}
            setLessonSocket={setLesson}
            setPhaseSocket={setPhase}
            homework={homework}
            key={i}
            i={i}
            school={school}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ))}
      </DropdownStyled>
    </>
  );
};
