import React, { useEffect, useState } from "react";
import Grid from "@bit/mui-org.material-ui.grid";

import { motion } from "framer-motion";
import { backgrounds } from "../../../../../data/backgrounds";
import { Background } from "../../atoms/Backgrounds";
import ScoreBarSocket from "../../atoms/ScoreBarSocket";
import { Button } from "../../atoms/Buttons";
import { transitionSpring } from "./SelectLesson";
import Topbar from "../../molecules/Topbar";
import { useSocketStore } from "../../../../../store/socketStore";
import { toast } from "react-toastify";

const Scores = ({ begin }) => {
  const {
    roomPin,
    setPhase,
    questions,
    round,
    updateScores,
    scores,
    setRound,
    playersAnswered,
    gameid,
    level,
    theme,
  } = useSocketStore();
  const [scoreUpdated, setScoreUpdated] = useState(false);
  const [showTotalScore, setShowTotalScore] = useState(false);

  useEffect(() => {
    if (!begin) {
      if (round === 0 || round === 3 || round === 6) {
        setShowTotalScore(true);
      }
    }
  }, [setShowTotalScore, round]);

  useEffect(() => {
    fetch(process.env.REACT_APP_SERVER + "score/get-totals/" + gameid)
      .then((res) => res.json())
      .then(
        (result) => {
          updateScores(result);
          setScoreUpdated(true);
        },
        (error) => toast(`Fout bij het ophalen van de scores: ${error}`)
      );

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {begin && (
        <Topbar
          roomPin={roomPin}
          exit
          begin
          questionSoort={"Score"}
          pageTitle=""
          question={"Beginscore"}
        />
      )}
      {!begin && (
        <Topbar
          roomPin={roomPin}
          exit
          questionSoort={questions[round].soort}
          pageTitle={questions[round].soort}
          question={round + 1}
          showTotalScore={showTotalScore}
        />
      )}
      <motion.div exit={{ opacity: 0, transition: { delay: 1.5 } }}>
        <Grid
          style={{
            height: "100vh",
            textAlign: "center",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid
            item
            style={{
              height: "",
              position: "absolute",
              bottom: 0,
              width: "90%",
              left: "5%",
            }}
          >
            <Grid
              container
              spacing={2}
              justify="center"
              style={{
                position: "relative",
                bottom: 0,
                flexWrap: "nowrap",
              }}
            >
              {scoreUpdated &&
                scores.map((total, group) => (
                  <Grid key={group} item xs={2}>
                    <ScoreBarSocket
                      allScores={scores}
                      questions={questions}
                      round={round}
                      score={total}
                      index={group}
                      begin={begin}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            position: "absolute",
            bottom: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <motion.div
              transition={{ transitionSpring, delay: 5 }}
              initial={{ y: 300 }}
              animate={{ y: 0 }}
              exit={{ y: 300, transition: { ...transitionSpring, delay: 0 } }}
            >
              <Button
                onAnimationComplete={(definition) => {
                  if (definition?.opacity === 0 && !begin && round !== 9)
                    setRound(round + 1);
                }}
                whileHover={{
                  scale: 1.1,
                  delay: 0,
                }}
                primary
                onClick={() => {
                  if (round === 9) {
                    if (!!JSON.parse(level.params).outrovideourl)
                      setPhase("outro");
                    else setPhase("end");
                  } else {
                    setPhase("quizStart");
                  }
                }}
                exit={{ opacity: 0 }}
              >
                Verder
              </Button>
            </motion.div>
          </Grid>
        </Grid>
      </motion.div>
      <Background
        opacity
        initialBig
        exitOpacity
        background={"cloudBackgroundBigWhite"}
      />
    </>
  );
};

export default Scores;
