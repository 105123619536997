import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import CashQuizLogo from "../../../themes/2023/components/atoms/CashQuizLogo";
import { Background } from "../../../themes/2023/components/atoms/Backgrounds";
import { useMainStore } from "../../../store";
import { useGAEventsTracker } from "../../../hooks/useGAEventsTracker";
import { Button } from "../../../themes/2023/components/atoms/Buttons";
import Modal from "../../../themes/2023/components/atoms/Modal";

import logoText1 from "../../../static/2022/ben-jij-een-held.png";
import character from "../../../static/media/character.png";
import boards from "../../../static/2023/bordjes.png";
import phones from "../../../static/2023/phones.png";
import text1 from "../../../static/2023/Groep 9.png";
import text2 from "../../../static/2023/Groep 10.png";
import arrows1 from "../../../static/2022/arrows1.png";
import arrows2 from "../../../static/2022/arrows2.png";
import whiteArrow from "../../../static/2022/arrowWhite.png";
import BvdkFooter from "../../../themes/2023/components/molecules/BvdkFooter";
import { useHistory } from "react-router-dom";
import { useSocketStore } from "../../../store/socketStore";

const ChooseTypeQuiz = () => {
  const {
    setShowCoins,
    setTypeQuiz,
    setTypeHost,
    setCode,
    setLessons,
    setPhase,
    setRound,
    setLevel,
    setQuestions,
    setTeams,
    setGameID,
    updateScores,
    round,
    typeHost,
    typeQuiz,
  } = useMainStore();
  const GAEventsTracker = useGAEventsTracker("cashquiz");
  const [activegame, setActiveGame] = useState(null);
  const [hover, setHover] = useState(false);
  const history = useHistory();
  const socketStore = useSocketStore();

  //active game?
  useEffect(() => {
    setShowCoins(true);
    if (!!localStorage.getItem("gameid")) {
      let lastid = localStorage.getItem("gameid");
      fetch(process.env.REACT_APP_SERVER + "score/get-game/" + lastid)
        .then((res) => res.json())
        .then(
          (game) => {
            if (game.id && !!!game.winnaar) {
              if (!!game.question) {
                if (isNaN(game.question)) {
                  //start, decision, end
                  switch (game.question) {
                    case "start":
                      setRound(0);
                      break;
                    case "decision":
                      setRound(10);
                      fetch(
                        process.env.REACT_APP_SERVER +
                          "score/get-totals/" +
                          game.id
                      )
                        .then((res) => res.json())
                        .then(
                          (result) => {
                            updateScores(result);
                          },
                          (error) => {}
                        );
                      break;
                  }
                } else {
                  if (parseInt(game.question) === 10) {
                    setRound(10);
                    fetch(
                      process.env.REACT_APP_SERVER +
                        "score/get-totals/" +
                        game.id
                    )
                      .then((res) => res.json())
                      .then(
                        (result) => {
                          updateScores(result);
                        },
                        (error) => {}
                      );
                  } else {
                    setRound(parseInt(game.question));
                  }
                }
              } else {
                setRound(0);
              }
              setLevel(game.level);
              // game.questions doesnt exist
              setQuestions(game.questions);

              let teams = [];
              let i;
              for (i = 1; i <= game.groups; i++) {
                teams.push({ index: i });
              }
              setGameID(game.id);
              setTypeQuiz("cashquiz");
              setTypeHost(game.gespeeld_door);
              setTeams(teams);
              setActiveGame(1);
            }
          },
          (error) => console.log(error)
        );
    }
  }, [
    setTypeQuiz,
    setTypeHost,
    setGameID,
    setTeams,
    setLevel,
    setQuestions,
    setRound,
    setShowCoins,
    updateScores,
  ]);

  return (
    <>
      <CashQuizLogo initial />
      <BvdkFooter initial />
      <motion.img
        exit={{ opacity: 0, transition: { delay: 0 } }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 2 }}
        style={{
          position: "absolute",
          //center vertically
          transform: "translateY(-50%)",
          //center horizontally
          top: "50%",
          right: "10vw",
          width: "calc(10vw + 200px)",
          zIndex: -1,
          //move half of the width to the left
        }}
        src={character}
      />
      <motion.div>
        <Grid
          style={{
            gap: 32,
            height: "100vh",
            textAlign: "center",
          }}
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ position: "absolute", top: "20%" }}
          >
            <motion.img
              exit={{ opacity: 0, transition: { delay: 0 } }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2 }}
              // style={{ x: -60, y: 50 }}
              width={700}
              src={logoText1}
            />
          </Grid>
          <Grid
            exit={{ opacity: 0, transition: { delay: 0 } }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 2 }}
            component={motion.div}
            style={{ gap: 32 }}
            alignItems="center"
            justify="center"
            container
            direction="row"
          >
            <h2
              style={{
                marginTop: "10%",
                fontFamily: "brevia",
                fontSize: "1.6em",
                opacity: ".9",
                color: "white",
                fontWeight: 500,
              }}
            >
              {" "}
              Speel de cashquiz met
            </h2>
          </Grid>
          <Grid
            exit={{ opacity: 0, transition: { delay: 0 } }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 2 }}
            component={motion.div}
            style={{ gap: 32 }}
            alignItems="center"
            justify="center"
            container
            direction="row"
          >
            <Button
              style={{
                width: 350,
                height: 100,
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "1.1rem",
                textAlign: "left",
                paddingTop: 12,
                paddingBottom: 8,
                position: "relative",
                borderRadius: "0.8rem",
                boxShadow: "0 5px 0 #001ca5",
              }}
              onClick={() => {
                setTypeQuiz("cashquiz");
                setPhase("personalCode");
                history.push("/cashquiz");
              }}
              onMouseEnter={() => setHover("normal")}
              onMouseLeave={() => setHover(false)}
              primary
            >
              <motion.img
                style={{
                  x: -120,
                  y: -140,
                  position: "absolute",
                  left: 0,
                  opacity: ".8",
                }}
                width={220}
                src={text1}
              />
              <motion.img
                style={{ x: 20, y: -60, position: "absolute", left: 0 }}
                width={20}
                src={arrows1}
              />
              <Grid
                style={{ height: "90%" }}
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
              >
                <Grid item>
                  <motion.img
                    animate={{
                      y: hover === "normal" ? 0 : 0,
                      scale: hover === "normal" ? 1.2 : 1,
                    }}
                    width={85}
                    src={boards}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Grid container direction="column" alignItems="flex-start">
                    <div
                      style={{
                        fontSize: "2rem",
                        fontWeight: 800,
                        fontFamily: "Brevia",
                      }}
                    >
                      Antwoord- <br /> bordjes
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Button>
            <Button
              style={{
                width: 350,
                height: 100,
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "1.1rem",
                textAlign: "left",
                paddingTop: 13,
                paddingBottom: 8,
                position: "relative",
                borderRadius: "0.8rem",
                boxShadow: "0 5px 0 #001ca5",
              }}
              onClick={() => {
                setTypeQuiz("cashquiz-plus");
                setPhase("personalCode");
                socketStore.setPhase("personalCode");
                history.push("/cashquiz-plus");
              }}
              onMouseEnter={() => setHover("plus")}
              onMouseLeave={() => setHover(false)}
              primary
            >
              <motion.img
                style={{
                  x: -30,
                  y: 120,
                  position: "absolute",
                  left: 0,
                  opacity: ".9",
                }}
                width={220}
                src={text2}
              />
              <motion.img
                style={{ x: 80, y: 80, position: "absolute", left: 0 }}
                width={20}
                src={arrows2}
              />
              <Grid
                style={{ height: "90%" }}
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
              >
                <Grid item>
                  <motion.img
                    animate={{
                      y: hover === "plus" ? 0 : 0,
                      scale: hover === "plus" ? 1.2 : 1,
                    }}
                    width={85}
                    src={phones}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Grid container direction="column" alignItems="flex-start">
                    <div
                      style={{
                        fontSize: "2rem",
                        fontWeight: 800,
                        fontFamily: "Brevia",
                      }}
                    >
                      Tablets / <br /> telefoons
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </motion.div>
      {activegame && (
        <Modal
          round={round}
          setActiveGame={setActiveGame}
          activegame={activegame}
        />
      )}
      <Background background={"cloudBackground"} />
    </>
  );
};

export default ChooseTypeQuiz;
