import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Background } from "../../atoms/Backgrounds";
import { Button } from "../../atoms/Buttons";
import trophySilver from "../../../../../static/media/trophy-silver.png";
import trophyGold from "../../../../../static/media/trophy-gold.png";
import trophyBronze from "../../../../../static/media/trophy-bronze.png";
import { transitionSlowSpring } from "../../atoms/ScoreBarSocket";
import Ranking from "../../atoms/Ranking";
import { AnimatePresence } from "framer-motion";
import Confetti from "react-dom-confetti";
import { useSocketStore } from "../../../../../store/socketStore";
import CashQuizLogo from "../../atoms/CashQuizLogo";
import { useGAEventsTracker } from "../../../../../hooks/useGAEventsTracker";

const Bar = styled(motion.div)`
  height: 175px;
  width: 125px;
  background ${(props) => props.background};
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Box = styled(motion.div)`
  z-index: 2;
  position: relative;
  border-radius: 10px;
  background: #fff;
  padding: 2em;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

const Text = styled(motion.h3)`
  color: ${(props) => props.theme.colors.purple};
  font-weight: 900;
  font-size: 1.7em;
  margin: 0px;
`;

const TextSmaller = styled(motion.h3)`
  color: ${(props) => props.theme.colors.purple};
  font-weight: 400;
  font-size: 1.5em;
  margin: 0px 0px 0xp 0px;
`;

const EndPage = ({ socket }) => {
  const {
    scores,
    setShowCoins,
    setDecision,
    decision,
    setPhase,
    setDuplicates,
    setWinner,
    gameid,
    theme,
    duplicates,
    teams,
    level,
    roomPin,
  } = useSocketStore();
  const [total, setTotal] = useState(0);
  const [ranking, setRanking] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [showWinners, setShowWinners] = useState(false);
  const revealTexts = [
    "De antwoorden zijn binnen",
    "Het geld is geteld",
    "Wie is de winnaar?",
  ];
  const [revealText, setRevealText] = useState(0);
  const GAEventsTracker = useGAEventsTracker("cashquiz-plus");
  useEffect(() => {
    GAEventsTracker("eind score pagina behaald");

    setShowCoins(true);
    
    scores.map((score) => setTotal((old) => old + score.total));

    setTimeout(() => {
      setConfetti(true);
    }, 2000);
  }, [scores, setShowCoins]);

  useEffect(() => {
    const timeOut = () => {
      if (revealText >= revealTexts.length - 1) {
        setRevealText(0);
        return;
      }
      setRevealText(revealText + 1);
    };
    setTimeout(timeOut, 3000);
    return () => clearTimeout(timeOut);
  }, [revealText]);

  function hasDuplicates(array) {
    const highest = Math.max.apply(
      Math,
      array.map(function (o) {
        return parseInt(o.total);
      })
    );

    return array.filter((score) => score.total === highest && score);
  }

  useEffect(() => {
    setDecision(hasDuplicates(scores).length > 1);
    setDuplicates(hasDuplicates(scores));
  }, [scores, setDecision, setDuplicates]);

  useEffect(() => {
    if (decision) {
      const socketIds = duplicates.map((dup, i) => {
        return teams.filter((team) =>
          team.group == dup.group
            ? (duplicates[i].socketId = team.socketId)
            : ""
        );
      });

      socket.emit("show decision screen", {
        socketIds,
        level,
      });
      setPhase("QuestionDecisionExplanation");
    } else if (decision === false) {
      if (showWinners) {
        socket.emit("show winner", { roomPin, winner: scores[0].group });
      }
      setWinner({ gameid });
    }
  }, [setPhase, setWinner, decision, setShowWinners, showWinners]);

  scores.sort(function (a, b) {
    return b.total - a.total;
  });

  const config = {
    angle: 90,
    spread: "21",
    startVelocity: "23",
    elementCount: "37",
    dragFriction: "0.07",
    duration: "1830",
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: [theme.colors.purple, theme.colors.yellow],
  };
  if (!showWinners) {
    return (
      <>
        <Grid
          container
          style={{ height: "100vh" }}
          alignItems="center"
          justify="center"
          exit={{ opacity: 0 }}
          component={motion.div}
        >
          <Grid item xs={5}>
            <motion.h1
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{
                y: 20,
                opacity: 0,
                transition: { ...transitionSlowSpring, delay: 1 },
              }}
              transition={{ ...transitionSlowSpring, delay: 0.2 }}
              style={{ lineHeight: 1.2, fontFamily: "Brevia" }}
            >
              {revealTexts[revealText]}
            </motion.h1>
            <Button
              primary
              onClick={() => setShowWinners(true)}
              exit={{ opacity: 0 }}
              whileHover={{
                scale: 1.1,
                delay: 0,
              }}
            >
              🏆 Toon winnaar!
            </Button>
            <motion.h2
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ ...transitionSlowSpring, delay: 0.4 }}
              exit={{
                y: 20,
                opacity: 0,
                transition: { ...transitionSlowSpring, delay: 1.2 },
              }}
              style={{
                color: "#fff",
                fontSize: "1.7em",
                lineHeight: 1.5,
              }}
            ></motion.h2>
          </Grid>
        </Grid>
        <Background big background={"cloudBackgroundBig"} />
      </>
    );
  }
  return (
    <>
      <CashQuizLogo />
      <Grid
        container
        style={{ height: "100vh" }}
        alignItems="center"
        justify="center"
        exit={{ opacity: 0 }}
        component={motion.div}
      >
        <Grid item md={9} lg={7} xl={6}>
          <AnimatePresence exitBeforeEnter>
            {!ranking ? (
              <Grid key="noranking" container direction="column">
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                  >
                    <Grid item xs={5}>
                      <motion.h1
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{
                          y: 20,
                          opacity: 0,
                          transition: { ...transitionSlowSpring, delay: 1 },
                        }}
                        transition={{ ...transitionSlowSpring, delay: 0.2 }}
                        style={{ lineHeight: 1.2, fontFamily: "Brevia" }}
                      >
                        Team {scores[0].group} is <br /> de winnaar
                      </motion.h1>
                      <motion.h2
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ ...transitionSlowSpring, delay: 0.4 }}
                        exit={{
                          y: 20,
                          opacity: 0,
                          transition: { ...transitionSlowSpring, delay: 1.2 },
                        }}
                        style={{
                          color: "#fff",
                          fontSize: "1.7em",
                          lineHeight: 1.5,
                        }}
                      >
                        Met een totaalscore <br /> van {scores[0].total} euro
                      </motion.h2>
                    </Grid>
                    <Grid item xs={7}>
                      <Grid
                        container
                        direction="row"
                        style={{ flexWrap: "nowrap" }}
                      >
                        <Bar
                          initial={{ y: "100%", opacity: 0 }}
                          animate={scores.length >= 2 && { y: 20, opacity: 1 }}
                          transition={{ ...transitionSlowSpring, delay: 1.2 }}
                          exit={{
                            y: "100%",
                            opacity: 0,
                            transition: { ...transitionSlowSpring, delay: 0.2 },
                          }}
                          background="#e9f0f5"
                        >
                          <motion.img
                            src={trophySilver}
                            width={100}
                            height={100}
                            style={{ y: -124, position: "absolute" }}
                          />
                          {scores.length >= 2 && (
                            <>
                              <motion.h1
                                style={{
                                  position: "absolute",
                                  y: -145,
                                  fontSize: "2em",
                                  color: theme.colors.purple,
                                }}
                              >
                                2e
                              </motion.h1>
                              <Grid
                                container
                                alignItems="center"
                                direction="column"
                              >
                                <Text>Team {scores[1].group}</Text>
                                <TextSmaller>€{scores[1].total}</TextSmaller>
                              </Grid>
                            </>
                          )}
                        </Bar>
                        <Bar
                          initial={{ y: "100%", opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ ...transitionSlowSpring, delay: 1 }}
                          exit={{
                            y: "100%",
                            opacity: 0,
                            transition: { ...transitionSlowSpring, delay: 0 },
                          }}
                          background={theme.colors.yellow}
                        >
                          <motion.img
                            src={trophyGold}
                            width={100}
                            height={100}
                            style={{ y: -124, position: "absolute" }}
                          />
                          <motion.h1
                            style={{
                              position: "absolute",
                              y: -145,
                              fontSize: "2em",
                              color: theme.colors.purple,
                            }}
                          >
                            <Confetti config={config} active={confetti} />
                            1e
                          </motion.h1>
                          <Grid
                            container
                            alignItems="center"
                            direction="column"
                          >
                            <Text>Team {scores[0].group}</Text>
                            <TextSmaller>€{scores[0].total}</TextSmaller>
                          </Grid>
                        </Bar>
                        <Bar
                          initial={{ y: "100%", opacity: 0 }}
                          animate={scores.length >= 3 && { y: 30, opacity: 1 }}
                          transition={{ ...transitionSlowSpring, delay: 1.4 }}
                          exit={{
                            y: "100%",
                            opacity: 0,
                            transition: { ...transitionSlowSpring, delay: 0.4 },
                          }}
                          background="#e2984c"
                        >
                          <motion.img
                            src={trophyBronze}
                            width={100}
                            height={100}
                            style={{ y: -124, position: "absolute" }}
                          />
                          {scores.length >= 3 && (
                            <>
                              <motion.h1
                                style={{
                                  position: "absolute",
                                  y: -145,
                                  fontSize: "2em",
                                  color: theme.colors.purple,
                                }}
                              >
                                3e
                              </motion.h1>
                              <Grid
                                container
                                alignItems="center"
                                direction="column"
                              >
                                <Text>Team {scores[2].group}</Text>
                                <TextSmaller>€{scores[2].total}</TextSmaller>
                              </Grid>
                            </>
                          )}
                        </Bar>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Box
                    initial={{ x: 0, opacity: 0 }}
                    exit={{
                      x: 0,
                      opacity: 0,
                      transition: { ...transitionSlowSpring, delay: 0.8 },
                    }}
                    animate={{ x: 0, opacity: 1, y: 0 }}
                    transition={{ ...transitionSlowSpring, delay: 0.6 }}
                    style={{ y: -70 }}
                  >
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs={7}>
                        <p
                          style={{
                            fontSize: "1.5em",
                            margin: 0,
                            color: theme.colors.purple,
                            lineHeight: 1.3,
                          }}
                        >
                          De klas heeft totaal met{" "}
                          <strong>{scores.length}</strong> teams{" "}
                          <strong>{total} euro</strong> verdiend. Dit komt neer
                          op een gemiddelde van
                          <strong>
                            {" "}
                            {Math.round(total / scores.length)} euro
                          </strong>{" "}
                          per team.
                        </p>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => setRanking(true)}
                          primary
                          whileHover={{
                            scale: 1.1,
                            delay: 0,
                          }}
                        >
                          Bekijk ranking
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Ranking key="ranking" scores={scores} setRanking={setRanking} />
            )}
          </AnimatePresence>
        </Grid>
      </Grid>
      {!ranking && (
        <Grid
          style={{
            position: "absolute",
            bottom: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              primary
              onClick={() => setPhase("final")}
              exit={{ opacity: 0 }}
              whileHover={{
                scale: 1.1,
                delay: 0,
              }}
            >
              Verder
            </Button>
          </Grid>
        </Grid>
      )}
      <Background big background={"cloudBackgroundBig"} />
    </>
  );
};

export default EndPage;
