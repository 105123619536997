import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence, motion } from "framer-motion";

import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import { Button } from "../atoms/Buttons";
import { useMainStore } from "../../../../store";

const AnswerOpen = ({
  selectedPlayer,
  players,
  setPlayerAnswer,
  setConfirm,
  setSelectedPlayer,
  homework,
  small,
  hidetitle,
}) => {
  const { theme } = useMainStore();

  const setAnswer = (answer) => {
    setPlayerAnswer(answer);
    setTimeout(() => {
      if (players.every((player) => player.answer !== undefined)) {
        setConfirm(true);
      } else {
        players.length !== selectedPlayer + 1
          ? setSelectedPlayer((selectedPlayer += 1))
          : players.findIndex((player) => player.answer === undefined) ===
            players.length - 1
          ? setConfirm(true)
          : setSelectedPlayer(
              players.findIndex((player) => player.answer === undefined)
            );
      }
    }, 500);
  };

  return (
    <>
      <motion.h1
        transition={{ ...transitionSpring, delay: 0.5 }}
        style={{
          color: homework ? theme.colors.white : theme.colors.purple,
          fontSize: homework ? "1rem" : "",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {!hidetitle && (
          <>
            <span style={{ fontFamily: "proxima-nova", fontWeight: 400 }}>
              {homework ? "Jouw antwoord" : "Antwoord"}
            </span>{" "}
            {!homework && (
              <AnimatePresence exitBeforeEnter>
                <motion.span
                  style={{ display: "inline-block" }}
                  key={selectedPlayer}
                  transition={{ ...transitionSpring }}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -20, opacity: 0 }}
                >
                  team {selectedPlayer !== undefined ? selectedPlayer + 1 : 4}:
                </motion.span>
              </AnimatePresence>
            )}
          </>
        )}
      </motion.h1>
      <Grid
        style={{ marginTop: "1em" }}
        container
        spacing={small ? 1 : 3}
        justify="center"
      >
        <Grid item xs={6}>
          <Button
            transition={{ ...transitionSpring, delay: 0.6 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            disable={players[selectedPlayer].answer === "Goed"}
            onClick={() => setAnswer("Goed")}
            primary
            fullWidth
          >
            Goed
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            transition={{ ...transitionSpring, delay: 0.7 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            disable={players[selectedPlayer].answer === "Fout"}
            onClick={() => setAnswer("Fout")}
            primary
          >
            Fout
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AnswerOpen;
