export const themes = {
  2023: {
    colors: {
      buttonText: "#4A6DFE",
      background: "#274DFF",
      text: "#000",
      primary: "#fff",
      yellowLighter: "#ffd615",
      yellow: "#ffd300",
      yellowShadow: "#173fff",
      yellowTile: "#deb90a",
      purpleLighter: "#4A6DFE",
      purple: "#4A6DFE",
      purpleShadow: "#173fff",
      purpleDarker: "#173fff",
      orange: "#274DFF",
      orangeDarker: "#bb7f3d",
      orangeLighter: "#fca853",
      green: "#274DFF",
      greenShadow: "#173fff",
      greenDarker: "#173fff",
      beige: "#f2ecde",
      blue: "#274DFF",
      red: "#d15440",
      white: "#ffffff",
      grey: "#e9f0f5",
      greyDarker: "#bbc1c4",
      brown: "#fff",
      backgroundLight: "#4A6DFE",
      backgroundTransparent: "rgba(25,65,255,0.7)",
      goldGradient: `linear-gradient(120deg, rgba(62,95,255,1) 0%, rgba(25,65,255,1) 100%)`,
    },
  },
  2021: {
    colors: {
      buttonText: "#000",
      background: "#5c2d91",
      text: "#000",
      primary: "#fff",
      yellowLighter: "#ffd615",
      yellow: "#ffd300",
      yellowShadow: "#ceaf3a",
      yellowTile: "#deb90a",
      purpleLighter: "#4A6DFE",
      purple: "#5c2d91",
      purpleShadow: "#4d2279",
      purpleDarker: "#1f0843",
      orange: "#e2984c",
      orangeDarker: "#bb7f3d",
      orangeLighter: "#fca853",
      green: "#198498",
      greenShadow: "#167081",
      greenDarker: "#167081",
      beige: "#f2ecde",
      blue: "#1579cf",
      red: "#d15440",
      white: "#ffffff",
      grey: "#e9f0f5",
      greyDarker: "#bbc1c4",
      brown: "#fff",
      backgroundLight: "#643897",
      goldGradient: `linear-gradient(120deg, rgba(62,95,255,1) 0%, rgba(25,65,255,1) 100%)`,
    },
  },
  2019: {
    colors: {
      buttonText: "#fff",
      background: "#00609c",
      text: "#000",
      primary: "#ff8c3f",
      yellowLighter: "#ff944d",
      yellow: "#ff8c3f",
      yellowShadow: "#fa6400",
      yellowTile: "#ff8c3f",
      purpleLighter: "#0074bd",
      purple: "#00609c",
      purpleShadow: "#003f66",
      purpleDarker: "#003f66",
      orange: "#e2984c",
      orangeDarker: "#bb7f3d",
      orangeLighter: "#fca853",
      green: "#198498",
      greenShadow: "#167081",
      greenDarker: "#167081",
      beige: "#f2ecde",
      blue: "#1579cf",
      red: "#d15440",
      white: "#ffffff",
      grey: "#e9f0f5",
      greyDarker: "#bbc1c4",
      brown: "#a8751f",
      backgroundLight: "hsl(203, 100%, 25%)",
    },
  },
  2018: {
    colors: {
      buttonText: "#fff",
      background: "#029ab1",
      text: "#000",
      primary: "#e59534",
      yellowLighter: "#e79f46",
      yellow: "#e59534",
      yellowShadow: "#e2891d",
      yellowTile: "#e59534",
      purple: "#029ab1",
      purpleShadow: "#02889d",
      purpleDarker: "#017f92",
      orange: "#e2984c",
      orangeDarker: "#bb7f3d",
      orangeLighter: "#fca853",
      green: "#198498",
      greenShadow: "#167081",
      greenDarker: "#167081",
      beige: "#f2ecde",
      blue: "#1579cf",
      red: "#d15440",
      white: "#ffffff",
      grey: "#e9f0f5",
      greyDarker: "#bbc1c4",
      brown: "#a8751f",
      backgroundLight: "#017f92",
    },
  },
  2017: {
    colors: {
      buttonText: "#fff",
      background: "#00609c",
      text: "#000",
      primary: "#e59534",
      yellowLighter: "#e79f46",
      yellow: "#e69835",
      yellowShadow: "#e2891d",
      yellowTile: "#e59534",
      purple: "#00609c",
      purpleShadow: "#00609c",
      purpleDarker: "#1f0843",
      orange: "#e2984c",
      orangeDarker: "#bb7f3d",
      orangeLighter: "#fca853",
      green: "#198498",
      greenShadow: "#167081",
      greenDarker: "#167081",
      beige: "#f2ecde",
      blue: "#1579cf",
      red: "#d15440",
      white: "#ffffff",
      grey: "#e9f0f5",
      greyDarker: "#bbc1c4",
      brown: "#a8751f",
      backgroundLight: "hsl(203, 100%, 25%)",
    },
  },
};
