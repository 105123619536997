import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";

import logobvdk from "../../../../static/media/bvdk-logo-2x.svg";
import logonvb from "../../../../static/media/logo-nvb.svg";
import { transitionSlowSpring } from "../atoms/ScoreBar";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useMainStore } from "../../../../store";

const BvdkFooter = ({ initial, noTestlesson }) => {
  const { small } = useWindowDimensions();
  const history = useHistory();
  const { theme } = useMainStore();

  return (
    <>
      <motion.div
        style={{ position: "absolute", bottom: 0, width: "100%", y: 30 }}
        initial={initial && { opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 30 }}
        transition={{ delay: initial ? 1.7 : 0, ...transitionSlowSpring }}
      >
        <Grid
          container
          justify="space-between"
          alignItems="center"
          spacing={small ? 0 : 4}
          style={{
            color: "#000",
            position: "absolute",
            bottom: "30px",
          }}
        >
          <Grid
            item
            style={{
              padding: small ? "0 0 15px 15px" : "15px 30px 15px 45px",
            }}
          >
            <div style={{ display: "flex", gap: 32, alignItems: "center" }}>
              <img
                alt="logobvdk"
                src={logobvdk}
                style={{
                  width: "75px",
                }}
              />
              {!noTestlesson && (
                <h4
                  onClick={() => history.push("/?oefensessie")}
                  style={{
                    cursor: "pointer",
                    fontFamily: "Brevia",
                    color: theme.colors.purple,
                    fontWeight: 800,
                    fontSize: "1.1rem",
                    textDecoration: "underline",
                    textDecorationColor: "inherit",
                    textDecorationThickness: 2,
                    textUnderlineOffset: 8,
                  }}
                >
                  Speel een oefenles
                </h4>
              )}
            </div>
          </Grid>
          <Grid
            item
            style={{
              textAlign: "right",
            }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                style={{
                  padding: small ? "15px 15px 0 0" : "15px 30px",
                  display: small ? "none" : "block",
                  color: "#0b4f66",
                }}
              >
                <span>Een initiatief van</span>
              </Grid>
              <Grid
                item
                style={{
                  padding: small ? "15px 15px" : "15px 30px",
                }}
              >
                <img
                  alt="logonvb"
                  src={logonvb}
                  style={{
                    width: "140px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </motion.div>
    </>
  );
};
export default BvdkFooter;
