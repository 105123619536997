import React, { useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import CashQuizLogo from "../../atoms/CashQuizLogo";
import BackgroundMobile from "../../atoms/BackgroundMobile";
import { usePlayerStore } from "../../../../../store/playerStore";
import { transitionSpring } from "../hostpages/SelectLesson";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import { useGAEventsTracker } from "../../../../../hooks/useGAEventsTracker";

const Screen = ({ first }) => {
  const { user, setStopAnswering, theme, screenOutro, decision, winner } =
    usePlayerStore();
  const { height, small } = useWindowDimensions();
  const GAEventsTracker = useGAEventsTracker("cashquiz-plus");

  useEffect(() => {
    setStopAnswering(false);
  }, []);

  useEffect(() => {
    first && GAEventsTracker("speler doet mee");
  }, [first]);

  return (
    <>
        {!first && (
          <motion.div>
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{
                    backgroundColor: theme.colors.purpleShadow,
                    position: "absolute",
                    top: 0,
                    left: 0,
                }}
            >
                <Grid container justify="center">
                    <Grid item xs={11} md={6} lg={4}>
                        <h3
                            style={{
                                fontSize: small ? "1.2rem" : "1.6rem",
                                color: theme.colors.white,
                                textAlign: "center",
                                margin: small ? "0.4em" : "1em",
                            }}
                        >
                            Team {user}
                        </h3>
                    </Grid>
                </Grid>
            </Grid>
        </motion.div>
      )};
      <motion.div exit={{ opacity: 0 }}>
        {first ? (
          <>
            <motion.div
                style={{
                    marginTop: small ? "30px" : "60px",
                }}
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
            >
              <CashQuizLogo />
            </motion.div>
            <Grid
              style={{
                margin: "0 auto",
                height: height,
              }}
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={11} md={7} lg={5}>
                <motion.h1
                  style={{
                    color: theme.colors.white,
                    fontSize: "2.5em",
                    textAlign: "center",
                  }}
                  transition={{
                    ...transitionSpring,
                  }}
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 100 }}
                >
                  Jullie zijn team {user}, veel succes!
                </motion.h1>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <motion.div
                style={{
                    marginTop: small ? "30px" : "60px",
                }}
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
            >
              <CashQuizLogo />
            </motion.div>
            <Grid
              style={{
                margin: "0 auto",
                height: height,
              }}
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={11} md={7} lg={5}>
                <motion.h1
                  style={{
                    fontFamily: "Brevia",
                    color: theme.colors.white,
                    fontSize: "2.5em",
                    textAlign: "center",
                  }}
                  transition={{
                    ...transitionSpring,
                  }}
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 100 }}
                >
                  {screenOutro === "outro"
                    ? "Bekijk de eindvideo op het digibord"
                    : screenOutro === "end"
                    ? decision
                      ? "Gelijkspel! Dit wordt spannend"
                      : winner
                      ? winner == user
                        ? "Jullie hebben gewonnen!"
                        : "Team " + winner + " is de winnaar"
                      : "Bekijk de eindstand op het digibord"
                    : "Kijk naar het digibord"}
                </motion.h1>
              </Grid>
            </Grid>
          </>
        )}
      </motion.div>
      <BackgroundMobile height={height} />
    </>
  );
};

export default Screen;
