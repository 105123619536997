import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import Confetti from "react-confetti";

import { backgrounds } from "../../../../../data/backgrounds";
import { Background } from "../../atoms/Backgrounds";
import { TextLink } from "../../atoms/TextLink";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import { transitionSlowSpring } from "../../atoms/ScoreBar";
import { transitionSpring } from "./SelectLesson";
import { useSocketStore } from "../../../../../store/socketStore";
import geldheldenImage from "../../../../../static/2023/geldhelden.png";

const FinalPage = () => {
  const { setPhase, setShowCoins, theme } = useSocketStore();
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    setShowCoins(true);
  }, []);

  return (
    <>
      <Confetti
        style={{ zIndex: -1 }}
        gravity={0.04}
        width={width}
        height={height}
        colors={[
          theme.colors.purple,
          theme.colors.yellow,
          theme.colors.yellowShadow,
          theme.colors.yellowLighter,
          theme.colors.purpleShadow,
          theme.colors.purpleDarker,
        ]}
      />
      <Grid
        container
        style={{ height: "100vh", textAlign: "start" }}
        alignItems="center"
        justify="center"
        exit={{ opacity: 0 }}
        component={motion.div}
      >
        <Grid item>
          <img
            src={geldheldenImage}
            width={486}
            height={462}
            style={{ paddingLeft: "11em", paddingRight: "11em" }}
          />
          <motion.div
            transition={transitionSlowSpring}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <motion.h1
              transition={{ transitionSpring, delay: 0.5 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              style={{
                fontFamily: "Brevia",
                color: theme.colors.primary,
                fontSize: "4em",
                margin: 0,
              }}
            >
              Gefeliciteerd geldhelden!
            </motion.h1>
          </motion.div>
        </Grid>
      </Grid>
      <TextLink
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        whileHover={{
          scale: 1.1,
          cursor: "pointer",
        }}
        transition={{
          scale: { delay: 0 },
          default: 0.5,
        }}
        onClick={() => setPhase("end")}
        navigationPrev
        bottom={"50px"}
        left={"50px"}
        style={{
          position: "absolute",
        }}
      >
        terug
      </TextLink>
      <Background initialBig background={"cloudBackgroundBig"} />
    </>
  );
};

export default FinalPage;
