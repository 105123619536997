import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import Confetti from "react-dom-confetti";
import { toast } from "react-toastify";

import CashQuizLogo from "../../atoms/CashQuizLogo";
import BackgroundMobile from "../../atoms/BackgroundMobile";
import { usePlayerStore } from "../../../../../store/playerStore";
import AnswerBox from "../../molecules/AnswerBox";
import { Button } from "../../atoms/Buttons";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";
import { checkAnswerSocket } from "../../../../../hooks/helperfunctions";
import AnswerEstimation from "../../molecules/answerssocket/AnswerEstimation";
import AnswerMultiple from "../../molecules/answerssocket/AnswerMultiple";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import bulbyellow from "../../../../../static/media/bulbyellow.svg";
import { useGAEventsTracker } from "../../../../../hooks/useGAEventsTracker";
import AnswerOpen from "../../../../2023/components/molecules/answerssocket/AnswerOpen";

const Game = ({ socket }) => {
  const getFeedbackColorTrueOrFalse = (confetti, chosenAnswer, value) => {
    if (confetti && chosenAnswer === value) return "#43ba69";
    else if (confetti === false && chosenAnswer === value) return "#ba4343";
    else if (chosenAnswer !== value) return "#e9f0f5";
    else return "#4A6DFE";
  };

  const {
    question,
    round,
    user,
    roomPin,
    startChecking,
    setStartChecking,
    playersAnswered,
    stopAnswering,
    theme,
    lastSeconds,
    setLastSeconds,
  } = usePlayerStore();
  const [disable, setDisable] = useState(false);
  let [chosenAnswer, setChosenAnswer] = useState(
    question.type === "meerkeuze" ? [] : ""
  );
  const [answered, setAnswered] = useState(false);
  const [confetti, setConfetti] = useState(undefined);
  const { height, small } = useWindowDimensions();
  const config = {
    angle: 90,
    spread: "21",
    startVelocity: "23",
    elementCount: "37",
    dragFriction: "0.07",
    duration: "1830",
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: [theme.colors.purple, theme.colors.yellow],
  };
  const GAEventsTracker = useGAEventsTracker("cashquiz-plus");

  useEffect(() => {
    if (question.type === "meerkeuze") {
      setChosenAnswer([]);
    } else {
      setChosenAnswer();
    }
    setAnswered(false);
    setConfetti(undefined);
  }, [question]);

  useEffect(() => {
    fetch(process.env.REACT_APP_SERVER + "score/get-player-answer", {
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        group: user,
        gameid: localStorage.getItem("gameid"),
        question: question.ordering,
      }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.length > 0) {
          if (question.type === "schatting" || question.type === "eenkeuze") {
            setChosenAnswer(parseInt(result[0].answer));
            setAnswered(true);
          } else {
            let answerarr = result[0].answer.split(",");
            let newarray = answerarr.map((answer) => {
              return parseInt(answer);
            });
            setChosenAnswer(newarray);
            setAnswered(true);
          }
        } else {
          setChosenAnswer(undefined);
          setAnswered(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [question]);

  const renderAnswerInput = () => {
    switch (question.type) {
      case "eenkeuze":
        return JSON.parse(question.answers).map((answerOption, index) => (
          <Grid key={answerOption} item>
            <AnswerBox
              disable={disable}
              setAnswered={setAnswered}
              config={config}
              confetti={confetti}
              answered={answered}
              chosenAnswer={chosenAnswer}
              setChosenAnswer={setChosenAnswer}
              index={index}
              answerOption={answerOption}
              question={question}
            />
          </Grid>
        ));
      case "meerkeuze":
        return (
          <AnswerMultiple
            disable={disable}
            setAnswered={setAnswered}
            question={question}
            config={config}
            confetti={confetti}
            answered={answered}
            chosenAnswer={chosenAnswer}
            setChosenAnswer={setChosenAnswer}
          />
        );
      case "schatting":
        return (
          <Grid
            style={{ marginTop: "1em" }}
            container
            spacing={2}
            justify="center"
          >
            <Grid item xs={11} style={{ maxWidth: "380px" }}>
              <AnswerEstimation
                disable={answered}
                confetti={confetti}
                setChosenAnswer={setChosenAnswer}
                chosenAnswer={chosenAnswer}
                question={question}
                round={round}
                small={small}
              />
            </Grid>
          </Grid>
        );
      case "open":
        return (
          <AnswerOpen
            disable={disable}
            setAnswered={setAnswered}
            question={question}
            config={config}
            confetti={confetti}
            answered={answered}
            chosenAnswer={chosenAnswer}
            setChosenAnswer={setChosenAnswer}
          />
        );
      case "waarnietwaar":
        return (
          <Grid
            style={{ marginTop: "1em" }}
            container
            spacing={2}
            justify="center"
          >
            <Grid item xs={6}>
              <Confetti
                active={confetti && chosenAnswer === "Waar"}
                config={config}
              />
              <Button
                noHover
                fullWidth
                transition={{
                  ...transitionSpring,
                  delay: 0.6,
                  y: { delay: 0 },
                }}
                initial={{ opacity: 0 }}
                animate={{
                  transition: { delay: 0 },
                  opacity: 1,
                  backgroundColor: getFeedbackColorTrueOrFalse(
                    confetti,
                    chosenAnswer,
                    "Waar"
                  ),
                  boxShadow:
                    chosenAnswer !== "Waar"
                      ? `0px 5px 0px ${theme.colors.purple}`
                      : `0px 0px 0px ${theme.colors.purple}`,
                  color: chosenAnswer !== "Waar" ? theme.colors.purple : "#fff",
                  y: chosenAnswer !== "Waar" ? -5 : 0,
                }}
                exit={{ opacity: 0 }}
                style={{
                  background: "none",
                  pointerEvents: disable ? "none" : "auto",
                }}
                wrong={!confetti && chosenAnswer === "Waar"}
                right={confetti && chosenAnswer === "Waar"}
                onClick={() => (!answered ? setChosenAnswer("Waar") : null)}
              >
                Waar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Confetti
                active={confetti && chosenAnswer === "Niet waar"}
                config={config}
              />
              <Button
                noHover
                fullWidth
                transition={{ ...transitionSpring, delay: 0.7 }}
                initial={{ opacity: 0 }}
                animate={{
                  transition: { delay: 0 },
                  opacity: 1,
                  backgroundColor: getFeedbackColorTrueOrFalse(
                    confetti,
                    chosenAnswer,
                    "Niet waar"
                  ),
                  boxShadow:
                    chosenAnswer !== "Niet waar"
                      ? `0px 5px 0px ${theme.colors.purple}`
                      : `0px 0px 0px ${theme.colors.purple}`,
                  color:
                    chosenAnswer !== "Niet waar" ? theme.colors.purple : "#fff",
                  y: chosenAnswer !== "Niet waar" ? -5 : 0,
                }}
                exit={{ opacity: 0 }}
                style={{
                  background: "none",
                  pointerEvents: disable ? "none" : "auto",
                }}
                wrong={!confetti && chosenAnswer === "Niet waar"}
                right={confetti && chosenAnswer === "Niet waar"}
                onClick={() =>
                  !answered ? setChosenAnswer("Niet waar") : null
                }
                primary
              >
                Niet waar
              </Button>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (startChecking || stopAnswering) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [startChecking, stopAnswering, setDisable, chosenAnswer, disable]);

  useEffect(() => {
    if (startChecking) {
      if (!answered) {
        setConfetti(false);
        socket.emit("wrong answer", {
          roomPin,
          user,
          answer: "",
        });
      } else if (
        checkAnswerSocket(
          question.type,
          chosenAnswer,
          question,
          playersAnswered,
          user
        )
      ) {
        setConfetti(true);
        if (question.type === "schatting") {
          socket.emit("correct answer", {
            roomPin,
            user,
            answer: chosenAnswer,
          });
        }
      } else if (
        !checkAnswerSocket(
          question.type,
          chosenAnswer,
          question,
          playersAnswered,
          user
        )
      ) {
        setConfetti(false);
        if (question.type === "schatting") {
          socket.emit("wrong answer", {
            roomPin,
            user,
            answer: chosenAnswer,
          });
        }
      }
      setStartChecking(false);
    }
  }, [
    startChecking,
    playersAnswered,
    roomPin,
    setStartChecking,
    answered,
    chosenAnswer,
    question,
    socket,
    user,
  ]);

  return (
    <>
      <motion.div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          style={{
            backgroundColor: theme.colors.purpleShadow,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Grid container justify="center">
            <Grid item xs={11} md={6} lg={4}>
              <h3
                style={{
                  fontSize: small ? "1.2rem" : "1.6rem",
                  color: theme.colors.white,
                  textAlign: "center",
                  margin: small ? "0.4em" : "1em",
                }}
              >
                Team {user}
              </h3>
            </Grid>
          </Grid>
        </Grid>
      </motion.div>
      <motion.div exit={{ opacity: 0 }}>
        <Grid
          xs={12}
          style={{
            margin: "0 auto",
            height: height,
            textAlign: "start",
            backgroundColor:
              question.type === "schatting" ? theme.colors.purple : "#fff",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid container justify="center" item xs={11} md={5} lg={4}>
            <Grid>
              <p
                style={{
                  fontFamily: "Brevia",
                  margin: 0,
                  marginBottom: small ? 0 : 20,
                  fontSize: small ? "1.4rem" : "2rem",
                  fontWeight: 400,
                  lineHeight: 1.3,
                  color:
                    question.type !== "schatting"
                      ? theme.colors.purple
                      : "#fff",
                  textAlign: "center",
                }}
              >
                Antwoord op <b>vraag {round + 1}</b>
              </p>
              {question.possible_answers && (
                <motion.p
                  animate={{
                    fontSize: small ? "1rem" : "1.4rem",
                    lineHeight: "1em",
                    color:
                      question.type === "schatting"
                        ? theme.colors.white
                        : theme.colors.purple,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  transition={{ ...transitionSpring }}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      backgroundColor:
                        question.type === "schatting"
                          ? theme.colors.white
                          : theme.colors.purple,
                      width: 25,
                      height: 25,
                      marginRight: 7,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        color:
                          question.type === "schatting"
                            ? theme.colors.brown
                            : theme.colors.white,
                        fontSize: "0.8em",
                        fontWeight: 900,
                      }}
                    >
                      !
                    </span>
                  </div>{" "}
                  {parseInt(question.possible_answers) ===
                  JSON.parse(question.answers).length
                    ? "Meerdere"
                    : question.possible_answers}{" "}
                  antwoorden mogelijk
                </motion.p>
              )}
              {question.tip && (
                <motion.p
                  animate={{
                    fontSize: small ? "1rem" : "1.4rem",
                    lineHeight: "1.4em",
                    margin: small ? "0" : "inherit",
                    color:
                      question.type === "schatting"
                        ? theme.colors.white
                        : theme.colors.purple,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    textAlign: "center",
                  }}
                  transition={{ ...transitionSpring }}
                >
                  <img
                    width={40}
                    height={40}
                    style={{ marginRight: 10 }}
                    src={bulbyellow}
                    alt="tipsicon"
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.tip,
                    }}
                  />
                </motion.p>
              )}
              {disable && !answered && (
                <motion.p
                  animate={{
                    fontSize: "1.4rem",
                    lineHeight: "1.4em",
                    color:
                      question.type === "schatting"
                        ? theme.colors.white
                        : theme.colors.purple,
                    alignItems: "center",
                    display: "flex",
                  }}
                  transition={{ ...transitionSpring }}
                >
                  {stopAnswering
                    ? "Je hebt geen antwoord verstuurd binnen de tijd. Klopt dit niet, vernieuw dan dit scherm."
                    : chosenAnswer !== undefined
                    ? "Je hebt al een antwoord verstuurd."
                    : "Je hebt geen antwoord verstuurd, let hier op! Klopt dit niet, vernieuw dan dit scherm."}
                </motion.p>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                {renderAnswerInput()}
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: "1em" }} xs={12} md={"auto"}>
              {!disable && (
                <Button
                  animate={{
                    rotate:
                      chosenAnswer !== undefined && !answered
                        ? lastSeconds
                          ? [0, 20, -20, 0]
                          : [0, 5, -5, 0, 0, 0]
                        : 0,
                  }}
                  transition={{
                    duration: 0.2,
                    rotate: {
                      repeat:
                        chosenAnswer !== undefined && !answered ? Infinity : 0,
                      repeatType: "reverse",
                      duration: lastSeconds ? 0.3 : 0.6,
                    },
                  }}
                  questionType={question.type}
                  noHover
                  style={{
                    background: answered ? "#808080" : theme.colors.blue,
                    boxShadow: answered ? "0 5px 0 #474d6a" : "0 5px 0 #173fff",
                  }}
                  onClick={() => {
                    if (!answered) {
                      if (question.type === "open") {
                        if (chosenAnswer === "" || chosenAnswer === undefined) {
                          toast("Geen antwoord ingegeven");
                        } else {
                          socket.emit("send answer", {
                            user: parseInt(user),
                            roomPin,
                            chosenAnswer,
                          });
                          setAnswered(true);
                        }
                      } else {
                        if (chosenAnswer === "" || chosenAnswer === undefined) {
                          toast("Geen antwoord ingegeven");
                        } else {
                          GAEventsTracker("speler heeft antwoord verstuurd");
                          setAnswered(true);
                          if (
                            checkAnswerSocket(
                              question.type,
                              chosenAnswer,
                              question,
                              playersAnswered,
                              user
                            )
                          ) {
                            socket.emit("correct answer", {
                              roomPin,
                              user,
                              answer: chosenAnswer,
                              correctMultiple:
                                question.type === "meerkeuze" &&
                                checkAnswerSocket(
                                  question.type,
                                  chosenAnswer,
                                  question,
                                  user
                                ),
                            });
                          } else if (
                            !checkAnswerSocket(
                              question.type,
                              chosenAnswer,
                              question,
                              playersAnswered,
                              user
                            )
                          ) {
                            socket.emit("wrong answer", {
                              roomPin,
                              user,
                              answer: chosenAnswer,
                            });
                          }
                          setLastSeconds(false);
                          socket.emit("send answer", {
                            user: parseInt(user),
                            roomPin,
                            chosenAnswer,
                          });
                        }
                      }
                    }
                  }}
                  primary
                  fullWidth
                >
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 900,
                      color:
                        answered || chosenAnswer === undefined
                          ? theme.colors.white
                          : theme.colors.white,
                    }}
                  >
                    {answered ? "Antwoord verstuurd" : "Bevestig antwoord"}
                  </p>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </motion.div>
      <CashQuizLogo />
      <BackgroundMobile white />
    </>
  );
};

export default Game;
