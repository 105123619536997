import styled from "styled-components";
import { motion } from "framer-motion";

export const SquareTileStyled = styled(motion.div)`
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-radius: 5px;
  font-weight: 600;
  font-family: "Brevia", sans-serif;
  font-size: 3em;
  position: relative;
  width: 100%;
  cursor: pointer;
  border: 6px solid transparent;
  transition: all 0.2s ease-out;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  &:hover {
    border: 6px solid ${(props) => props.theme.colors.white};
  }
`;

export const SquareTile = (props) => {
  return (
    <SquareTileStyled
      whileHover={{ scale: 1.05 }}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: props.index * 0.1, scale: { delay: 0 } }}
      {...props}
    >
      {props.children}
    </SquareTileStyled>
  );
};
