import styled from "styled-components";

const TestBanner = () => {
  return <Wrapper>Oefensessie</Wrapper>;
};

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 300px;
  height: 300px;
  transform: rotateZ(45deg) translate(0px, -250px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 16px;
  font-weight: 700;
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.white};
  z-index: 20;
  background: ${(props) => props.theme.colors.goldGradient};
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
`;

export default TestBanner;
