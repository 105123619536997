import { motion } from "framer-motion";
import React from "react";

const IconClose = ({ color, height, width }) => {
  return (
    <motion.svg
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      id="bold"
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 515.556 515.556"
      height={height}
      viewBox="0 0 9.6 9.6"
      width={width}
      fill={color}
    >
      <path d="M9.6,1.1L8.5,0L4.8,3.7L1.1,0L0,1.1l3.7,3.7L0,8.5l1.1,1.1l3.7-3.7l3.7,3.7l1.1-1.1L5.8,4.8L9.6,1.1z" />
    </motion.svg>
  );
};

export default IconClose;
