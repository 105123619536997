import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import arrowprev from "../../../../static/media/arrowprev.svg";
import arrowprevpurple from "../../../../static/media/arrowprevpurple.svg";

export const TextLink = styled(motion.a)`
  ${(props) =>
    props.navigationPrev &&
    css`
      opacity: 1;
      position: relative;
      /* padding-left: 45px; */
      border-bottom: 0 none !important;
      font-family: "proxima-nova";
      /* &:before {
      content: "";
      background-image: url(${arrowprev});
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
    } */
    `}

  color: #fff;
  font-size: 1.5em;
  @media only screen and (max-width: 600px) {
    font-size: 1.4em;
  }
  opacity: 0.5;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};

  ${(props) =>
    props.navigationPrevPurple &&
    css`
      color: ${(props) => props.theme.colors.purple};
      opacity: 1;
      position: relative;
      /* padding-left: 45px; */
      border-bottom: 0 none !important;
      font-family: "proxima-nova";
      /* &:before {
        content: "";
        background-image: url(${arrowprevpurple});
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
      } */
    `}
`;
