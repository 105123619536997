import styled, { css } from "styled-components";
import { animated } from "react-spring";
import { motion } from "framer-motion";
import { useMainStore } from "../../../../store/index.js";

import bulb from "../../../../static/emojis/bulb.png";
import dashing from "../../../../static/emojis/dashing.png";
import trophy from "../../../../static/emojis/trophy.png";
import target from "../../../../static/emojis/target.png";
import calculate from "../../../../static/emojis/calculate.png";
import open from "../../../../static/emojis/open.png";

export const QuestionIconStyled = styled(motion.div)`
  width: 65px;
  height: 65px;
  z-index: 5;
  position: relative;
`;

export const Icon = styled(animated.div)`
  height: 100%;
  width: 100%;
  position: relative;

  img {
    filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.6));
    height: 100%;
    width: 100%;
    &.target {
      margin-top: -10px;
    }
  }

  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export const QuestionIcon = ({ questionSoort }) => {
  const { theme, decision } = useMainStore();

  const renderIcon = () => {
    if (decision) {
      return trophy;
    }
    switch (questionSoort) {
      case "Verdiepingsvraag":
        return <motion.img src={bulb} />;
      case "Schattingsvraag":
        return <motion.img className={"target"} src={target} />;
      case "Rekenvraag":
        return <motion.img src={calculate} />;
      case "Snelle vraag":
        return <motion.img src={dashing} />;
      case "Beslissingsvraag":
        return <motion.img src={trophy} />;
      case "Score":
        return <motion.img src={trophy} />;
      case "Open vraag":
        return <motion.img src={open} />;
      default:
        break;
    }
  };

  return (
    <QuestionIconStyled>
      <motion.div
        style={{
          position: "relative",
          padding: "20px 10px 10px",
          paddingTop: "20px",
          height: "45px",
          width: "45px",
          borderTop: 0,
          background: theme.colors.goldGradient,
          borderRadius: "0rem 0rem 0.4rem 0.4rem",
        }}
      >
        <Icon width="100%">{renderIcon()}</Icon>
      </motion.div>
    </QuestionIconStyled>
  );
};
