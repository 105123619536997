//HOSTPAGE
import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { useForm } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Background } from "../../../themes/2023/components/atoms/Backgrounds";
import { Input } from "../../../themes/2023/components/atoms/Input";
import { HeaderTwo } from "../../../themes/2023/components/atoms/Typography";
import { transitionSpring } from "../../../themes/2023/components/pages/hostpages/SelectLesson";
import { Button } from "../../../themes/2023/components/atoms/Buttons";
import Dropdown from "../../../themes/2023/components/atoms/Dropdown";
import { TextLink } from "../../../themes/2023/components/atoms/TextLink";
import { useSocketStore } from "../../../store/socketStore";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useGAEventsTracker } from "../../../hooks/useGAEventsTracker";
import { useMainStore } from "../../../store";

const schema = yup.object().shape({
  groepnumber: yup.string().required("Dit veld is verplicht"),
  school_name: yup.string().required("Dit veld is verplicht"),
  postcode: yup
    .string()
    .required("Dit veld is verplicht")
    .matches(
      /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i,
      "Dit is geen geldige postcode"
    ),
  teacher: yup.string().required("Dit veld is verplicht"),
  email_teacher: yup
    .string()
    .email("Dit is geen geldig email adres")
    .required("Dit veld is verplicht"),
  //bank: yup.string().required("Dit veld is verplicht"),
  gastdocent: yup.string().required("Dit veld is verplicht"),
  email: yup
    .string()
    .email("Dit is geen geldig email adres")
    .required("Dit veld is verplicht"),
});

const NoCodeForm = ({ socket }) => {
  const GAEventsTracker = useGAEventsTracker("cashquiz-plus");
  const { height } = useWindowDimensions();
  const [title, setTitle] = useState("");
  const { setPhase, setCode, theme } = useSocketStore();
  const { typeHost, typeQuiz } = useMainStore();
  const [guestTeacher, setGuestTeacher] = useState(true);
  const [banks, setBanks] = useState([]);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    setCode(undefined);

    fetch(`${process.env.REACT_APP_SERVER}banks`)
      .then((res) => res.json())
      .then((result) => {
        setBanks(result);
      });
  }, [setCode, setBanks]);

  const onSubmit = (data) => {
    Object.keys(data).map(
      (k) => (data[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    data.bank = title;
    data.host = typeHost;
    data.quiztype = typeQuiz;
    GAEventsTracker("game gestart zonder code");
    socket.emit("create room no code", data);
  };

  return (
    <>
      <motion.div exit={{ opacity: 0 }}>
        <Grid
          style={{
            height: "100vh",
            textAlign: "center",
            overflow: "auto",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xl={10} lg={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-around"
                spacing={2}
              >
                <Grid item xs={12}>
                  <motion.h1
                    layout
                    transition={{ ...transitionSpring }}
                    initial={{ opacity: 0, transition: { delay: 0.2 } }}
                    animate={{ opacity: 1 }}
                    style={{
                      margin: 0,
                      marginBottom: 24,
                      fontSize: height < 700 ? "2em" : "3em",
                    }}
                  >
                    Vul gegevens van school in
                  </motion.h1>
                </Grid>
                <Grid style={{ textAlign: "left" }} item xs={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <motion.div
                        transition={{ ...transitionSpring }}
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -20, opacity: 0 }}
                      >
                        <HeaderTwo
                          style={{
                            fontSize: height < 700 ? "1em" : "1.5em",
                            fontWeight: 600,
                          }}
                        >
                          Bank
                        </HeaderTwo>
                        <Dropdown
                          small={height < 700}
                          title={title}
                          setTitle={setTitle}
                          name="bank"
                          ref={register({ required: true })}
                          items={banks}
                        />
                        {errors.bank && (
                          <span
                            style={{
                              color: theme.colors.red,
                              marginTop: 10,
                              display: "inline-block",
                            }}
                          >
                            {errors.bank?.message}
                          </span>
                        )}
                      </motion.div>
                    </Grid>
                    <Grid item xs={4}>
                      <motion.div
                        transition={{ ...transitionSpring }}
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -20, opacity: 0 }}
                      >
                        <HeaderTwo
                          style={{
                            fontSize: height < 700 ? "1em" : "1.5em",
                            fontWeight: 600,
                          }}
                        >
                          Naam gastdocent
                        </HeaderTwo>
                        <Input
                          border
                          small={height < 700}
                          name="gastdocent"
                          ref={register({ required: true })}
                        />
                        {errors.gastdocent && (
                          <span
                            style={{
                              color: theme.colors.red,
                              marginTop: 10,
                              display: "inline-block",
                            }}
                          >
                            {errors.gastdocent?.message}
                          </span>
                        )}
                      </motion.div>
                    </Grid>
                    <Grid item xs={4}>
                      <motion.div
                        transition={{ ...transitionSpring }}
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -20, opacity: 0 }}
                      >
                        <HeaderTwo
                          style={{
                            fontSize: height < 700 ? "1em" : "1.5em",
                            fontWeight: 600,
                          }}
                        >
                          E-mail gastdocent
                        </HeaderTwo>
                        <Input
                          border
                          small={height < 700}
                          name="email"
                          ref={register({ required: true })}
                        />
                        {errors.email && (
                          <span
                            style={{
                              color: theme.colors.red,
                              marginTop: 10,
                              display: "inline-block",
                            }}
                          >
                            {errors.email?.message}
                          </span>
                        )}
                      </motion.div>
                    </Grid>
                    <Grid item xs={4}>
                      <HeaderTwo
                        layout
                        transition={{ ...transitionSpring }}
                        style={{
                          fontSize: height < 700 ? "1em" : "1.5em",
                          fontWeight: 600,
                        }}
                      >
                        Schoolnaam
                      </HeaderTwo>
                      <Input
                        small={height < 700}
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                        name="school_name"
                        ref={register({ required: true })}
                      />
                      {errors.school_name && (
                        <span
                          style={{
                            color: theme.colors.red,
                            marginTop: 10,
                            display: "inline-block",
                          }}
                        >
                          {errors.school_name?.message}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <HeaderTwo
                        layout
                        transition={{ ...transitionSpring }}
                        style={{
                          fontSize: height < 700 ? "1em" : "1.5em",
                          fontWeight: 600,
                        }}
                      >
                        Groepsnaam
                      </HeaderTwo>
                      <Input
                        small={height < 700}
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                        placeholder="Bijv. Groep 3"
                        name="groepnumber"
                        ref={register({ required: true })}
                      />
                      {errors.groepnumber && (
                        <span
                          style={{
                            color: theme.colors.red,
                            marginTop: 10,
                            display: "inline-block",
                          }}
                        >
                          {errors.groepnumber?.message}
                        </span>
                      )}
                    </Grid>

                    <Grid item xs={4}>
                      <HeaderTwo
                        layout
                        transition={{ ...transitionSpring }}
                        style={{
                          fontSize: height < 700 ? "1em" : "1.5em",
                          fontWeight: 600,
                        }}
                      >
                        Postcode school
                      </HeaderTwo>
                      <Input
                        small={height < 700}
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                        name="postcode"
                        ref={register({ required: true })}
                      />
                      {errors.postcode && (
                        <span
                          style={{
                            color: theme.colors.red,
                            marginTop: 10,
                            display: "inline-block",
                          }}
                        >
                          {errors.postcode?.message}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <HeaderTwo
                        layout
                        transition={{ ...transitionSpring }}
                        style={{
                          fontSize: height < 700 ? "1em" : "1.5em",
                          fontWeight: 600,
                        }}
                      >
                        Naam leerkracht
                      </HeaderTwo>
                      <Input
                        small={height < 700}
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                        name="teacher"
                        ref={register({ required: true })}
                      />
                      {errors.teacher && (
                        <span
                          style={{
                            color: theme.colors.red,
                            marginTop: 10,
                            display: "inline-block",
                          }}
                        >
                          {errors.teacher?.message}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <HeaderTwo
                        small={height < 700}
                        layout
                        transition={{ ...transitionSpring }}
                        style={{
                          fontSize: height < 700 ? "1em" : "1.5em",
                          fontWeight: 600,
                        }}
                      >
                        E-mail leerkracht
                      </HeaderTwo>
                      <Input
                        small={height < 700}
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                        name="email_teacher"
                        ref={register({ required: true })}
                      />
                      {errors.email_teacher && (
                        <span
                          style={{
                            color: theme.colors.red,
                            marginTop: 10,
                            display: "inline-block",
                          }}
                        >
                          {errors.email_teacher?.message}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <motion.div
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                        style={{ height: "100%", marginTop: 20 }}
                      ></motion.div>
                    </Grid>

                    <Grid item xs={12}>
                      <motion.div
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                      >
                        <Grid container justify="center">
                          <Grid item>
                            <Button
                              type="submit"
                              transition={{ ...transitionSpring }}
                              primary
                            >
                              Start de Cash Quiz
                            </Button>
                          </Grid>
                        </Grid>
                      </motion.div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </motion.div>
      <TextLink
        whileHover={{
          borderBottom: "1px solid #fff",
          cursor: "pointer",
          opacity: 1,
        }}
        onClick={() => setPhase("personalCode")}
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.5 }}
        bottom={"50px"}
        left={"50px"}
        style={{
          position: "absolute",
        }}
      >
        terug
      </TextLink>
      <Background big background={"cloudBackground"} />
    </>
  );
};

export default NoCodeForm;
