import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence, motion } from "framer-motion";

import { numberToLetter } from "../atoms/LetterBox";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import { AnswerBox } from "./AnswerModal";
import { Button } from "../atoms/Buttons";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useMainStore } from "../../../../store";

const AnswerMultiple = ({
  selectedPlayer,
  players,
  questions,
  round,
  setPlayerAnswer,
  setConfirm,
  setSelectedPlayer,
}) => {
  const { theme } = useMainStore();

  const setAnswer = (answer) => {
    setPlayerAnswer(answer, false);
  };

  const { small } = useWindowDimensions();

  return (
    <>
      <motion.h1
        style={{ color: theme.colors.purple }}
        transition={{ ...transitionSpring, delay: 0.5 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <span style={{ fontFamily: "proxima-nova", fontWeight: 400 }}>
          Antwoorden
        </span>{" "}
        <AnimatePresence exitBeforeEnter>
          <motion.span
            transition={{ ...transitionSpring }}
            style={{ display: "inline-block" }}
            key={selectedPlayer}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
          >
            team {selectedPlayer !== undefined ? selectedPlayer + 1 : 4}:
          </motion.span>
        </AnimatePresence>
      </motion.h1>
      <motion.h3
        transition={{ ...transitionSpring, delay: 0.7 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        style={{
          fontWeight: 400,
          color: theme.colors.purple,
          fontSize: "1.5em",
        }}
      >
        Vul maximaal{" "}
        <strong>{questions[round].possible_answers} antwoorden</strong> in.
      </motion.h3>
      <Grid
        style={{ marginTop: "1em" }}
        container
        spacing={small ? 1 : 3}
        justify="center"
      >
        {JSON.parse(questions[round].answers).map(
          (answer, index) =>
            answer && (
              <Grid key={index} item>
                <AnswerBox
                  disable={
                    Array.isArray(players[selectedPlayer].answer) &&
                    players[selectedPlayer].answer.includes(index)
                  }
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: { delay: index * 0.1 + 1 },
                  }}
                  transition={{ ...transitionSpring }}
                  selected={players[selectedPlayer].answer === index}
                  onClick={() => {
                    if (
                      Array.isArray(players[selectedPlayer].answer) &&
                      players[selectedPlayer].answer.includes(index)
                    ) {
                      const indexSplice =
                        players[selectedPlayer].answer.indexOf(index);
                      players[selectedPlayer].answer.splice(indexSplice, 1);
                      setPlayerAnswer(players[selectedPlayer].answer, "hard");
                    } else {
                      if (!players[selectedPlayer].answer) setAnswer(index);
                      else if (
                        players[selectedPlayer].answer.length !==
                        parseInt(questions[round].possible_answers)
                      ) {
                        setAnswer(index);
                      } else {
                        setPlayerAnswer(index, true);
                      }
                    }
                  }}
                >
                  <motion.span>{numberToLetter(index)}</motion.span>
                </AnswerBox>
              </Grid>
            )
        )}
      </Grid>
      <Button
        noHover
        primary
        disable={
          (players[selectedPlayer].answer &&
            !players[selectedPlayer].answer.length) ||
          !players[selectedPlayer].answer
        }
        onClick={() => {
          if (players.every((player) => player.answer !== undefined)) {
            setConfirm(true);
          } else {
            players.length !== selectedPlayer + 1
              ? setSelectedPlayer((selectedPlayer += 1))
              : setSelectedPlayer(
                  players.findIndex((player) => player.answer === undefined)
                );
          }
        }}
      >
        Volgende team
      </Button>
    </>
  );
};

export default AnswerMultiple;
