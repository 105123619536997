import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { numberToLetter } from "../atoms/LetterBox";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import { useMainStore } from "../../../../store";

const Tabs = styled(motion.div)`
  text-align: center;
`;

const Underline = styled(motion.div)`
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.purple};
`;
const TextInner = styled(motion.div)`
  padding: 0 2px;
  ${(props) =>
    props.smaller &&
    css`
      font-size: 0.8em;
    `}
`;

const TabBox = styled(motion.button)`
  width: 75px;
  height: 75px;
  border: none;
  border-radius: 0.4rem;
  font-size: 2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 10px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.grey};
  box-shadow: 0 5px 0 ${(props) => props.theme.colors.greyDarker};
  color: ${(props) => props.theme.colors.purple};
  transition: all 0.5s;
  font-family: proxima-nova,

  &:active {
    box-shadow: none;
    transform: translateY(5px);
  }

  &:focus {
    outline: 0;
  }


  ${(props) =>
    props.selected &&
    css`
      color: #fff;
      font-size: ${props.fontSize};
      background-color: ${props.theme.colors.purple};
      box-shadow: 0 5px 0 ${(props) => props.theme.colors.purpleShadow};
    `}
`;

const getText = (player, selectedPlayer, index, type) => {
  if (Array.isArray(player?.answer) && player.answer.length === 1)
    return <TextInner>{numberToLetter(player.answer[0])}</TextInner>;
  else if (Array.isArray(player?.answer) && player.answer.length === 2)
    return (
      <>
        <TextInner>{numberToLetter(player.answer[0])}</TextInner>
        <TextInner>{numberToLetter(player.answer[1])}</TextInner>
      </>
    );
  else if (Array.isArray(player?.answer) && player.answer.length === 3)
    return (
      <>
        <TextInner smaller>{numberToLetter(player.answer[0])}</TextInner>
        <TextInner smaller>{numberToLetter(player.answer[1])}</TextInner>
        <TextInner smaller>{numberToLetter(player.answer[2])}</TextInner>
      </>
    );
  else if (type === "waarnietwaar" || type === "schatting" || type === "open")
    return player.answer;
  else if (type === "openvraag") {
    return player?.correct === true
      ? "Goed"
      : player?.correct === false
      ? "Fout"
      : "";
  } else if (player?.answer !== undefined)
    return numberToLetter(player?.answer);
  else if (selectedPlayer === index && !player.answer) return "..";
};

const AnswerTabs = ({
  selectedPlayer,
  setSelectedPlayer,
  players,
  setConfirm,
  type,
}) => {
  const { theme } = useMainStore();
  return (
    <Grid container alignItems="center" justify="center">
      <Tabs>
        <Grid
          component={motion.div}
          layout
          style={{ marginTop: 20 }}
          container
          spacing={2}
          justify="center"
        >
          {players.map((player, index) => (
            <Grid key={index} item>
              <motion.div
                initial={{ y: 300, opacity: 0 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: { delay: index * 0.1 + 1.5 },
                }}
                exit={{ y: 300, opacity: 0 }}
                transition={{ ...transitionSpring }}
                key={index}
              >
                <span
                  style={{
                    color: theme.colors.purple,
                    fontWeight: 900,
                    fontSize: "1em",
                    fontFamily: "proxima-nova",
                  }}
                >
                  {" "}
                  Team {player.group ? player.group : index + 1}
                </span>
                <TabBox
                  onClick={() => {
                    setSelectedPlayer(index);
                    setConfirm(false);
                  }}
                  selected={selectedPlayer === index}
                >
                  <motion.span
                    style={{
                      display: "flex",
                      fontSize:
                        type === "waarnietwaar" ||
                        type === "openvraag" ||
                        type === "open"
                          ? "0.5em"
                          : "1em",
                      color:
                        selectedPlayer === index ? "#fff" : theme.colors.purple,
                    }}
                  >
                    {getText(player, selectedPlayer, index, type)}
                  </motion.span>
                </TabBox>
                {selectedPlayer === index && (
                  <Underline
                    transition={{ ...transitionSpring }}
                    style={{ y: -10 }}
                    layoutId="selected"
                  />
                )}
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Tabs>
    </Grid>
  );
};

export default AnswerTabs;
