import React from "react";
import { animated } from "react-spring";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import coin1 from "../../../../static/media/coin1.svg";
import coin2 from "../../../../static/media/coin2.svg";
import coin3 from "../../../../static/media/coin3.svg";
import coin5 from "../../../../static/media/coin5.svg";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

export const transition = {
  type: "spring",
  stiffness: 100,
};

export const Coins = styled(animated.div)`
  position: absolute;
  background-repeat: no-repeat;
  will-change: transform;
  width: 300px;
  height: 300px;
  z-index: -1;
  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

function CoinsParallax({ mouse, initial }) {
  const trans1 = (x, y) =>
    `translate3d(${x / 50}px,${y / 50 + 0}px,0) scale(${small ? 0.4 : 0.6})`;

  const trans2 = (x, y) =>
    `translate3d(${x / 40 + 150}px,${y / 40 + 50}px,0) scale(${
      small ? 0.7 : 1
    })`;

  const trans3 = (x, y) =>
    `translate3d(${-x / 45 + 100}px,${-y / 45 + 600}px,0) scale(${
      small ? 0.6 : 0.8
    }) `;

  const trans4 = (x, y) =>
    `translate3d(${x / 30 + 50}px,${y / 30 + 750}px,0) scale(${
      small ? 0.9 : 1.2
    })`;

  const trans5 = (x, y) =>
    `translate3d(${x / 50}px,${y / 50 + 50}px,0) scale(1.3) rotate(50deg)`;

  const trans6 = (x, y) =>
    `translate3d(${-x / 40}px,${-y / 40}px,0) scale(0.8) scale(1.2)`;

  const { small } = useWindowDimensions();
  return (
    <div
      style={{
        position: "absolute",
        width: "100vw",
        height: "100vh",
        zIndex: -1,
        overflow: "hidden",
      }}
    >
      <motion.div
        style={{ y: -30, x: -80 }}
        transition={{ ...transition, delay: 1.8 }}
        animate={{ opacity: 1, y: -30, x: -80 }}
        initial={initial && { opacity: 0, y: -30, x: -80 }}
        exit={{ opacity: 0, y: -30 }}
      >
        <Coins
          image={coin1}
          style={{
            transform: mouse.xy && mouse.xy.interpolate(trans1),
            filter: "blur(2px)",
          }}
        />
        <Coins
          image={coin2}
          style={{ transform: mouse.xy && mouse.xy.interpolate(trans2) }}
        />
      </motion.div>
      <motion.div
        style={{ y: -300, x: -100 }}
        transition={initial && { ...transition, delay: 1.6 }}
        initial={initial && { opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: -300, x: -100 }}
        exit={{ opacity: 0, y: -50 }}
      >
        <Coins
          image={coin3}
          style={{
            transform: mouse.xy && mouse.xy.interpolate(trans3),
            filter: "blur(1px)",
          }}
        />
        <Coins
          image={coin5}
          style={{
            transform: mouse.xy && mouse.xy.interpolate(trans4),
          }}
        />
      </motion.div>
      {!small && (
        <>
          <motion.div
            transition={{ ...transition, delay: 1.2 }}
            animate={{ opacity: 1, y: 0 }}
            initial={initial && { opacity: 0, y: 50 }}
            exit={{ opacity: 0, y: 50 }}
          ></motion.div>
          <motion.div
            transition={{ ...transition, delay: 1.4 }}
            animate={{ opacity: 1, y: 0 }}
            initial={initial && { opacity: 0, y: 50 }}
            exit={{ opacity: 0, y: 50 }}
          >
            <Coins
              image={coin1}
              style={{
                transform: mouse.xy && mouse.xy.interpolate(trans5),
                top: 20,
                right: 0,
              }}
            />
            <Coins
              image={coin1}
              style={{
                transform: mouse.xy && mouse.xy.interpolate(trans6),
                top: 300,
                right: -100,
              }}
            />
          </motion.div>
        </>
      )}
    </div>
  );
}

export default CoinsParallax;
