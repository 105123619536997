import { animate, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";

export default function Counter({ from, to, unit, unitPrepend }) {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 2,
      ease: [0.41, 0.14, 0.32, 0.93],
      delay: 2,
      onUpdate(value) {
        node.textContent = value.toFixed(0);
      },
    });

    return () => controls.stop();
  }, [from, to]);

  return (
    <motion.h1
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { delay: 0 } }}
      transition={{ delay: 2 }}
      style={{ color: "#fff", fontSize: "4rem", fontWeight: 900 }}
    >
      {!!unitPrepend && unit}
      <span ref={nodeRef} />
      {!!!unitPrepend && unit && unit}
    </motion.h1>
  );
}
