import Question from "./Question";
import QuestionDecision from "./QuestionDecision";
import QuestionIntro from "./QuestionIntro";
import SubmitAnswers from "./SubmitAnswers";
import RevealAnswer from "./RevealAnswer";
import SelectLevel from "./SelectLevel";
import SelectTeams from "./SelectTeams";
import QuestionDecisionExplanation from "./QuestionDecisionExplanation";
import Scores from "./Scores";
import Explanation from "./Explanation";
import QuestionVideo from "./QuestionVideo";
import EndPage from "./EndPage";
import Outro from "./Outro";
import QuestionDecisionReveal from "./QuestionDecisionReveal";
import Tips from "./Tips";
import FinalPage from "./FinalPage";

export const renderScreen2023 = (phase) => {
  switch (phase) {
    case "selectLevel":
      return <SelectLevel key="selectLevel" />;
    case "selectTeams":
      return <SelectTeams key="selectTeam" />;
    case "scoresBegin":
      return <Scores begin key="scores" />;
    case "quizStart":
      return <QuestionIntro key="intro" />;
    case "questionVideo":
      return <QuestionVideo key="video" />;
    case "question":
      return <Question key="question" />;
    case "submitAnswers":
      return <SubmitAnswers key="submitAnswers" />;
    case "revealAnswer":
      return <RevealAnswer key="revealAnswer" />;
    case "questionDecision":
      return <QuestionDecision key="questionDecision" />;
    case "questionDecisionReveal":
      return <QuestionDecisionReveal key="revealAnswerDecision" />;
    case "QuestionDecisionExplanation":
      return <QuestionDecisionExplanation key="QuestionDecisionExplanation" />;
    case "showScores":
      return <Scores key="showScores" />;
    case "explanation":
      return <Explanation key="explanation" />;
    case "tips":
      return <Tips key="tips" />;
    case "end":
      return <EndPage key="end" />;
    case "outro":
      return <Outro key="outro" />;
    case "final":
      return <FinalPage key="final" />;
    default:
      return null;
  }
};
